import React from 'react'

const BattleDiagram = () => (
  <svg viewBox="0 0 1805 600" class="o-battle-diagram">
    <rect class="b" x="582" y="212" width="174" height="80" />
    <g class="a">
      <path
        class="c"
        d="M636.4,258.05v3.64H621.22v-19.6H636v3.64h-10.3V250h9.1v3.53h-9.1v4.54Z"
      />
      <path
        class="c"
        d="m654.51 246.62-6.36 15.07h-4.5l-6.33-15.07h4.51l4.17 10.25 4.31-10.25z"
      />
      <path
        class="c"
        d="m670.55 255.39h-11.39a3.54 3.54 0 0 0 1.45 2.21 4.85 4.85 0 0 0 2.86 0.81 5.69 5.69 0 0 0 2.09-0.35 5.07 5.07 0 0 0 1.69-1.1l2.32 2.52a7.8 7.8 0 0 1-6.21 2.43 9.88 9.88 0 0 1-4.51-1 7.22 7.22 0 0 1-3-2.76 8.06 8.06 0 0 1 0-8 7.36 7.36 0 0 1 2.89-2.78 8.94 8.94 0 0 1 8.1 0 7 7 0 0 1 2.82 2.73 8.16 8.16 0 0 1 1 4.13c-0.02 0.04-0.05 0.43-0.11 1.16zm-10.22-4.85a3.6 3.6 0 0 0-1.23 2.3h7.42a3.62 3.62 0 0 0-1.23-2.28 3.67 3.67 0 0 0-2.46-0.86 3.8 3.8 0 0 0-2.5 0.84z"
      />
      <path
        class="c"
        d="m687.23 248.08a6.65 6.65 0 0 1 1.72 5v8.63h-4.37v-7.95a4 4 0 0 0-0.78-2.68 2.87 2.87 0 0 0-2.27-0.88 3.47 3.47 0 0 0-2.63 1 4.26 4.26 0 0 0-1 3v7.45h-4.37v-15.03h4.17v1.77a5.69 5.69 0 0 1 2.16-1.47 7.43 7.43 0 0 1 2.83-0.52 6.21 6.21 0 0 1 4.54 1.68z"
      />
      <path
        class="c"
        d="M702.61,261a4.25,4.25,0,0,1-1.58.71,7.88,7.88,0,0,1-2,.24,5.89,5.89,0,0,1-4.16-1.37,5.24,5.24,0,0,1-1.47-4v-6.19h-2.32V247h2.32v-3.67h4.37V247h3.75v3.36H697.8v6.13a2,2,0,0,0,.49,1.47,1.79,1.79,0,0,0,1.38.52,2.86,2.86,0,0,0,1.77-.56Z"
      />
      <path
        class="c"
        d="M706.48,261.45a9.79,9.79,0,0,1-2.86-1.16l1.46-3.14a9.08,9.08,0,0,0,2.43,1.05,10.31,10.31,0,0,0,2.8.41c1.85,0,2.78-.46,2.78-1.37a1,1,0,0,0-.76-.93,11.66,11.66,0,0,0-2.32-.47,21.85,21.85,0,0,1-3.06-.65,4.69,4.69,0,0,1-2.08-1.29,3.64,3.64,0,0,1-.88-2.63,4.12,4.12,0,0,1,.82-2.53,5.32,5.32,0,0,1,2.41-1.72,10.28,10.28,0,0,1,3.74-.62,15,15,0,0,1,3.18.35,8.79,8.79,0,0,1,2.61,1l-1.45,3.1A8.77,8.77,0,0,0,711,249.7a4.27,4.27,0,0,0-2.1.4,1.14,1.14,0,0,0-.7,1,1,1,0,0,0,.75,1,13.31,13.31,0,0,0,2.41.54,26.07,26.07,0,0,1,3,.65,4.6,4.6,0,0,1,2,1.28,3.59,3.59,0,0,1,.87,2.57,4,4,0,0,1-.84,2.5,5.42,5.42,0,0,1-2.45,1.69,10.94,10.94,0,0,1-3.82.6A14.53,14.53,0,0,1,706.48,261.45Z"
      />
    </g>
    <rect class="b" x="771" y="212" width="307" height="80" />
    <g class="a">
      <path class="c" d="M818.22,242.09h4.54V258h9.82v3.7H818.22Z" />
      <path
        class="c"
        d="M846.44,248.07a6.36,6.36,0,0,1,1.88,5v8.6h-4.09v-1.88q-1.23,2.1-4.59,2.1a7,7,0,0,1-3-.59,4.49,4.49,0,0,1-2-1.62,4.28,4.28,0,0,1-.67-2.35,4,4,0,0,1,1.58-3.31,8.11,8.11,0,0,1,4.89-1.2H844a2.8,2.8,0,0,0-.87-2.2,3.83,3.83,0,0,0-2.6-.77,7.73,7.73,0,0,0-2.37.38,6.22,6.22,0,0,0-2,1l-1.57-3.05a9.26,9.26,0,0,1,3-1.34,13,13,0,0,1,3.54-.48A7.83,7.83,0,0,1,846.44,248.07Zm-3.72,10.38a2.79,2.79,0,0,0,1.23-1.52v-1.54h-3c-1.8,0-2.69.59-2.69,1.76a1.56,1.56,0,0,0,.66,1.33,2.92,2.92,0,0,0,1.8.49A3.76,3.76,0,0,0,842.72,258.45Z"
      />
      <path
        class="c"
        d="M866,248.08a6.69,6.69,0,0,1,1.72,5v8.63H863.3v-7.95a4,4,0,0,0-.78-2.68,2.88,2.88,0,0,0-2.27-.88,3.47,3.47,0,0,0-2.63,1,4.21,4.21,0,0,0-1,3v7.45h-4.37V246.62h4.17v1.77a5.69,5.69,0,0,1,2.16-1.47,7.37,7.37,0,0,1,2.82-.52A6.22,6.22,0,0,1,866,248.08Z"
      />
      <path
        class="c"
        d="M887.13,240.91v20.78H883V260a5.78,5.78,0,0,1-4.71,2,7.77,7.77,0,0,1-3.85-1,6.73,6.73,0,0,1-2.7-2.72,9,9,0,0,1,0-8.17,6.8,6.8,0,0,1,2.7-2.72,7.87,7.87,0,0,1,3.85-.95,5.75,5.75,0,0,1,4.51,1.82v-7.31Zm-5.38,16.28a4.77,4.77,0,0,0,0-6.07A3.64,3.64,0,0,0,879,250a3.69,3.69,0,0,0-2.78,1.14,4.77,4.77,0,0,0,0,6.07,3.69,3.69,0,0,0,2.78,1.14A3.64,3.64,0,0,0,881.75,257.19Z"
      />
      <path
        class="c"
        d="m891.38 243.82a2.26 2.26 0 0 1-0.75-1.73 2.29 2.29 0 0 1 0.75-1.74 2.82 2.82 0 0 1 2-0.7 2.86 2.86 0 0 1 2 0.67 2.14 2.14 0 0 1 0.76 1.68 2.37 2.37 0 0 1-0.76 1.81 2.7 2.7 0 0 1-2 0.71 2.77 2.77 0 0 1-2-0.7zm-0.22 2.8h4.37v15.07h-4.37z"
      />
      <path
        class="c"
        d="m913.26 248.08a6.65 6.65 0 0 1 1.73 5v8.63h-4.37v-7.95a3.9 3.9 0 0 0-0.79-2.68 2.85 2.85 0 0 0-2.26-0.88 3.5 3.5 0 0 0-2.64 1 4.26 4.26 0 0 0-1 3v7.45h-4.36v-15.03h4.17v1.77a5.73 5.73 0 0 1 2.15-1.47 7.43 7.43 0 0 1 2.83-0.52 6.18 6.18 0 0 1 4.54 1.68z"
      />
      <path
        class="c"
        d="m934.64 246.62v12.55q0 4.14-2.15 6.16t-6.3 2a15.58 15.58 0 0 1-4.15-0.53 9.09 9.09 0 0 1-3.25-1.54l1.74-3.13a7.29 7.29 0 0 0 2.41 1.24 9.5 9.5 0 0 0 2.91 0.46 4.75 4.75 0 0 0 3.35-1 4.06 4.06 0 0 0 1.07-3.09v-0.65a6.1 6.1 0 0 1-4.76 1.88 8 8 0 0 1-3.79-0.91 6.94 6.94 0 0 1-2.73-2.56 7.69 7.69 0 0 1 0-7.62 6.94 6.94 0 0 1 2.73-2.56 8 8 0 0 1 3.79-0.91 6 6 0 0 1 5 2.16v-1.94zm-5.42 9.73a3.77 3.77 0 0 0 0-5.34 4 4 0 0 0-2.84-1 4 4 0 0 0-2.85 1 3.74 3.74 0 0 0 0 5.34 4 4 0 0 0 2.85 1 4 4 0 0 0 2.84-1z"
      />
      <path
        class="c"
        d="m959.94 243a6.66 6.66 0 0 1 2.95 2.46 7.37 7.37 0 0 1 0 7.55 6.59 6.59 0 0 1-2.95 2.46 11 11 0 0 1-4.52 0.85h-3.95v5.41h-4.54v-19.6h8.49a10.88 10.88 0 0 1 4.52 0.87zm-1.67 8.75a3.5 3.5 0 0 0 0-5 4.73 4.73 0 0 0-3.1-0.89h-3.7v6.81h3.7a4.79 4.79 0 0 0 3.1-0.96z"
      />
      <path
        class="c"
        d="M978.15,248.07a6.36,6.36,0,0,1,1.88,5v8.6h-4.09v-1.88q-1.23,2.1-4.59,2.1a7,7,0,0,1-3-.59,4.49,4.49,0,0,1-2-1.62,4.28,4.28,0,0,1-.67-2.35A4,4,0,0,1,967.3,254a8.11,8.11,0,0,1,4.89-1.2h3.47a2.8,2.8,0,0,0-.87-2.2,3.83,3.83,0,0,0-2.6-.77,7.73,7.73,0,0,0-2.37.38,6.22,6.22,0,0,0-2,1l-1.57-3.05a9.26,9.26,0,0,1,3-1.34,13,13,0,0,1,3.54-.48A7.83,7.83,0,0,1,978.15,248.07Zm-3.72,10.38a2.79,2.79,0,0,0,1.23-1.52v-1.54h-3c-1.8,0-2.69.59-2.69,1.76a1.56,1.56,0,0,0,.66,1.33,2.92,2.92,0,0,0,1.8.49A3.76,3.76,0,0,0,974.43,258.45Z"
      />
      <path
        class="c"
        d="m999.69 246.62v12.55q0 4.14-2.16 6.16t-6.3 2a15.45 15.45 0 0 1-4.14-0.53 9 9 0 0 1-3.25-1.54l1.73-3.13a7.29 7.29 0 0 0 2.41 1.24 9.5 9.5 0 0 0 2.91 0.46 4.73 4.73 0 0 0 3.35-1 4.06 4.06 0 0 0 1.08-3.09v-0.65a6.13 6.13 0 0 1-4.76 1.88 8 8 0 0 1-3.8-0.91 7.07 7.07 0 0 1-2.73-2.56 7.76 7.76 0 0 1 0-7.62 7.07 7.07 0 0 1 2.73-2.56 8 8 0 0 1 3.8-0.91 6 6 0 0 1 5 2.16v-1.94zm-5.42 9.73a3.79 3.79 0 0 0 0-5.34 4 4 0 0 0-2.84-1 4.05 4.05 0 0 0-2.86 1 3.74 3.74 0 0 0 0 5.34 4.05 4.05 0 0 0 2.86 1 4 4 0 0 0 2.84-1z"
      />
      <path
        class="c"
        d="m1018.4 255.39h-11.42a3.56 3.56 0 0 0 1.46 2.21 4.8 4.8 0 0 0 2.85 0.81 5.65 5.65 0 0 0 2.09-0.35 5 5 0 0 0 1.69-1.1l2.33 2.52a7.82 7.82 0 0 1-6.22 2.43 9.88 9.88 0 0 1-4.51-1 7.28 7.28 0 0 1-3-2.76 8 8 0 0 1 0-8 7.4 7.4 0 0 1 2.88-2.78 8.94 8.94 0 0 1 8.1 0 7 7 0 0 1 2.82 2.73 8.16 8.16 0 0 1 1 4.13c0.01 0.04-0.02 0.43-0.07 1.16zm-10.22-4.85a3.61 3.61 0 0 0-1.24 2.3h7.42a3.58 3.58 0 0 0-1.23-2.28 3.67 3.67 0 0 0-2.46-0.86 3.75 3.75 0 0 0-2.49 0.84z"
      />
      <path
        class="c"
        d="M1022.81,261.45a9.84,9.84,0,0,1-2.85-1.16l1.45-3.14a9.29,9.29,0,0,0,2.44,1.05,10.2,10.2,0,0,0,2.8.41c1.85,0,2.77-.46,2.77-1.37a1,1,0,0,0-.76-.93,11.51,11.51,0,0,0-2.32-.47,21.68,21.68,0,0,1-3-.65,4.72,4.72,0,0,1-2.09-1.29,3.68,3.68,0,0,1-.88-2.63,4.13,4.13,0,0,1,.83-2.53,5.28,5.28,0,0,1,2.4-1.72,10.35,10.35,0,0,1,3.74-.62,14.82,14.82,0,0,1,3.18.35,8.76,8.76,0,0,1,2.62,1l-1.46,3.1a8.74,8.74,0,0,0-4.34-1.12,4.32,4.32,0,0,0-2.1.4,1.15,1.15,0,0,0-.7,1,1,1,0,0,0,.76,1,12.9,12.9,0,0,0,2.41.54,25.39,25.39,0,0,1,3,.65,4.54,4.54,0,0,1,2,1.28,3.59,3.59,0,0,1,.87,2.57,4,4,0,0,1-.84,2.5,5.37,5.37,0,0,1-2.45,1.69,10.9,10.9,0,0,1-3.82.6A14.59,14.59,0,0,1,1022.81,261.45Z"
      />
    </g>
    <rect class="b" x="1093" y="212" width="143" height="80" />
    <g class="a">
      <path
        class="c"
        d="m1150.2 253.27a4.89 4.89 0 0 1 1 3.07 4.51 4.51 0 0 1-2 4 10.16 10.16 0 0 1-5.83 1.39h-10.14v-19.6h9.58a9.4 9.4 0 0 1 5.5 1.37 4.34 4.34 0 0 1 1.92 3.72 4.73 4.73 0 0 1-0.69 2.55 4.83 4.83 0 0 1-1.92 1.77 5.11 5.11 0 0 1 2.58 1.73zm-12.51-7.77v4.62h4.51a4.52 4.52 0 0 0 2.54-0.58 2 2 0 0 0 0.87-1.74 1.91 1.91 0 0 0-0.87-1.72 4.61 4.61 0 0 0-2.54-0.58zm8 12.18a2 2 0 0 0 0.91-1.82q0-2.43-3.61-2.43h-5.29v4.84h5.26a4.94 4.94 0 0 0 2.72-0.59z"
      />
      <path class="c" d="m1154.3 240.91h4.37v20.78h-4.37z" />
      <path
        class="c"
        d="m1165.7 260.92a7.37 7.37 0 0 1-3-2.76 8.1 8.1 0 0 1 0-8 7.51 7.51 0 0 1 3-2.76 9.66 9.66 0 0 1 8.52 0 7.52 7.52 0 0 1 2.94 2.76 8 8 0 0 1 0 8 7.38 7.38 0 0 1-2.94 2.76 9.66 9.66 0 0 1-8.52 0zm7-3.73a4.84 4.84 0 0 0 0-6.07 3.92 3.92 0 0 0-5.53 0 4.74 4.74 0 0 0 0 6.07 3.92 3.92 0 0 0 5.53 0z"
      />
      <path
        class="c"
        d="m1196.6 246.62v12.55q0 4.14-2.16 6.16t-6.3 2a15.56 15.56 0 0 1-4.14-0.53 9 9 0 0 1-3.25-1.54l1.74-3.13a7.1 7.1 0 0 0 2.41 1.24 9.5 9.5 0 0 0 2.91 0.46 4.72 4.72 0 0 0 3.34-1 4.06 4.06 0 0 0 1.08-3.09v-0.65a6.11 6.11 0 0 1-4.76 1.88 8 8 0 0 1-3.79-0.91 6.94 6.94 0 0 1-2.73-2.56 7.69 7.69 0 0 1 0-7.62 6.94 6.94 0 0 1 2.73-2.56 8 8 0 0 1 3.79-0.91 6 6 0 0 1 5 2.16v-1.94zm-5.42 9.73a3.77 3.77 0 0 0 0-5.34 4 4 0 0 0-2.84-1 4.05 4.05 0 0 0-2.86 1 3.74 3.74 0 0 0 0 5.34 4.05 4.05 0 0 0 2.86 1 4 4 0 0 0 2.84-1z"
      />
    </g>
    <rect class="b" x="656" y="307" width="248" height="80" />
    <g class="a">
      <path
        class="c"
        d="M703.73,355.72a9.75,9.75,0,0,1-3.84-3.61,10.5,10.5,0,0,1,0-10.44,9.77,9.77,0,0,1,3.84-3.62,12.32,12.32,0,0,1,10.13-.4,9.26,9.26,0,0,1,3.49,2.57l-2.94,2.69a6.31,6.31,0,0,0-5-2.29,6.66,6.66,0,0,0-3.28.79,5.72,5.72,0,0,0-2.24,2.23,6.93,6.93,0,0,0,0,6.5,5.63,5.63,0,0,0,2.24,2.22,6.67,6.67,0,0,0,3.28.8,6.31,6.31,0,0,0,5-2.32l2.94,2.68a9.18,9.18,0,0,1-3.5,2.61,11.84,11.84,0,0,1-4.66.89A11.41,11.41,0,0,1,703.73,355.72Z"
      />
      <path
        class="c"
        d="M731.55,343.07a6.34,6.34,0,0,1,1.89,5v8.6h-4.12v-1.88q-1.25,2.1-4.63,2.1a7.14,7.14,0,0,1-3-.59,4.52,4.52,0,0,1-2-1.62,4.2,4.2,0,0,1-.68-2.35,4,4,0,0,1,1.6-3.31,8.17,8.17,0,0,1,4.92-1.2H729a2.8,2.8,0,0,0-.87-2.2,3.9,3.9,0,0,0-2.63-.77,7.74,7.74,0,0,0-2.38.38,6.22,6.22,0,0,0-2,1l-1.58-3a9.38,9.38,0,0,1,3-1.34,13.25,13.25,0,0,1,3.57-.48A8,8,0,0,1,731.55,343.07Zm-3.76,10.38a2.76,2.76,0,0,0,1.24-1.52v-1.54h-3c-1.8,0-2.71.59-2.71,1.76a1.57,1.57,0,0,0,.67,1.33,3,3,0,0,0,1.82.49A3.8,3.8,0,0,0,727.79,353.45Z"
      />
      <path
        class="c"
        d="M747.21,356a4.47,4.47,0,0,1-1.59.71,8.18,8.18,0,0,1-2,.24,6,6,0,0,1-4.19-1.37,5.26,5.26,0,0,1-1.48-4v-6.19h-2.34V342H738v-3.67h4.41V342h3.78v3.36h-3.78v6.13a2,2,0,0,0,.49,1.47,1.82,1.82,0,0,0,1.4.52,2.84,2.84,0,0,0,1.77-.56Z"
      />
      <path
        class="c"
        d="M761.24,343.07a6.34,6.34,0,0,1,1.89,5v8.6H759v-1.88q-1.25,2.1-4.63,2.1a7.14,7.14,0,0,1-3-.59,4.52,4.52,0,0,1-2-1.62,4.19,4.19,0,0,1-.67-2.35A3.94,3.94,0,0,1,750.3,349a8.19,8.19,0,0,1,4.93-1.2h3.49a2.8,2.8,0,0,0-.87-2.2,3.88,3.88,0,0,0-2.62-.77,7.81,7.81,0,0,0-2.39.38,6.33,6.33,0,0,0-2,1l-1.58-3a9.38,9.38,0,0,1,3-1.34,13.25,13.25,0,0,1,3.57-.48A8,8,0,0,1,761.24,343.07Zm-3.76,10.38a2.76,2.76,0,0,0,1.24-1.52v-1.54h-3c-1.81,0-2.71.59-2.71,1.76a1.56,1.56,0,0,0,.66,1.33,3,3,0,0,0,1.82.49A3.8,3.8,0,0,0,757.48,353.45Z"
      />
      <path class="c" d="m767.11 335.91h4.4v20.78h-4.4z" />
      <path
        class="c"
        d="m778.52 355.92a7.4 7.4 0 0 1-3-2.76 7.63 7.63 0 0 1 3-10.77 9.22 9.22 0 0 1 4.31-1 9.11 9.11 0 0 1 4.29 1 7.64 7.64 0 0 1 0 13.53 9.11 9.11 0 0 1-4.29 1 9.22 9.22 0 0 1-4.31-1zm7.09-3.73a4.8 4.8 0 0 0 0-6.07 3.68 3.68 0 0 0-2.78-1.14 3.74 3.74 0 0 0-2.8 1.14 4.74 4.74 0 0 0 0 6.07 3.74 3.74 0 0 0 2.8 1.14 3.68 3.68 0 0 0 2.78-1.14z"
      />
      <path
        class="c"
        d="m809.75 341.62v12.55q0 4.14-2.17 6.16t-6.35 2a15.8 15.8 0 0 1-4.18-0.53 9.11 9.11 0 0 1-3.27-1.54l1.75-3.13a7.3 7.3 0 0 0 2.43 1.24 9.58 9.58 0 0 0 2.93 0.46 4.77 4.77 0 0 0 3.37-1 4 4 0 0 0 1.09-3.09v-0.65a6.2 6.2 0 0 1-4.8 1.88 8 8 0 0 1-3.82-0.91 6.92 6.92 0 0 1-2.75-2.56 7.63 7.63 0 0 1 0-7.62 6.92 6.92 0 0 1 2.75-2.56 8 8 0 0 1 3.82-0.91 6.06 6.06 0 0 1 5 2.16v-1.94zm-5.46 9.73a3.74 3.74 0 0 0 0-5.34 4 4 0 0 0-2.86-1 4.08 4.08 0 0 0-2.88 1 3.72 3.72 0 0 0 0 5.34 4.08 4.08 0 0 0 2.88 1 4 4 0 0 0 2.86-1z"
      />
      <path
        class="c"
        d="M829.14,341.62v15.07H825V354.9a5.85,5.85,0,0,1-2.09,1.49,6.57,6.57,0,0,1-2.63.52,6.5,6.5,0,0,1-4.74-1.71,6.73,6.73,0,0,1-1.75-5.06v-8.52h4.4v7.87q0,3.64,3.08,3.64a3.33,3.33,0,0,0,2.54-1,4.28,4.28,0,0,0,1-3v-7.45Z"
      />
      <path
        class="c"
        d="m848 350.39h-11.46a3.52 3.52 0 0 0 1.46 2.21 4.94 4.94 0 0 0 2.88 0.81 6 6 0 0 0 2.11-0.35 5 5 0 0 0 1.7-1.1l2.35 2.52a7.92 7.92 0 0 1-6.27 2.43 10 10 0 0 1-4.54-1 7.31 7.31 0 0 1-3.05-2.76 8 8 0 0 1 0-8 7.39 7.39 0 0 1 2.9-2.78 9.09 9.09 0 0 1 8.17 0 7 7 0 0 1 2.84 2.73 8.06 8.06 0 0 1 1 4.13c0.02 0.04-0.01 0.43-0.09 1.16zm-10.3-4.85a3.61 3.61 0 0 0-1.24 2.3h7.54a3.59 3.59 0 0 0-1.24-2.28 3.75 3.75 0 0 0-2.49-0.86 3.82 3.82 0 0 0-2.55 0.84z"
      />
      <path
        class="c"
        d="M852.46,356.45a9.83,9.83,0,0,1-2.88-1.16l1.46-3.14a9.3,9.3,0,0,0,2.46,1.05,10.4,10.4,0,0,0,2.82.41c1.87,0,2.8-.46,2.8-1.37a1,1,0,0,0-.77-.93,10.94,10.94,0,0,0-2.34-.47,23.23,23.23,0,0,1-3.07-.65,4.78,4.78,0,0,1-2.11-1.29,3.64,3.64,0,0,1-.89-2.63,4.08,4.08,0,0,1,.84-2.53A5.3,5.3,0,0,1,853.2,342a10.49,10.49,0,0,1,3.77-.62,15.08,15.08,0,0,1,3.2.35,8.53,8.53,0,0,1,2.64,1l-1.46,3.1A8.92,8.92,0,0,0,857,344.7a4.34,4.34,0,0,0-2.11.4,1.13,1.13,0,0,0-.71,1,1,1,0,0,0,.76,1,13.41,13.41,0,0,0,2.43.54,26.06,26.06,0,0,1,3,.65,4.66,4.66,0,0,1,2.06,1.28,3.59,3.59,0,0,1,.87,2.57,4,4,0,0,1-.84,2.5,5.49,5.49,0,0,1-2.47,1.69,11.18,11.18,0,0,1-3.86.6A14.69,14.69,0,0,1,852.46,356.45Z"
      />
    </g>
    <rect class="b" x="437" y="307" width="204" height="80" />
    <g class="a">
      <path
        class="c"
        d="m510.6 337.09-6.47 19.6h-4.91l-4.34-13.27-4.49 13.27h-4.88l-6.51-19.6h4.74l4.46 13.77 4.65-13.77h4.24l4.53 13.91 4.6-13.89z"
      />
      <path
        class="c"
        d="m526.49 350.39h-11.49a3.57 3.57 0 0 0 1.47 2.21 4.89 4.89 0 0 0 2.88 0.81 5.93 5.93 0 0 0 2.1-0.35 5.14 5.14 0 0 0 1.71-1.1l2.34 2.52a7.91 7.91 0 0 1-6.26 2.43 10 10 0 0 1-4.55-1 7.37 7.37 0 0 1-3.05-2.76 8 8 0 0 1 0-8 7.35 7.35 0 0 1 2.91-2.78 9.09 9.09 0 0 1 8.17 0 7 7 0 0 1 2.83 2.73 8.06 8.06 0 0 1 1 4.13c0.02 0.04-0.01 0.43-0.06 1.16zm-10.3-4.85a3.56 3.56 0 0 0-1.24 2.3h7.47a3.59 3.59 0 0 0-1.24-2.28 3.72 3.72 0 0 0-2.48-0.86 3.79 3.79 0 0 0-2.51 0.84z"
      />
      <path
        class="c"
        d="m542.34 342.37a7 7 0 0 1 2.72 2.71 8.88 8.88 0 0 1 0 8.15 7 7 0 0 1-2.72 2.72 7.87 7.87 0 0 1-3.86 1 5.85 5.85 0 0 1-4.77-2v1.74h-4.2v-20.78h4.4v7.31a5.89 5.89 0 0 1 4.57-1.82 7.77 7.77 0 0 1 3.86 0.97zm-1.84 9.82a4.77 4.77 0 0 0 0-6.07 4 4 0 0 0-5.56 0 4.77 4.77 0 0 0 0 6.07 4 4 0 0 0 5.56 0z"
      />
      <path
        class="c"
        d="M550.39,356.45a10.06,10.06,0,0,1-2.88-1.16l1.47-3.14a9.3,9.3,0,0,0,2.46,1.05,10.4,10.4,0,0,0,2.82.41c1.86,0,2.79-.46,2.79-1.37a1,1,0,0,0-.76-.93,11.23,11.23,0,0,0-2.34-.47,23.78,23.78,0,0,1-3.08-.65,4.69,4.69,0,0,1-2.1-1.29,3.64,3.64,0,0,1-.89-2.63,4.13,4.13,0,0,1,.83-2.53,5.38,5.38,0,0,1,2.43-1.72,10.45,10.45,0,0,1,3.77-.62,15.08,15.08,0,0,1,3.2.35,8.53,8.53,0,0,1,2.64,1l-1.47,3.1a8.85,8.85,0,0,0-4.37-1.12,4.38,4.38,0,0,0-2.12.4,1.14,1.14,0,0,0-.7,1,1,1,0,0,0,.76,1,13.41,13.41,0,0,0,2.43.54,25.84,25.84,0,0,1,3,.65,4.6,4.6,0,0,1,2.06,1.28,3.55,3.55,0,0,1,.88,2.57,3.94,3.94,0,0,1-.85,2.5,5.38,5.38,0,0,1-2.47,1.69,11.08,11.08,0,0,1-3.85.6A14.83,14.83,0,0,1,550.39,356.45Z"
      />
      <path
        class="c"
        d="M564.19,338.82a2.27,2.27,0,0,1-.76-1.73,2.3,2.3,0,0,1,.76-1.74,2.8,2.8,0,0,1,2-.7,2.93,2.93,0,0,1,2,.67,2.18,2.18,0,0,1,.76,1.68,2.41,2.41,0,0,1-.76,1.81,2.77,2.77,0,0,1-2,.71A2.8,2.8,0,0,1,564.19,338.82Zm-.22,2.8h4.4v15.07H564Z"
      />
      <path
        class="c"
        d="M582.26,356a4.52,4.52,0,0,1-1.6.71,8,8,0,0,1-2,.24,6,6,0,0,1-4.19-1.37,5.26,5.26,0,0,1-1.48-4v-6.19h-2.34V342H573v-3.67h4.4V342h3.79v3.36H577.4v6.13a2,2,0,0,0,.5,1.47,1.81,1.81,0,0,0,1.39.52,2.86,2.86,0,0,0,1.78-.56Z"
      />
      <path
        class="c"
        d="m599.05 350.39h-11.49a3.57 3.57 0 0 0 1.44 2.21 4.89 4.89 0 0 0 2.88 0.81 5.93 5.93 0 0 0 2.1-0.35 5.14 5.14 0 0 0 1.71-1.1l2.34 2.52a7.91 7.91 0 0 1-6.26 2.43 10 10 0 0 1-4.55-1 7.34 7.34 0 0 1-3-2.76 7.52 7.52 0 0 1-1.08-4 7.68 7.68 0 0 1 1.06-4 7.48 7.48 0 0 1 2.91-2.78 9.09 9.09 0 0 1 8.17 0 7.1 7.1 0 0 1 2.84 2.73 8.16 8.16 0 0 1 1 4.13c0.02 0.04-0.01 0.43-0.07 1.16zm-10.3-4.85a3.56 3.56 0 0 0-1.24 2.3h7.49a3.64 3.64 0 0 0-1.24-2.28 3.75 3.75 0 0 0-2.49-0.86 3.81 3.81 0 0 0-2.52 0.84z"
      />
    </g>
    <rect class="b" x="919" y="307" width="239" height="80" />
    <g class="a">
      <path
        class="c"
        d="M959.22,337.09h8.9a12.59,12.59,0,0,1,5.65,1.21,9.17,9.17,0,0,1,3.8,3.43,10.45,10.45,0,0,1,0,10.31,9.1,9.1,0,0,1-3.8,3.43,12.59,12.59,0,0,1-5.65,1.22h-8.9ZM967.9,353a6.56,6.56,0,0,0,4.69-1.64,6.49,6.49,0,0,0,0-8.87,6.6,6.6,0,0,0-4.69-1.64h-4.15V353Z"
      />
      <path
        class="c"
        d="M982.26,338.82a2.26,2.26,0,0,1-.75-1.73,2.29,2.29,0,0,1,.75-1.74,2.77,2.77,0,0,1,2-.7,2.86,2.86,0,0,1,2,.67,2.14,2.14,0,0,1,.76,1.68,2.37,2.37,0,0,1-.76,1.81,2.7,2.7,0,0,1-2,.71A2.77,2.77,0,0,1,982.26,338.82Zm-.22,2.8h4.37v15.07H982Z"
      />
      <path
        class="c"
        d="m1006 341.62v12.55q0 4.14-2.16 6.16t-6.3 2a15.45 15.45 0 0 1-4.14-0.53 9 9 0 0 1-3.25-1.54l1.73-3.14a7.12 7.12 0 0 0 2.41 1.25 9.5 9.5 0 0 0 2.91 0.46 4.73 4.73 0 0 0 3.35-1 4.06 4.06 0 0 0 1.08-3.09v-0.65a6.13 6.13 0 0 1-4.76 1.88 8 8 0 0 1-3.8-0.91 7.07 7.07 0 0 1-2.73-2.56 7.76 7.76 0 0 1 0-7.62 7.07 7.07 0 0 1 2.73-2.56 8 8 0 0 1 3.8-0.91 6 6 0 0 1 5 2.15v-1.93zm-5.42 9.73a3.77 3.77 0 0 0 0-5.34 4 4 0 0 0-2.84-1 4.05 4.05 0 0 0-2.86 1 3.74 3.74 0 0 0 0 5.34 4.05 4.05 0 0 0 2.86 1 4 4 0 0 0 2.85-1z"
      />
      <path
        class="c"
        d="M1010.29,338.82a2.27,2.27,0,0,1-.76-1.73,2.3,2.3,0,0,1,.76-1.74,2.75,2.75,0,0,1,2-.7,2.88,2.88,0,0,1,2,.67A2.18,2.18,0,0,1,1015,337a2.41,2.41,0,0,1-.76,1.81,2.72,2.72,0,0,1-2,.71A2.75,2.75,0,0,1,1010.29,338.82Zm-.22,2.8h4.36v15.07h-4.36Z"
      />
      <path
        class="c"
        d="M1028.21,356a4.5,4.5,0,0,1-1.58.71,8,8,0,0,1-2,.24,5.87,5.87,0,0,1-4.15-1.37,5.26,5.26,0,0,1-1.47-4v-6.19h-2.33V342H1019v-3.67h4.36V342h3.76v3.36h-3.76v6.13a2.07,2.07,0,0,0,.49,1.47,1.81,1.81,0,0,0,1.39.52,2.86,2.86,0,0,0,1.77-.56Z"
      />
      <path
        class="c"
        d="m1042.1 343.06a6.4 6.4 0 0 1 1.87 5v8.6h-4.09v-1.88q-1.23 2.1-4.59 2.1a7.15 7.15 0 0 1-3-0.59 4.41 4.41 0 0 1-1.94-1.62 4.23 4.23 0 0 1-0.68-2.35 3.94 3.94 0 0 1 1.59-3.31 8.06 8.06 0 0 1 4.88-1.2h3.47a2.81 2.81 0 0 0-0.86-2.2 3.86 3.86 0 0 0-2.61-0.77 7.62 7.62 0 0 0-2.36 0.38 6.17 6.17 0 0 0-2 1l-1.57-3a9.37 9.37 0 0 1 3-1.34 13.14 13.14 0 0 1 3.54-0.48 7.91 7.91 0 0 1 5.35 1.66zm-3.73 10.39a2.79 2.79 0 0 0 1.23-1.52v-1.54h-3c-1.79 0-2.69 0.58-2.69 1.76a1.57 1.57 0 0 0 0.66 1.33 2.94 2.94 0 0 0 1.8 0.49 3.76 3.76 0 0 0 2-0.52z"
      />
      <path class="c" d="M1048,335.91h4.37v20.78H1048Z" />
      <path
        class="c"
        d="m1077.5 352.49h-9.1l-1.74 4.2h-4.68l8.74-19.6h4.48l8.76 19.6h-4.76zm-1.43-3.45-3.11-7.5-3.11 7.5z"
      />
      <path
        class="c"
        d="M1101,335.91v20.78h-4.17V355a5.74,5.74,0,0,1-4.7,2,7.87,7.87,0,0,1-3.85-1,6.8,6.8,0,0,1-2.7-2.72,9,9,0,0,1,0-8.17,6.8,6.8,0,0,1,2.7-2.72,7.87,7.87,0,0,1,3.85-1,5.76,5.76,0,0,1,4.51,1.82v-7.31Zm-5.37,16.28a4.77,4.77,0,0,0,0-6.07,3.91,3.91,0,0,0-5.52,0,4.77,4.77,0,0,0,0,6.07,3.91,3.91,0,0,0,5.52,0Z"
      />
      <path
        class="c"
        d="M1106.38,356.45a9.84,9.84,0,0,1-2.85-1.16l1.46-3.14a9.08,9.08,0,0,0,2.43,1.05,10.26,10.26,0,0,0,2.8.41c1.85,0,2.77-.46,2.77-1.38a1,1,0,0,0-.75-.92,11.1,11.1,0,0,0-2.33-.47,23.2,23.2,0,0,1-3.05-.65,4.63,4.63,0,0,1-2.08-1.29,3.64,3.64,0,0,1-.89-2.63,4.11,4.11,0,0,1,.83-2.53,5.26,5.26,0,0,1,2.41-1.72,10.28,10.28,0,0,1,3.74-.62,14.8,14.8,0,0,1,3.17.35,8.76,8.76,0,0,1,2.62,1l-1.45,3.11a8.8,8.8,0,0,0-4.34-1.12,4.27,4.27,0,0,0-2.1.4,1.14,1.14,0,0,0-.7,1,1,1,0,0,0,.75,1,13.31,13.31,0,0,0,2.41.54,25.39,25.39,0,0,1,3,.65,4.57,4.57,0,0,1,2,1.28,3.59,3.59,0,0,1,.87,2.57,4.05,4.05,0,0,1-.84,2.5,5.42,5.42,0,0,1-2.45,1.69,11,11,0,0,1-3.83.6A14.59,14.59,0,0,1,1106.38,356.45Z"
      />
    </g>
    <rect class="b" x="1173" y="307" width="259" height="80" />
    <g class="a">
      <path
        class="c"
        d="M1215.9,356.62a10.6,10.6,0,0,1-3.49-1.63l1.54-3.42a10.5,10.5,0,0,0,3,1.48,11,11,0,0,0,3.47.56,5.6,5.6,0,0,0,2.86-.57,1.74,1.74,0,0,0,.92-1.53,1.45,1.45,0,0,0-.55-1.16,4.12,4.12,0,0,0-1.4-.74c-.57-.19-1.34-.39-2.31-.62a31.91,31.91,0,0,1-3.66-1.06,6,6,0,0,1-2.45-1.71,4.57,4.57,0,0,1-1-3.14,5.45,5.45,0,0,1,.93-3.09,6.19,6.19,0,0,1,2.78-2.2,11.4,11.4,0,0,1,4.55-.81,15,15,0,0,1,3.67.45,10.89,10.89,0,0,1,3.14,1.29l-1.4,3.44a10.89,10.89,0,0,0-5.43-1.54,5.12,5.12,0,0,0-2.82.62,1.89,1.89,0,0,0-.91,1.62,1.6,1.6,0,0,0,1,1.5,16.39,16.39,0,0,0,3.21,1,30.77,30.77,0,0,1,3.67,1.06,6.05,6.05,0,0,1,2.45,1.68,4.5,4.5,0,0,1,1,3.11,5.29,5.29,0,0,1-.94,3.06,6.27,6.27,0,0,1-2.81,2.2,11.53,11.53,0,0,1-4.57.81A16,16,0,0,1,1215.9,356.62Z"
      />
      <path
        class="c"
        d="m1234.4 356.15a7.44 7.44 0 0 1-3-2.76 8.1 8.1 0 0 1 0-8 7.51 7.51 0 0 1 3-2.76 9.66 9.66 0 0 1 8.52 0 7.52 7.52 0 0 1 2.94 2.76 8 8 0 0 1 0 8 7.45 7.45 0 0 1-2.94 2.76 9.66 9.66 0 0 1-8.52 0zm7-3.73a4.8 4.8 0 0 0 0-6.07 3.61 3.61 0 0 0-2.75-1.14 3.69 3.69 0 0 0-2.78 1.14 4.77 4.77 0 0 0 0 6.07 3.69 3.69 0 0 0 2.78 1.14 3.61 3.61 0 0 0 2.77-1.14z"
      />
      <path
        class="c"
        d="m1252.8 356.15a7.39 7.39 0 0 1-3-2.76 7.61 7.61 0 0 1 3-10.77 9.3 9.3 0 0 1 4.33-1 8.45 8.45 0 0 1 4.16 1 6 6 0 0 1 2.59 2.84l-3.39 1.83a3.71 3.71 0 0 0-3.39-2.08 3.87 3.87 0 0 0-2.83 1.12 4.73 4.73 0 0 0 0 6.11 3.87 3.87 0 0 0 2.83 1.12 3.68 3.68 0 0 0 3.39-2.07l3.39 1.84a6.05 6.05 0 0 1-2.59 2.8 8.35 8.35 0 0 1-4.16 1 9.3 9.3 0 0 1-4.33-0.98z"
      />
      <path
        class="c"
        d="M1266.62,339.05a2.27,2.27,0,0,1-.76-1.73,2.3,2.3,0,0,1,.76-1.74,2.75,2.75,0,0,1,2-.7,2.88,2.88,0,0,1,2,.67,2.17,2.17,0,0,1,.75,1.68,2.4,2.4,0,0,1-.75,1.81,3.08,3.08,0,0,1-3.92,0Zm-.22,2.8h4.36v15.07h-4.36Z"
      />
      <path
        class="c"
        d="m1286.3 343.29a6.4 6.4 0 0 1 1.87 5v8.6h-4.09v-1.89q-1.23 2.1-4.59 2.1a7 7 0 0 1-3-0.59 4.41 4.41 0 0 1-1.94-1.62 4.2 4.2 0 0 1-0.68-2.35 3.94 3.94 0 0 1 1.59-3.31 8.06 8.06 0 0 1 4.88-1.2h3.47a2.81 2.81 0 0 0-0.86-2.2 3.86 3.86 0 0 0-2.61-0.77 7.62 7.62 0 0 0-2.36 0.38 6.17 6.17 0 0 0-2 1l-1.57-3.05a9.26 9.26 0 0 1 3-1.34 13.14 13.14 0 0 1 3.54-0.48 7.88 7.88 0 0 1 5.35 1.72zm-3.73 10.39a2.79 2.79 0 0 0 1.23-1.52v-1.54h-3c-1.79 0-2.69 0.59-2.69 1.76a1.57 1.57 0 0 0 0.66 1.33 2.94 2.94 0 0 0 1.8 0.49 3.76 3.76 0 0 0 2-0.52z"
      />
      <path class="c" d="m1292.1 336.14h4.37v20.78h-4.37z" />
      <path
        class="c"
        d="m1326.6 356.92v-11.76l-5.77 9.68h-2l-5.74-9.43v11.51h-4.25v-19.6h3.75l7.34 12.18 7.22-12.18h3.72l0.06 19.6z"
      />
      <path
        class="c"
        d="m1349.9 350.62h-11.39a3.54 3.54 0 0 0 1.45 2.21 4.85 4.85 0 0 0 2.86 0.81 5.81 5.81 0 0 0 2.08-0.35 4.89 4.89 0 0 0 1.7-1.11l2.32 2.53a7.82 7.82 0 0 1-6.21 2.43 9.85 9.85 0 0 1-4.51-1 7.31 7.31 0 0 1-3-2.76 7.61 7.61 0 0 1-1.06-4 7.72 7.72 0 0 1 1-4 7.45 7.45 0 0 1 2.88-2.77 8.52 8.52 0 0 1 4.11-1 8.44 8.44 0 0 1 4 0.95 7 7 0 0 1 2.81 2.73 8.06 8.06 0 0 1 1 4.13c0.1 0.08 0.02 0.47-0.04 1.2zm-10.22-4.85a3.6 3.6 0 0 0-1.23 2.3h7.42a3.62 3.62 0 0 0-1.23-2.28 3.7 3.7 0 0 0-2.47-0.86 3.75 3.75 0 0 0-2.49 0.84z"
      />
      <path
        class="c"
        d="M1368.2,336.14v20.78H1364v-1.74a5.74,5.74,0,0,1-4.7,2,7.85,7.85,0,0,1-3.85-.95,6.88,6.88,0,0,1-2.71-2.72,9,9,0,0,1,0-8.17,6.88,6.88,0,0,1,2.71-2.72,7.85,7.85,0,0,1,3.85-.95,5.72,5.72,0,0,1,4.5,1.82v-7.31Zm-5.37,16.28a4.77,4.77,0,0,0,0-6.07,3.91,3.91,0,0,0-5.52,0,4.77,4.77,0,0,0,0,6.07,3.91,3.91,0,0,0,5.52,0Z"
      />
      <path
        class="c"
        d="m1372.5 339.05a2.23 2.23 0 0 1-0.76-1.73 2.27 2.27 0 0 1 0.76-1.74 2.74 2.74 0 0 1 2-0.7 2.89 2.89 0 0 1 2 0.67 2.17 2.17 0 0 1 0.75 1.68 2.4 2.4 0 0 1-0.75 1.81 2.78 2.78 0 0 1-2 0.71 2.74 2.74 0 0 1-2-0.7zm-0.23 2.8h4.37v15.07h-4.37z"
      />
      <path
        class="c"
        d="m1392.1 343.29a6.4 6.4 0 0 1 1.88 5v8.6h-4.09v-1.89q-1.23 2.1-4.59 2.1a7 7 0 0 1-3-0.59 4.49 4.49 0 0 1-2-1.62 4.28 4.28 0 0 1-0.67-2.35 4 4 0 0 1 1.58-3.31 8.11 8.11 0 0 1 4.89-1.2h3.47a2.78 2.78 0 0 0-0.87-2.2 3.83 3.83 0 0 0-2.6-0.77 7.73 7.73 0 0 0-2.37 0.38 6.22 6.22 0 0 0-2 1l-1.57-3.05a9.26 9.26 0 0 1 3-1.34 13 13 0 0 1 3.54-0.48 7.83 7.83 0 0 1 5.4 1.72zm-3.72 10.39a2.79 2.79 0 0 0 1.23-1.52v-1.54h-3c-1.8 0-2.69 0.59-2.69 1.76a1.57 1.57 0 0 0 0.66 1.33 2.92 2.92 0 0 0 1.8 0.49 3.76 3.76 0 0 0 2-0.52z"
      />
    </g>
    <rect class="d hidden" x="76" y="497" width="181" height="80" />
    <g class="a">
      <path
        class="e"
        d="m139.74 546.92v-14.28l-7.08 11.9h-1.29l-7.08-11.82v14.2h-2.69v-19.6h2.29l8.11 13.77 8.06-13.77h2.3v19.6z"
      />
      <path
        class="e"
        d="m150.3 546.11a7.12 7.12 0 0 1-2.76-2.71 8.12 8.12 0 0 1 0-7.81 7 7 0 0 1 2.76-2.69 8.15 8.15 0 0 1 4-1 8.06 8.06 0 0 1 4 1 7.11 7.11 0 0 1 2.75 2.69 8.2 8.2 0 0 1 0 7.81 7.23 7.23 0 0 1-2.75 2.71 8 8 0 0 1-4 1 8.05 8.05 0 0 1-4-1zm6.53-2a4.66 4.66 0 0 0 1.78-1.85 6.06 6.06 0 0 0 0-5.46 4.51 4.51 0 0 0-1.78-1.84 5.44 5.44 0 0 0-5.12 0 4.6 4.6 0 0 0-1.79 1.84 6 6 0 0 0 0 5.46 4.75 4.75 0 0 0 1.79 1.85 5.37 5.37 0 0 0 5.12 0z"
      />
      <path
        class="e"
        d="m177.43 532.89a6.76 6.76 0 0 1 2.67 2.66 7.81 7.81 0 0 1 1 4 7.91 7.91 0 0 1-1 4 6.72 6.72 0 0 1-2.67 2.67 7.76 7.76 0 0 1-3.87 1 7 7 0 0 1-3-0.64 5.81 5.81 0 0 1-2.27-1.87v2.35h-2.59v-20.92h2.68v8.18a5.71 5.71 0 0 1 2.24-1.78 7.16 7.16 0 0 1 2.94-0.6 7.76 7.76 0 0 1 3.87 0.95zm-1.53 11.19a4.75 4.75 0 0 0 1.79-1.85 6 6 0 0 0 0-5.46 4.6 4.6 0 0 0-1.79-1.84 5.07 5.07 0 0 0-2.56-0.64 5.14 5.14 0 0 0-2.55 0.64 4.53 4.53 0 0 0-1.79 1.84 6.14 6.14 0 0 0 0 5.46 4.69 4.69 0 0 0 1.79 1.85 5.14 5.14 0 0 0 2.55 0.65 5.07 5.07 0 0 0 2.56-0.65z"
      />
      <path
        class="e"
        d="M184.83,528.72a1.69,1.69,0,0,1-.52-1.23,1.71,1.71,0,0,1,.52-1.25,1.76,1.76,0,0,1,1.31-.52,1.81,1.81,0,0,1,1.3.49,1.63,1.63,0,0,1,.52,1.22,1.78,1.78,0,0,1-1.82,1.79A1.83,1.83,0,0,1,184.83,528.72Zm0,3.36h2.69v14.84h-2.69Z"
      />
      <path class="e" d="m192.6 526.14h2.69v20.78h-2.69z" />
      <path
        class="e"
        d="m213.77 540.39h-12a4.65 4.65 0 0 0 1.72 3.15 5.59 5.59 0 0 0 3.66 1.19 5.48 5.48 0 0 0 4.28-1.79l1.48 1.74a6.48 6.48 0 0 1-2.5 1.79 9.52 9.52 0 0 1-7.52-0.35 7 7 0 0 1-2.81-2.7 8.23 8.23 0 0 1 0-7.81 6.93 6.93 0 0 1 2.66-2.71 7.61 7.61 0 0 1 3.82-1 7.44 7.44 0 0 1 3.79 1 6.81 6.81 0 0 1 2.61 2.71 8.26 8.26 0 0 1 0.94 4 7 7 0 0 1-0.13 0.78zm-10.51-5a4.75 4.75 0 0 0-1.53 3.08h9.52a4.8 4.8 0 0 0-1.53-3.07 5 5 0 0 0-6.46 0z"
      />
    </g>
    <rect class="d second" x="272" y="497" width="278" height="80" />
    <g class="a">
      <path
        class="e"
        d="M323,545.84a9.49,9.49,0,0,1-3.71-3.59,10.44,10.44,0,0,1,0-10.27,9.52,9.52,0,0,1,3.72-3.59,11,11,0,0,1,5.32-1.3,11.17,11.17,0,0,1,4.2.77,8.79,8.79,0,0,1,3.25,2.28L334,531.91a7.33,7.33,0,0,0-5.52-2.33,8,8,0,0,0-4,1,7.07,7.07,0,0,0-2.75,2.7,7.91,7.91,0,0,0,0,7.7,7.07,7.07,0,0,0,2.75,2.7,8.06,8.06,0,0,0,4,1A7.32,7.32,0,0,0,334,542.3l1.82,1.76a8.88,8.88,0,0,1-3.26,2.3,11.12,11.12,0,0,1-4.22.78A10.75,10.75,0,0,1,323,545.84Z"
      />
      <path
        class="e"
        d="m341.18 546.11a7.16 7.16 0 0 1-2.75-2.71 8.12 8.12 0 0 1 0-7.81 7 7 0 0 1 2.75-2.69 8.22 8.22 0 0 1 4-1 8.09 8.09 0 0 1 4 1 7.11 7.11 0 0 1 2.75 2.69 8.2 8.2 0 0 1 0 7.81 7.23 7.23 0 0 1-2.75 2.71 8 8 0 0 1-4 1 8.12 8.12 0 0 1-4-1zm6.54-2a4.66 4.66 0 0 0 1.78-1.85 6.14 6.14 0 0 0 0-5.46 4.51 4.51 0 0 0-1.78-1.84 5.44 5.44 0 0 0-5.12 0 4.53 4.53 0 0 0-1.79 1.84 6 6 0 0 0 0 5.46 4.69 4.69 0 0 0 1.79 1.85 5.37 5.37 0 0 0 5.12 0z"
      />
      <path
        class="e"
        d="m379.59 533.56a6.51 6.51 0 0 1 1.63 4.82v8.54h-2.68v-8.24a4.69 4.69 0 0 0-1-3.24 3.64 3.64 0 0 0-2.84-1.1 4.26 4.26 0 0 0-3.25 1.28 5.13 5.13 0 0 0-1.2 3.65v7.65h-2.69v-8.24a4.69 4.69 0 0 0-1-3.24 3.66 3.66 0 0 0-2.85-1.1 4.24 4.24 0 0 0-3.24 1.28 5.09 5.09 0 0 0-1.21 3.65v7.65h-2.69v-14.84h2.58v2.21a5.14 5.14 0 0 1 2.13-1.75 7.13 7.13 0 0 1 3-0.6 6.51 6.51 0 0 1 3.08 0.7 4.87 4.87 0 0 1 2.08 2 5.66 5.66 0 0 1 2.33-2 7.59 7.59 0 0 1 3.38-0.73 6.06 6.06 0 0 1 4.44 1.65z"
      />
      <path
        class="e"
        d="m409.18 533.56a6.51 6.51 0 0 1 1.64 4.82v8.54h-2.69v-8.24a4.69 4.69 0 0 0-1-3.24 3.64 3.64 0 0 0-2.84-1.1 4.25 4.25 0 0 0-3.25 1.28 5.13 5.13 0 0 0-1.21 3.65v7.65h-2.68v-8.24a4.69 4.69 0 0 0-1-3.24 3.64 3.64 0 0 0-2.84-1.1 4.26 4.26 0 0 0-3.25 1.28 5.13 5.13 0 0 0-1.2 3.65v7.65h-2.69v-14.84h2.58v2.21a5.18 5.18 0 0 1 2.12-1.75 7.17 7.17 0 0 1 3-0.6 6.53 6.53 0 0 1 3.08 0.7 4.8 4.8 0 0 1 2.07 2 5.62 5.62 0 0 1 2.34-2 7.55 7.55 0 0 1 3.37-0.73 6 6 0 0 1 4.45 1.65z"
      />
      <path
        class="e"
        d="M429.61,532.08v14.84h-2.55v-2.24a5.48,5.48,0,0,1-2.14,1.78,6.71,6.71,0,0,1-2.9.62,6.49,6.49,0,0,1-4.68-1.63,6.37,6.37,0,0,1-1.7-4.83v-8.54h2.68v8.23a4.61,4.61,0,0,0,1,3.26,3.86,3.86,0,0,0,3,1.11,4.42,4.42,0,0,0,3.36-1.29,5.06,5.06,0,0,0,1.23-3.64v-7.67Z"
      />
      <path
        class="e"
        d="m447.15 533.58a6.43 6.43 0 0 1 1.66 4.8v8.54h-2.68v-8.24a4.55 4.55 0 0 0-1-3.24 3.89 3.89 0 0 0-3-1.1 4.63 4.63 0 0 0-3.44 1.28 5 5 0 0 0-1.26 3.65v7.65h-2.69v-14.84h2.58v2.24a5.19 5.19 0 0 1 2.19-1.77 7.75 7.75 0 0 1 3.16-0.61 6.1 6.1 0 0 1 4.48 1.64z"
      />
      <path
        class="e"
        d="M453.84,528.72a1.69,1.69,0,0,1-.52-1.23,1.71,1.71,0,0,1,.52-1.25,1.76,1.76,0,0,1,1.3-.52,1.78,1.78,0,0,1,1.3.49,1.6,1.6,0,0,1,.52,1.22,1.7,1.7,0,0,1-.52,1.27,1.73,1.73,0,0,1-1.3.52A1.82,1.82,0,0,1,453.84,528.72Zm0,3.36h2.69v14.84H453.8Z"
      />
      <path
        class="e"
        d="M470,546.05a4.23,4.23,0,0,1-1.46.77,6,6,0,0,1-1.79.26,4.79,4.79,0,0,1-3.48-1.2,4.59,4.59,0,0,1-1.23-3.44v-8.15h-2.52v-2.21H462v-3.25h2.69v3.25H469v2.21h-4.26v8a2.6,2.6,0,0,0,.6,1.84,2.25,2.25,0,0,0,1.73.65,3.23,3.23,0,0,0,2.09-.7Z"
      />
      <path
        class="e"
        d="M473.24,528.72a1.68,1.68,0,0,1-.51-1.23,1.71,1.71,0,0,1,.51-1.25,1.76,1.76,0,0,1,1.31-.52,1.81,1.81,0,0,1,1.3.49,1.63,1.63,0,0,1,.52,1.22,1.78,1.78,0,0,1-1.82,1.79A1.83,1.83,0,0,1,473.24,528.72Zm0,3.36h2.69v14.84H473.2Z"
      />
      <path
        class="e"
        d="m494.37 540.39h-12a4.65 4.65 0 0 0 1.72 3.15 5.59 5.59 0 0 0 3.66 1.19 5.48 5.48 0 0 0 4.28-1.79l1.48 1.74a6.48 6.48 0 0 1-2.5 1.79 9.52 9.52 0 0 1-7.52-0.35 7 7 0 0 1-2.81-2.7 8.23 8.23 0 0 1 0-7.81 6.93 6.93 0 0 1 2.66-2.71 7.61 7.61 0 0 1 3.82-1 7.44 7.44 0 0 1 3.79 1 6.81 6.81 0 0 1 2.61 2.71 8.26 8.26 0 0 1 0.94 4 7 7 0 0 1-0.13 0.78zm-10.51-5a4.75 4.75 0 0 0-1.53 3.08h9.52a4.8 4.8 0 0 0-1.53-3.07 5 5 0 0 0-6.46 0z"
      />
      <path
        class="e"
        d="M498.91,546.59a7.73,7.73,0,0,1-2.64-1.24l1.12-2.13a8.08,8.08,0,0,0,2.38,1.12,9.85,9.85,0,0,0,2.83.42c2.35,0,3.53-.67,3.53-2a1.31,1.31,0,0,0-.48-1.07,2.94,2.94,0,0,0-1.19-.57c-.47-.12-1.15-.26-2-.41a20.62,20.62,0,0,1-2.92-.64,4.64,4.64,0,0,1-1.95-1.23,3.46,3.46,0,0,1-.81-2.44,3.79,3.79,0,0,1,1.68-3.23,7.47,7.47,0,0,1,4.51-1.22,12.57,12.57,0,0,1,3,.36,7.84,7.84,0,0,1,2.43,1l-1.15,2.13a7.66,7.66,0,0,0-4.28-1.18,5,5,0,0,0-2.6.56,1.7,1.7,0,0,0-.9,1.49,1.42,1.42,0,0,0,.5,1.14,3.13,3.13,0,0,0,1.25.62c.5.13,1.19.28,2.09.45a20.49,20.49,0,0,1,2.89.66,4.27,4.27,0,0,1,1.89,1.19,3.28,3.28,0,0,1,.79,2.35,3.72,3.72,0,0,1-1.72,3.2,8.1,8.1,0,0,1-4.69,1.19A12.28,12.28,0,0,1,498.91,546.59Z"
      />
    </g>
    <rect class="d third" x="565" y="497" width="232" height="80" />
    <g class="a">
      <path
        class="e"
        d="m638.26 527.32-6.55 19.6h-2.94l-5.35-15.66-5.35 15.66h-3l-6.56-19.6h2.89l5.29 15.93 5.52-15.93h2.57l5.41 16 5.4-16z"
      />
      <path
        class="e"
        d="m653.52 540.39h-12a4.65 4.65 0 0 0 1.72 3.15 5.59 5.59 0 0 0 3.66 1.19 5.48 5.48 0 0 0 4.28-1.79l1.48 1.74a6.48 6.48 0 0 1-2.5 1.79 9.52 9.52 0 0 1-7.52-0.35 7 7 0 0 1-2.81-2.7 8.23 8.23 0 0 1 0-7.81 6.93 6.93 0 0 1 2.66-2.71 7.61 7.61 0 0 1 3.82-1 7.44 7.44 0 0 1 3.79 1 6.88 6.88 0 0 1 2.61 2.71 8.26 8.26 0 0 1 0.94 4 7 7 0 0 1-0.13 0.78zm-10.51-5a4.75 4.75 0 0 0-1.53 3.08h9.52a4.8 4.8 0 0 0-1.53-3.07 5 5 0 0 0-6.46 0z"
      />
      <path
        class="e"
        d="M667,533.45a5.85,5.85,0,0,1,1.64,4.51v9h-2.55v-2a4.31,4.31,0,0,1-1.92,1.58,7.36,7.36,0,0,1-2.95.54,6.2,6.2,0,0,1-4-1.2,4.13,4.13,0,0,1-.07-6.31,7,7,0,0,1,4.54-1.19H666v-.54a3.42,3.42,0,0,0-1-2.66,4.27,4.27,0,0,0-3-.92,7.86,7.86,0,0,0-2.58.44,6.4,6.4,0,0,0-2.13,1.16l-1.12-2a8,8,0,0,1,2.75-1.41,11.32,11.32,0,0,1,3.38-.49A6.69,6.69,0,0,1,667,533.45Zm-2.67,10.88a3.94,3.94,0,0,0,1.62-2v-2.07h-4.14c-2.28,0-3.42.76-3.42,2.3a2.08,2.08,0,0,0,.87,1.76,4,4,0,0,0,2.41.64A5.08,5.08,0,0,0,664.33,544.33Z"
      />
      <path
        class="e"
        d="m678.27 532.61a7.52 7.52 0 0 1 3.33-0.67v2.6a4.82 4.82 0 0 0-0.61 0 4.51 4.51 0 0 0-3.43 1.31 5.14 5.14 0 0 0-1.25 3.71v7.39h-2.69v-14.87h2.58v2.49a4.63 4.63 0 0 1 2.07-1.96z"
      />
      <path
        class="e"
        d="M695,533.45a5.85,5.85,0,0,1,1.64,4.51v9h-2.55v-2a4.31,4.31,0,0,1-1.92,1.58,7.36,7.36,0,0,1-2.95.54,6.2,6.2,0,0,1-4-1.2,4.13,4.13,0,0,1-.07-6.31,7,7,0,0,1,4.54-1.19H694v-.54a3.42,3.42,0,0,0-1-2.66,4.27,4.27,0,0,0-3-.92,7.86,7.86,0,0,0-2.58.44,6.4,6.4,0,0,0-2.13,1.16l-1.12-2a8,8,0,0,1,2.75-1.41,11.32,11.32,0,0,1,3.38-.49A6.69,6.69,0,0,1,695,533.45Zm-2.67,10.88a3.94,3.94,0,0,0,1.62-2v-2.07h-4.14c-2.28,0-3.42.76-3.42,2.3a2.08,2.08,0,0,0,.87,1.76,4,4,0,0,0,2.41.64A5.08,5.08,0,0,0,692.33,544.33Z"
      />
      <path
        class="e"
        d="m713.35 532.89a6.79 6.79 0 0 1 2.68 2.66 7.92 7.92 0 0 1 1 4 8 8 0 0 1-1 4 6.75 6.75 0 0 1-2.68 2.67 7.7 7.7 0 0 1-3.86 1 7 7 0 0 1-3-0.64 5.81 5.81 0 0 1-2.27-1.87v2.35h-2.58v-20.92h2.69v8.18a5.71 5.71 0 0 1 2.24-1.78 7.16 7.16 0 0 1 2.94-0.6 7.7 7.7 0 0 1 3.84 0.95zm-1.52 11.19a4.83 4.83 0 0 0 1.79-1.85 6 6 0 0 0 0-5.46 4.66 4.66 0 0 0-1.79-1.84 5.42 5.42 0 0 0-5.11 0 4.47 4.47 0 0 0-1.79 1.84 6.06 6.06 0 0 0 0 5.46 4.62 4.62 0 0 0 1.79 1.85 5.35 5.35 0 0 0 5.11 0z"
      />
      <path class="e" d="m720.72 526.14h2.69v20.78h-2.69z" />
      <path
        class="e"
        d="m741.89 540.39h-12a4.65 4.65 0 0 0 1.72 3.15 5.58 5.58 0 0 0 3.65 1.19 5.5 5.5 0 0 0 4.29-1.79l1.48 1.74a6.52 6.52 0 0 1-2.51 1.79 8.68 8.68 0 0 1-3.34 0.61 8.79 8.79 0 0 1-4.17-1 7 7 0 0 1-2.82-2.7 8.31 8.31 0 0 1 0-7.81 7.07 7.07 0 0 1 2.66-2.71 7.64 7.64 0 0 1 3.83-1 7.44 7.44 0 0 1 3.79 1 6.79 6.79 0 0 1 2.6 2.71 8.15 8.15 0 0 1 0.94 4c-0.07 0.22-0.09 0.49-0.12 0.82zm-10.52-5a4.74 4.74 0 0 0-1.52 3.08h9.52a4.8 4.8 0 0 0-1.53-3.07 4.61 4.61 0 0 0-3.23-1.18 4.67 4.67 0 0 0-3.24 1.16z"
      />
      <path
        class="e"
        d="M746.42,546.59a7.58,7.58,0,0,1-2.63-1.24l1.12-2.13a8,8,0,0,0,2.38,1.12,9.8,9.8,0,0,0,2.83.42c2.35,0,3.53-.67,3.53-2a1.31,1.31,0,0,0-.48-1.07,3,3,0,0,0-1.19-.57c-.48-.12-1.15-.26-2-.41a21.13,21.13,0,0,1-2.93-.64,4.67,4.67,0,0,1-1.94-1.23,3.46,3.46,0,0,1-.81-2.44,3.79,3.79,0,0,1,1.68-3.23,7.45,7.45,0,0,1,4.5-1.22,12.57,12.57,0,0,1,3,.36,8,8,0,0,1,2.44,1l-1.15,2.13a7.66,7.66,0,0,0-4.28-1.18,5,5,0,0,0-2.61.56,1.71,1.71,0,0,0-.89,1.49,1.39,1.39,0,0,0,.5,1.14,3.06,3.06,0,0,0,1.25.62c.49.13,1.19.28,2.08.45a20.67,20.67,0,0,1,2.9.66,4.27,4.27,0,0,1,1.89,1.19,3.32,3.32,0,0,1,.78,2.35,3.69,3.69,0,0,1-1.72,3.2,8.08,8.08,0,0,1-4.69,1.19A12.33,12.33,0,0,1,746.42,546.59Z"
      />
    </g>
    <rect class="d fourth" x="812" y="497" width="323" height="80" />
    <g class="a">
      <path class="e" d="M856.57,527.32h2.8v17.16H870v2.44H856.57Z" />
      <path
        class="e"
        d="m886 540.39h-12a4.65 4.65 0 0 0 1.72 3.15 5.58 5.58 0 0 0 3.65 1.19 5.48 5.48 0 0 0 4.29-1.79l1.48 1.74a6.56 6.56 0 0 1-2.5 1.79 9.52 9.52 0 0 1-7.52-0.35 7 7 0 0 1-2.82-2.7 8.27 8.27 0 0 1 0-7.81 6.93 6.93 0 0 1 2.66-2.71 7.61 7.61 0 0 1 3.82-1 7.44 7.44 0 0 1 3.79 1 6.88 6.88 0 0 1 2.61 2.71 8.25 8.25 0 0 1 0.93 4 6.67 6.67 0 0 1-0.11 0.78zm-10.52-5a4.79 4.79 0 0 0-1.52 3.08h9.52a4.8 4.8 0 0 0-1.53-3.07 4.61 4.61 0 0 0-3.23-1.18 4.67 4.67 0 0 0-3.27 1.16z"
      />
      <path
        class="e"
        d="M899.45,533.45a5.85,5.85,0,0,1,1.64,4.51v9h-2.55v-2a4.31,4.31,0,0,1-1.92,1.58,7.36,7.36,0,0,1-3,.54,6.2,6.2,0,0,1-4-1.2,4.13,4.13,0,0,1-.07-6.31,7,7,0,0,1,4.53-1.19h4.26v-.54a3.45,3.45,0,0,0-1-2.66,4.28,4.28,0,0,0-3-.92,7.84,7.84,0,0,0-2.57.44,6.4,6.4,0,0,0-2.13,1.16l-1.12-2a8,8,0,0,1,2.74-1.41,11.44,11.44,0,0,1,3.39-.49A6.69,6.69,0,0,1,899.45,533.45Zm-2.67,10.88a4,4,0,0,0,1.62-2v-2.07h-4.14c-2.28,0-3.42.76-3.42,2.3a2.08,2.08,0,0,0,.87,1.76,4,4,0,0,0,2.41.64A5.08,5.08,0,0,0,896.78,544.33Z"
      />
      <path
        class="e"
        d="m910.72 532.61a7.49 7.49 0 0 1 3.33-0.67v2.6a4.82 4.82 0 0 0-0.61 0 4.51 4.51 0 0 0-3.43 1.31 5.14 5.14 0 0 0-1.25 3.71v7.39h-2.69v-14.87h2.58v2.49a4.58 4.58 0 0 1 2.07-1.96z"
      />
      <path
        class="e"
        d="m929.77 533.58a6.44 6.44 0 0 1 1.67 4.8v8.54h-2.69v-8.24a4.55 4.55 0 0 0-1-3.24 3.89 3.89 0 0 0-3-1.1 4.63 4.63 0 0 0-3.45 1.28 5 5 0 0 0-1.26 3.65v7.65h-2.68v-14.84h2.57v2.24a5.22 5.22 0 0 1 2.2-1.77 7.66 7.66 0 0 1 3.15-0.61 6.08 6.08 0 0 1 4.49 1.64z"
      />
      <path
        class="e"
        d="M936.47,528.72a1.65,1.65,0,0,1-.52-1.23,1.68,1.68,0,0,1,.52-1.25,1.74,1.74,0,0,1,1.3-.52,1.81,1.81,0,0,1,1.3.49,1.6,1.6,0,0,1,.52,1.22,1.7,1.7,0,0,1-.52,1.27,1.76,1.76,0,0,1-1.3.52A1.8,1.8,0,0,1,936.47,528.72Zm-.05,3.36h2.69v14.84h-2.69Z"
      />
      <path
        class="e"
        d="m956.65 533.58a6.44 6.44 0 0 1 1.67 4.8v8.54h-2.69v-8.24a4.55 4.55 0 0 0-1-3.24 3.89 3.89 0 0 0-3-1.1 4.63 4.63 0 0 0-3.45 1.28 5 5 0 0 0-1.26 3.65v7.65h-2.68v-14.84h2.57v2.24a5.22 5.22 0 0 1 2.2-1.77 7.66 7.66 0 0 1 3.15-0.61 6.1 6.1 0 0 1 4.49 1.64z"
      />
      <path
        class="e"
        d="M977.58,532.08V544.9c0,2.6-.64,4.51-1.93,5.76s-3.21,1.86-5.77,1.86a13.53,13.53,0,0,1-4-.58,7.85,7.85,0,0,1-3.08-1.64l1.29-2.07a7.6,7.6,0,0,0,2.56,1.42,9.91,9.91,0,0,0,3.15.52,5.28,5.28,0,0,0,3.85-1.24,5.07,5.07,0,0,0,1.25-3.75V544a6.07,6.07,0,0,1-2.34,1.73,7.87,7.87,0,0,1-3,.57,8.07,8.07,0,0,1-3.85-.91,6.77,6.77,0,0,1-2.7-2.55,7.61,7.61,0,0,1,0-7.47,6.68,6.68,0,0,1,2.7-2.54,8.3,8.3,0,0,1,3.85-.89,7.86,7.86,0,0,1,3.12.61,5.86,5.86,0,0,1,2.37,1.82v-2.29Zm-5.11,11.25a4.5,4.5,0,0,0,1.82-1.72,4.79,4.79,0,0,0,.66-2.5,4.53,4.53,0,0,0-1.43-3.49,5.76,5.76,0,0,0-7.36,0,4.56,4.56,0,0,0-1.43,3.49,4.79,4.79,0,0,0,.66,2.5,4.5,4.5,0,0,0,1.82,1.72,5.57,5.57,0,0,0,2.65.62A5.47,5.47,0,0,0,972.47,543.33Z"
      />
      <path
        class="e"
        d="m994.09 545.84a9.56 9.56 0 0 1-3.71-3.59 10.51 10.51 0 0 1 0-10.27 9.65 9.65 0 0 1 3.72-3.59 11 11 0 0 1 5.32-1.3 11.17 11.17 0 0 1 4.2 0.77 8.71 8.71 0 0 1 3.25 2.28l-1.82 1.77a7.29 7.29 0 0 0-5.51-2.33 7.91 7.91 0 0 0-3.95 1 7 7 0 0 0-2.76 2.7 7.91 7.91 0 0 0 0 7.7 7 7 0 0 0 2.76 2.7 8 8 0 0 0 3.95 1 7.3 7.3 0 0 0 5.51-2.35l1.82 1.76a8.71 8.71 0 0 1-3.26 2.3 11.07 11.07 0 0 1-4.21 0.78 10.82 10.82 0 0 1-5.31-1.33z"
      />
      <path
        class="e"
        d="m1023.2 540.39h-12a4.62 4.62 0 0 0 1.73 3.15 5.55 5.55 0 0 0 3.65 1.19 5.48 5.48 0 0 0 4.28-1.79l1.49 1.74a6.52 6.52 0 0 1-2.51 1.79 8.73 8.73 0 0 1-3.35 0.61 8.84 8.84 0 0 1-4.17-1 7 7 0 0 1-2.81-2.7 8.31 8.31 0 0 1 0-7.81 7 7 0 0 1 2.66-2.71 7.61 7.61 0 0 1 3.82-1 7.48 7.48 0 0 1 3.8 1 6.79 6.79 0 0 1 2.6 2.71 8.15 8.15 0 0 1 0.94 4 7 7 0 0 1-0.13 0.82zm-10.51-5a4.75 4.75 0 0 0-1.53 3.08h9.52a4.83 4.83 0 0 0-1.52-3.07 5 5 0 0 0-6.47 0z"
      />
      <path
        class="e"
        d="m1039.4 533.58a6.44 6.44 0 0 1 1.67 4.8v8.54h-2.69v-8.24a4.55 4.55 0 0 0-1-3.24 3.89 3.89 0 0 0-3-1.1 4.63 4.63 0 0 0-3.45 1.28 5 5 0 0 0-1.26 3.65v7.65h-2.69v-14.84h2.58v2.24a5.16 5.16 0 0 1 2.2-1.77 7.66 7.66 0 0 1 3.15-0.61 6.1 6.1 0 0 1 4.49 1.64z"
      />
      <path
        class="e"
        d="M1054.42,546.05a4.23,4.23,0,0,1-1.46.77,6,6,0,0,1-1.79.26,4.75,4.75,0,0,1-3.47-1.2,4.56,4.56,0,0,1-1.24-3.44v-8.15h-2.52v-2.21h2.52v-3.25h2.69v3.25h4.26v2.21h-4.26v8a2.6,2.6,0,0,0,.6,1.84,2.25,2.25,0,0,0,1.73.65,3.28,3.28,0,0,0,2.1-.7Z"
      />
      <path
        class="e"
        d="m1070.5 540.39h-12a4.61 4.61 0 0 0 1.72 3.15 5.58 5.58 0 0 0 3.65 1.19 5.51 5.51 0 0 0 4.29-1.79l1.48 1.74a6.52 6.52 0 0 1-2.51 1.79 9.5 9.5 0 0 1-7.51-0.35 6.93 6.93 0 0 1-2.82-2.7 8.31 8.31 0 0 1 0-7.81 7 7 0 0 1 2.66-2.71 7.64 7.64 0 0 1 3.83-1 7.47 7.47 0 0 1 3.79 1 6.79 6.79 0 0 1 2.6 2.71 8.15 8.15 0 0 1 0.94 4c-0.07 0.18-0.09 0.45-0.12 0.78zm-10.52-5a4.7 4.7 0 0 0-1.52 3.08h9.52a4.8 4.8 0 0 0-1.53-3.07 5 5 0 0 0-6.47 0z"
      />
      <path
        class="e"
        d="m1078.9 532.61a7.54 7.54 0 0 1 3.34-0.67v2.6a5.11 5.11 0 0 0-0.62 0 4.51 4.51 0 0 0-3.43 1.31 5.14 5.14 0 0 0-1.25 3.71v7.39h-2.69v-14.87h2.58v2.49a4.63 4.63 0 0 1 2.07-1.96z"
      />
      <path
        class="e"
        d="M1086.45,546.59a7.73,7.73,0,0,1-2.64-1.24l1.12-2.13a8.08,8.08,0,0,0,2.38,1.12,9.85,9.85,0,0,0,2.83.42c2.35,0,3.53-.67,3.53-2a1.31,1.31,0,0,0-.48-1.07,2.94,2.94,0,0,0-1.19-.57c-.47-.12-1.15-.26-2-.41a20.62,20.62,0,0,1-2.92-.64,4.64,4.64,0,0,1-2-1.23,3.46,3.46,0,0,1-.81-2.44,3.79,3.79,0,0,1,1.68-3.23,7.47,7.47,0,0,1,4.51-1.22,12.57,12.57,0,0,1,3,.36,7.84,7.84,0,0,1,2.43,1l-1.15,2.13a7.66,7.66,0,0,0-4.28-1.18,5,5,0,0,0-2.6.56,1.7,1.7,0,0,0-.9,1.49,1.42,1.42,0,0,0,.5,1.14,3.13,3.13,0,0,0,1.25.62c.5.13,1.19.28,2.09.45a20.49,20.49,0,0,1,2.89.66,4.27,4.27,0,0,1,1.89,1.19,3.28,3.28,0,0,1,.79,2.35,3.72,3.72,0,0,1-1.72,3.2,8.1,8.1,0,0,1-4.69,1.19A12.28,12.28,0,0,1,1086.45,546.59Z"
      />
    </g>
    <rect class="d fifth" x="1150" y="497" width="288" height="80" />
    <g class="a">
      <path
        class="e"
        d="m1212.2 529.14a6.83 6.83 0 0 1 0 10 9 9 0 0 1-6 1.82h-4.84v5.94h-2.8v-19.6h7.64a9 9 0 0 1 6 1.84zm-2 8.27a4.5 4.5 0 0 0 0-6.52 6.31 6.31 0 0 0-4.06-1.14h-4.76v8.79h4.76a6.31 6.31 0 0 0 4.05-1.13z"
      />
      <path
        class="e"
        d="m1222.5 532.61a7.46 7.46 0 0 1 3.33-0.67v2.6a4.88 4.88 0 0 0-0.62 0 4.53 4.53 0 0 0-3.43 1.31 5.13 5.13 0 0 0-1.24 3.71v7.39h-2.69v-14.87h2.58v2.49a4.53 4.53 0 0 1 2.07-1.96z"
      />
      <path
        class="e"
        d="m1231.4 546.11a7.16 7.16 0 0 1-2.75-2.71 8.12 8.12 0 0 1 0-7.81 7 7 0 0 1 2.75-2.69 8.19 8.19 0 0 1 4-1 8.09 8.09 0 0 1 4 1 7.11 7.11 0 0 1 2.75 2.69 8.2 8.2 0 0 1 0 7.81 7.23 7.23 0 0 1-2.75 2.71 8 8 0 0 1-4 1 8.09 8.09 0 0 1-4-1zm6.54-2a4.66 4.66 0 0 0 1.78-1.85 6.14 6.14 0 0 0 0-5.46 4.51 4.51 0 0 0-1.78-1.84 5.44 5.44 0 0 0-5.12 0 4.53 4.53 0 0 0-1.79 1.84 6 6 0 0 0 0 5.46 4.69 4.69 0 0 0 1.79 1.85 5.37 5.37 0 0 0 5.12 0z"
      />
      <path
        class="e"
        d="M1260.78,526.14v20.78h-2.57v-2.35a5.88,5.88,0,0,1-2.27,1.87,7,7,0,0,1-3,.64,7.76,7.76,0,0,1-3.87-1,6.72,6.72,0,0,1-2.67-2.67,7.91,7.91,0,0,1-1-4,7.81,7.81,0,0,1,1-4,6.76,6.76,0,0,1,2.67-2.66,7.76,7.76,0,0,1,3.87-.95,7.16,7.16,0,0,1,2.94.6,5.71,5.71,0,0,1,2.24,1.78v-8.18Zm-5.09,17.94a4.69,4.69,0,0,0,1.79-1.85,6.14,6.14,0,0,0,0-5.46,4.53,4.53,0,0,0-1.79-1.84,5.14,5.14,0,0,0-2.55-.64,5.07,5.07,0,0,0-2.56.64,4.53,4.53,0,0,0-1.79,1.84,6,6,0,0,0,0,5.46,4.69,4.69,0,0,0,1.79,1.85,5.07,5.07,0,0,0,2.56.65A5.14,5.14,0,0,0,1255.69,544.08Z"
      />
      <path
        class="e"
        d="M1279.71,532.08v14.84h-2.55v-2.24a5.41,5.41,0,0,1-2.14,1.78,6.68,6.68,0,0,1-2.9.62,6.46,6.46,0,0,1-4.67-1.63,6.38,6.38,0,0,1-1.71-4.83v-8.54h2.69v8.23a4.6,4.6,0,0,0,1,3.26,3.86,3.86,0,0,0,3,1.11,4.42,4.42,0,0,0,3.36-1.29,5.06,5.06,0,0,0,1.23-3.64v-7.67Z"
      />
      <path
        class="e"
        d="M1287.26,546.11a7.2,7.2,0,0,1-2.79-2.71,8.05,8.05,0,0,1,0-7.81,7.08,7.08,0,0,1,2.79-2.69,8.35,8.35,0,0,1,4-1,7.76,7.76,0,0,1,3.6.81,5.73,5.73,0,0,1,2.45,2.35l-2,1.32a4.66,4.66,0,0,0-1.74-1.6,5,5,0,0,0-2.3-.53,5.26,5.26,0,0,0-2.61.64,4.54,4.54,0,0,0-1.82,1.84,6,6,0,0,0,0,5.48,4.54,4.54,0,0,0,1.82,1.84,5.26,5.26,0,0,0,2.61.64,4.87,4.87,0,0,0,2.3-.53,4.63,4.63,0,0,0,1.74-1.59l2,1.28a5.83,5.83,0,0,1-2.45,2.37,7.64,7.64,0,0,1-3.6.82A8.24,8.24,0,0,1,1287.26,546.11Z"
      />
      <path
        class="e"
        d="M1309.36,546.05a4.18,4.18,0,0,1-1.45.77,6,6,0,0,1-1.8.26,4.77,4.77,0,0,1-3.47-1.2,4.59,4.59,0,0,1-1.23-3.44v-8.15h-2.52v-2.21h2.52v-3.25h2.69v3.25h4.25v2.21h-4.25v8a2.6,2.6,0,0,0,.6,1.84,2.25,2.25,0,0,0,1.72.65,3.28,3.28,0,0,0,2.1-.7Z"
      />
      <path
        class="e"
        d="m1324.4 529.75h-6.72v-2.43h16.21v2.43h-6.72v17.17h-2.77z"
      />
      <path
        class="e"
        d="m1337.4 546.11a7.12 7.12 0 0 1-2.76-2.71 8.2 8.2 0 0 1 0-7.81 7 7 0 0 1 2.76-2.69 8.69 8.69 0 0 1 7.94 0 7.08 7.08 0 0 1 2.74 2.69 8.2 8.2 0 0 1 0 7.81 7.2 7.2 0 0 1-2.74 2.71 8.61 8.61 0 0 1-7.94 0zm6.54-2a4.71 4.71 0 0 0 1.77-1.85 6.06 6.06 0 0 0 0-5.46 4.55 4.55 0 0 0-1.77-1.84 5.46 5.46 0 0 0-5.13 0 4.66 4.66 0 0 0-1.79 1.84 6 6 0 0 0 0 5.46 4.83 4.83 0 0 0 1.79 1.85 5.39 5.39 0 0 0 5.13 0z"
      />
      <path
        class="e"
        d="M1366.65,532.08v14.84h-2.55v-2.24a5.41,5.41,0,0,1-2.14,1.78,6.68,6.68,0,0,1-2.9.62,6.45,6.45,0,0,1-4.67-1.63,6.38,6.38,0,0,1-1.71-4.83v-8.54h2.69v8.23a4.6,4.6,0,0,0,1,3.26,3.86,3.86,0,0,0,3,1.11,4.42,4.42,0,0,0,3.36-1.29,5.06,5.06,0,0,0,1.23-3.64v-7.67Z"
      />
      <path
        class="e"
        d="m1376.4 532.61a7.49 7.49 0 0 1 3.33-0.67v2.6a4.82 4.82 0 0 0-0.61 0 4.51 4.51 0 0 0-3.43 1.31 5.14 5.14 0 0 0-1.25 3.71v7.39h-2.69v-14.87h2.58v2.49a4.63 4.63 0 0 1 2.07-1.96z"
      />
      <path
        class="e"
        d="M1383.92,546.59a7.67,7.67,0,0,1-2.63-1.24l1.12-2.13a8.08,8.08,0,0,0,2.38,1.12,9.85,9.85,0,0,0,2.83.42c2.35,0,3.53-.67,3.53-2a1.31,1.31,0,0,0-.48-1.07,2.94,2.94,0,0,0-1.19-.57c-.47-.12-1.15-.26-2-.41a20.62,20.62,0,0,1-2.92-.64,4.64,4.64,0,0,1-2-1.23,3.46,3.46,0,0,1-.81-2.44,3.79,3.79,0,0,1,1.68-3.23,7.47,7.47,0,0,1,4.51-1.22,12.42,12.42,0,0,1,3,.36,7.9,7.9,0,0,1,2.44,1l-1.15,2.13a7.66,7.66,0,0,0-4.28-1.18,5,5,0,0,0-2.6.56,1.7,1.7,0,0,0-.9,1.49,1.42,1.42,0,0,0,.5,1.14,3.06,3.06,0,0,0,1.25.62c.49.13,1.19.28,2.08.45a20.34,20.34,0,0,1,2.9.66,4.27,4.27,0,0,1,1.89,1.19,3.28,3.28,0,0,1,.79,2.35,3.72,3.72,0,0,1-1.72,3.2,8.12,8.12,0,0,1-4.7,1.19A12.33,12.33,0,0,1,1383.92,546.59Z"
      />
    </g>
    <rect class="d" x="1453" y="497" width="277" height="80" />
    <g class="a">
      <path
        class="e"
        d="M1504,546.46a8.74,8.74,0,0,1-3.23-1.78l1-2.19a8.55,8.55,0,0,0,2.87,1.64,10.58,10.58,0,0,0,3.6.63,6.4,6.4,0,0,0,3.64-.83,2.54,2.54,0,0,0,1.2-2.19,2.2,2.2,0,0,0-.65-1.64,4.48,4.48,0,0,0-1.63-1q-1-.33-2.73-.75a28.49,28.49,0,0,1-3.57-1.07,5.83,5.83,0,0,1-2.32-1.64,4.33,4.33,0,0,1-1-3,5,5,0,0,1,.83-2.82,5.63,5.63,0,0,1,2.5-2,10.08,10.08,0,0,1,4.18-.76,13.14,13.14,0,0,1,3.41.45,9.23,9.23,0,0,1,2.89,1.29l-.93,2.24a10.22,10.22,0,0,0-2.63-1.19,9.84,9.84,0,0,0-2.74-.41,6,6,0,0,0-3.57.87,2.65,2.65,0,0,0-1.19,2.24,2.14,2.14,0,0,0,.67,1.64,4.63,4.63,0,0,0,1.66,1,27.33,27.33,0,0,0,2.71.74,28.92,28.92,0,0,1,3.55,1.06,6,6,0,0,1,2.31,1.63,4.26,4.26,0,0,1,1,2.94,5,5,0,0,1-.84,2.81,5.66,5.66,0,0,1-2.55,2,10.63,10.63,0,0,1-4.2.74A13.63,13.63,0,0,1,1504,546.46Z"
      />
      <path
        class="e"
        d="m1521.9 546.11a7.16 7.16 0 0 1-2.75-2.71 8.12 8.12 0 0 1 0-7.81 7 7 0 0 1 2.75-2.69 8.22 8.22 0 0 1 4-1 8.11 8.11 0 0 1 4 1 7.11 7.11 0 0 1 2.75 2.69 8.2 8.2 0 0 1 0 7.81 7.23 7.23 0 0 1-2.75 2.71 8 8 0 0 1-4 1 8.12 8.12 0 0 1-4-1zm6.54-2a4.66 4.66 0 0 0 1.78-1.85 6.14 6.14 0 0 0 0-5.46 4.51 4.51 0 0 0-1.78-1.84 5.44 5.44 0 0 0-5.12 0 4.53 4.53 0 0 0-1.79 1.84 6 6 0 0 0 0 5.46 4.69 4.69 0 0 0 1.79 1.85 5.37 5.37 0 0 0 5.12 0z"
      />
      <path
        class="e"
        d="M1539.77,546.11a7.2,7.2,0,0,1-2.79-2.71,8.05,8.05,0,0,1,0-7.81,7.08,7.08,0,0,1,2.79-2.69,8.35,8.35,0,0,1,4-1,7.79,7.79,0,0,1,3.6.81,5.79,5.79,0,0,1,2.45,2.35l-2,1.32a4.66,4.66,0,0,0-1.74-1.6,5,5,0,0,0-2.3-.53,5.26,5.26,0,0,0-2.61.64,4.48,4.48,0,0,0-1.82,1.84,6,6,0,0,0,0,5.48,4.48,4.48,0,0,0,1.82,1.84,5.26,5.26,0,0,0,2.61.64,4.9,4.9,0,0,0,2.3-.53,4.63,4.63,0,0,0,1.74-1.59l2,1.28a5.89,5.89,0,0,1-2.45,2.37,7.67,7.67,0,0,1-3.6.82A8.24,8.24,0,0,1,1539.77,546.11Z"
      />
      <path
        class="e"
        d="m1553.4 528.72a1.68 1.68 0 0 1-0.51-1.23 1.71 1.71 0 0 1 0.51-1.25 1.76 1.76 0 0 1 1.31-0.52 1.81 1.81 0 0 1 1.3 0.49 1.63 1.63 0 0 1 0.52 1.22 1.78 1.78 0 0 1-1.82 1.79 1.83 1.83 0 0 1-1.31-0.5zm0 3.36h2.63v14.84h-2.69z"
      />
      <path
        class="e"
        d="M1571.27,533.45a5.85,5.85,0,0,1,1.63,4.51v9h-2.54v-2a4.31,4.31,0,0,1-1.92,1.58,7.42,7.42,0,0,1-3,.54,6.2,6.2,0,0,1-4-1.2,4.15,4.15,0,0,1-.07-6.31,7,7,0,0,1,4.54-1.19h4.26v-.54a3.45,3.45,0,0,0-1-2.66,4.28,4.28,0,0,0-3-.92,7.86,7.86,0,0,0-2.58.44,6.25,6.25,0,0,0-2.12,1.16l-1.12-2a7.9,7.9,0,0,1,2.74-1.41,11.34,11.34,0,0,1,3.39-.49A6.73,6.73,0,0,1,1571.27,533.45Zm-2.68,10.88a4,4,0,0,0,1.63-2v-2.07h-4.15c-2.28,0-3.41.76-3.41,2.3a2.07,2.07,0,0,0,.86,1.76,4,4,0,0,0,2.41.64A5.06,5.06,0,0,0,1568.59,544.33Z"
      />
      <path class="e" d="m1577.9 526.14h2.69v20.78h-2.69z" />
      <path
        class="e"
        d="M1595.06,546.46a8.83,8.83,0,0,1-3.23-1.78l1-2.19a8.35,8.35,0,0,0,2.87,1.64,10.48,10.48,0,0,0,3.59.63,6.38,6.38,0,0,0,3.64-.83,2.53,2.53,0,0,0,1.21-2.19,2.17,2.17,0,0,0-.66-1.64,4.52,4.52,0,0,0-1.62-1q-1-.33-2.73-.75a27.49,27.49,0,0,1-3.57-1.07,5.86,5.86,0,0,1-2.33-1.64,4.37,4.37,0,0,1-1-3,5,5,0,0,1,.82-2.82,5.66,5.66,0,0,1,2.51-2,10.06,10.06,0,0,1,4.17-.76,13.16,13.16,0,0,1,3.42.45,9.07,9.07,0,0,1,2.88,1.29l-.92,2.24a10.13,10.13,0,0,0-2.64-1.19,9.79,9.79,0,0,0-2.74-.41,6,6,0,0,0-3.57.87,2.67,2.67,0,0,0-1.19,2.24,2.14,2.14,0,0,0,.67,1.64,4.67,4.67,0,0,0,1.67,1,26.27,26.27,0,0,0,2.7.74,29.15,29.15,0,0,1,3.56,1.06,6.13,6.13,0,0,1,2.31,1.63,4.3,4.3,0,0,1,1,2.94,5,5,0,0,1-.84,2.81,5.69,5.69,0,0,1-2.54,2,10.65,10.65,0,0,1-4.21.74A13.68,13.68,0,0,1,1595.06,546.46Z"
      />
      <path
        class="e"
        d="M1619,546.05a4.18,4.18,0,0,1-1.45.77,6,6,0,0,1-1.8.26,4.77,4.77,0,0,1-3.47-1.2,4.59,4.59,0,0,1-1.23-3.44v-8.15h-2.52v-2.21H1611v-3.25h2.69v3.25H1618v2.21h-4.25v8a2.6,2.6,0,0,0,.6,1.84,2.25,2.25,0,0,0,1.72.65,3.28,3.28,0,0,0,2.1-.7Z"
      />
      <path
        class="e"
        d="m1624.1 546.11a7.12 7.12 0 0 1-2.76-2.71 8.12 8.12 0 0 1 0-7.81 7 7 0 0 1 2.76-2.69 8.15 8.15 0 0 1 4-1 8.06 8.06 0 0 1 4 1 7 7 0 0 1 2.75 2.69 8.2 8.2 0 0 1 0 7.81 7.16 7.16 0 0 1-2.75 2.71 8 8 0 0 1-4 1 8.05 8.05 0 0 1-4-1zm6.53-2a4.66 4.66 0 0 0 1.78-1.85 6.06 6.06 0 0 0 0-5.46 4.51 4.51 0 0 0-1.78-1.84 5.44 5.44 0 0 0-5.12 0 4.6 4.6 0 0 0-1.79 1.84 6 6 0 0 0 0 5.46 4.75 4.75 0 0 0 1.79 1.85 5.37 5.37 0 0 0 5.12 0z"
      />
      <path
        class="e"
        d="m1644.1 532.61a7.54 7.54 0 0 1 3.34-0.67v2.6a5 5 0 0 0-0.62 0 4.51 4.51 0 0 0-3.43 1.31 5.14 5.14 0 0 0-1.25 3.71v7.39h-2.68v-14.87h2.57v2.49a4.63 4.63 0 0 1 2.07-1.96z"
      />
      <path
        class="e"
        d="M1650.81,528.72a1.69,1.69,0,0,1-.52-1.23,1.71,1.71,0,0,1,.52-1.25,1.76,1.76,0,0,1,1.3-.52,1.82,1.82,0,0,1,1.31.49,1.62,1.62,0,0,1,.51,1.22,1.73,1.73,0,0,1-.51,1.27,1.76,1.76,0,0,1-1.31.52A1.82,1.82,0,0,1,1650.81,528.72Zm0,3.36h2.69v14.84h-2.69Z"
      />
      <path
        class="e"
        d="m1671.9 540.39h-12a4.65 4.65 0 0 0 1.72 3.15 5.58 5.58 0 0 0 3.65 1.19 5.48 5.48 0 0 0 4.29-1.79l1.48 1.74a6.56 6.56 0 0 1-2.5 1.79 9.52 9.52 0 0 1-7.52-0.35 7 7 0 0 1-2.82-2.7 8.27 8.27 0 0 1 0-7.81 6.93 6.93 0 0 1 2.66-2.71 7.58 7.58 0 0 1 3.82-1 7.44 7.44 0 0 1 3.79 1 6.88 6.88 0 0 1 2.61 2.71 8.25 8.25 0 0 1 0.93 4 6.67 6.67 0 0 1-0.11 0.78zm-10.52-5a4.79 4.79 0 0 0-1.52 3.08h9.52a4.8 4.8 0 0 0-1.53-3.07 4.61 4.61 0 0 0-3.23-1.18 4.67 4.67 0 0 0-3.24 1.16z"
      />
      <path
        class="e"
        d="M1676.47,546.59a7.67,7.67,0,0,1-2.63-1.24l1.12-2.13a8.08,8.08,0,0,0,2.38,1.12,9.85,9.85,0,0,0,2.83.42c2.35,0,3.53-.67,3.53-2a1.31,1.31,0,0,0-.48-1.07,3,3,0,0,0-1.19-.57c-.47-.12-1.15-.26-2-.41a20.62,20.62,0,0,1-2.92-.64,4.64,4.64,0,0,1-1.95-1.23,3.46,3.46,0,0,1-.81-2.44,3.79,3.79,0,0,1,1.68-3.23,7.47,7.47,0,0,1,4.51-1.22,12.48,12.48,0,0,1,3,.36,7.9,7.9,0,0,1,2.44,1l-1.15,2.13a7.66,7.66,0,0,0-4.28-1.18,5,5,0,0,0-2.61.56,1.71,1.71,0,0,0-.89,1.49,1.42,1.42,0,0,0,.5,1.14,3.06,3.06,0,0,0,1.25.62c.49.13,1.19.28,2.08.45a20.67,20.67,0,0,1,2.9.66,4.27,4.27,0,0,1,1.89,1.19,3.32,3.32,0,0,1,.79,2.35,3.7,3.7,0,0,1-1.73,3.2,8.06,8.06,0,0,1-4.69,1.19A12.33,12.33,0,0,1,1676.47,546.59Z"
      />
    </g>
    <rect class="d second" x="60" y="402" width="152" height="80" />
    <g class="a">
      <path
        class="e"
        d="M109,450.84a9.49,9.49,0,0,1-3.71-3.59,10.44,10.44,0,0,1,0-10.27,9.52,9.52,0,0,1,3.72-3.59,11,11,0,0,1,5.32-1.3,11.17,11.17,0,0,1,4.2.77,8.79,8.79,0,0,1,3.25,2.28L120,436.91a7.33,7.33,0,0,0-5.52-2.33,8,8,0,0,0-4,1,7.07,7.07,0,0,0-2.75,2.7,7.91,7.91,0,0,0,0,7.7,7.07,7.07,0,0,0,2.75,2.7,8.06,8.06,0,0,0,4,1A7.32,7.32,0,0,0,120,447.3l1.82,1.76a8.88,8.88,0,0,1-3.26,2.3,11.12,11.12,0,0,1-4.22.78A10.75,10.75,0,0,1,109,450.84Z"
      />
      <path
        class="e"
        d="m137.84 438.58a6.43 6.43 0 0 1 1.66 4.8v8.54h-2.68v-8.24a4.55 4.55 0 0 0-1-3.24 3.89 3.89 0 0 0-3-1.1 4.63 4.63 0 0 0-3.44 1.28 5 5 0 0 0-1.26 3.65v7.65h-2.69v-20.78h2.69v8a5.49 5.49 0 0 1 2.2-1.65 7.38 7.38 0 0 1 3-0.59 6.11 6.11 0 0 1 4.52 1.68z"
      />
      <path
        class="e"
        d="M154.61,438.45a5.85,5.85,0,0,1,1.64,4.51v9H153.7v-2a4.31,4.31,0,0,1-1.92,1.58,7.36,7.36,0,0,1-2.95.54,6.2,6.2,0,0,1-4-1.2,4.13,4.13,0,0,1-.07-6.31,7,7,0,0,1,4.53-1.19h4.26v-.54a3.45,3.45,0,0,0-1-2.66,4.28,4.28,0,0,0-3-.92,7.84,7.84,0,0,0-2.57.44,6.4,6.4,0,0,0-2.13,1.16l-1.12-2a7.9,7.9,0,0,1,2.74-1.41,11.44,11.44,0,0,1,3.39-.49A6.71,6.71,0,0,1,154.61,438.45Zm-2.67,10.88a4,4,0,0,0,1.62-1.95v-2.07h-4.14c-2.28,0-3.42.76-3.42,2.3a2.08,2.08,0,0,0,.87,1.76,4,4,0,0,0,2.41.64A5.08,5.08,0,0,0,151.94,449.33Z"
      />
      <path
        class="e"
        d="M169.58,451.05a4.23,4.23,0,0,1-1.46.77,6,6,0,0,1-1.79.26,4.75,4.75,0,0,1-3.47-1.2,4.56,4.56,0,0,1-1.24-3.44v-8.15H159.1v-2.21h2.52v-3.25h2.69v3.25h4.26v2.21h-4.26v8a2.6,2.6,0,0,0,.6,1.84,2.25,2.25,0,0,0,1.73.65,3.28,3.28,0,0,0,2.1-.7Z"
      />
    </g>
    <rect class="d third" x="227" y="402" width="220" height="80" />
    <g class="a">
      <path
        class="e"
        d="m280 447.1h-3.7v4.82h-2.72v-4.82h-10.89v-2l10-12.82h3l-9.6 12.37h7.53v-4.25h2.63v4.25h3.75z"
      />
      <path
        class="e"
        d="m285.58 451a8 8 0 0 1-2.84-3.46 14.41 14.41 0 0 1 0-10.75 8.05 8.05 0 0 1 2.84-3.46 7.37 7.37 0 0 1 4.13-1.19 7.3 7.3 0 0 1 4.1 1.19 8.07 8.07 0 0 1 2.85 3.46 14.54 14.54 0 0 1 0 10.75 8 8 0 0 1-2.85 3.46 7.3 7.3 0 0 1-4.1 1.19 7.37 7.37 0 0 1-4.13-1.19zm7.9-3.23a11.83 11.83 0 0 0 0-11.2 4.65 4.65 0 0 0-7.56 0 11.75 11.75 0 0 0 0 11.2 4.67 4.67 0 0 0 7.56 0z"
      />
      <path
        class="e"
        d="M317.43,447.1h-3.72v4.82H311V447.1H300.1v-2l10-12.82h3l-9.61,12.37h7.54v-4.25h2.63v4.25h3.72Z"
      />
      <path
        class="e"
        d="m341.88 434.14a6.85 6.85 0 0 1 0 10 9.07 9.07 0 0 1-6 1.82h-4.88v5.94h-2.8v-19.6h7.64a9.07 9.07 0 0 1 6.04 1.84zm-2 8.27a4.5 4.5 0 0 0 0-6.52 6.3 6.3 0 0 0-4.06-1.14h-4.82v8.79h4.76a6.3 6.3 0 0 0 4.1-1.13z"
      />
      <path
        class="e"
        d="M357.76,438.45A5.81,5.81,0,0,1,359.4,443v9h-2.55v-2a4.23,4.23,0,0,1-1.91,1.58,7.42,7.42,0,0,1-3,.54,6.2,6.2,0,0,1-4-1.2,4.13,4.13,0,0,1-.07-6.31,7,7,0,0,1,4.54-1.19h4.25v-.54a3.45,3.45,0,0,0-1-2.66,4.31,4.31,0,0,0-3-.92,7.86,7.86,0,0,0-2.58.44,6.4,6.4,0,0,0-2.13,1.16l-1.12-2a8,8,0,0,1,2.75-1.41,11.34,11.34,0,0,1,3.39-.49A6.68,6.68,0,0,1,357.76,438.45Zm-2.67,10.88a3.89,3.89,0,0,0,1.62-1.95v-2.07h-4.14c-2.28,0-3.42.76-3.42,2.3a2.08,2.08,0,0,0,.87,1.76,4,4,0,0,0,2.41.64A5.06,5.06,0,0,0,355.09,449.33Z"
      />
      <path
        class="e"
        d="M378.67,437.08V449.9c0,2.6-.65,4.51-1.94,5.76s-3.21,1.86-5.76,1.86a13.6,13.6,0,0,1-4-.58,7.92,7.92,0,0,1-3.08-1.64l1.29-2.07a7.6,7.6,0,0,0,2.56,1.42,9.91,9.91,0,0,0,3.15.52,5.28,5.28,0,0,0,3.85-1.24,5.07,5.07,0,0,0,1.25-3.75V449a6.07,6.07,0,0,1-2.34,1.73,7.83,7.83,0,0,1-3,.57,8.07,8.07,0,0,1-3.85-.91,6.77,6.77,0,0,1-2.7-2.55,7.61,7.61,0,0,1,0-7.47,6.68,6.68,0,0,1,2.7-2.54,8.3,8.3,0,0,1,3.85-.89,7.86,7.86,0,0,1,3.12.61,5.86,5.86,0,0,1,2.37,1.82v-2.29Zm-5.11,11.25a4.57,4.57,0,0,0,1.82-1.72,4.88,4.88,0,0,0,.65-2.5,4.55,4.55,0,0,0-1.42-3.49,5.77,5.77,0,0,0-7.37,0,4.56,4.56,0,0,0-1.43,3.49,4.79,4.79,0,0,0,.66,2.5,4.57,4.57,0,0,0,1.82,1.72,5.6,5.6,0,0,0,2.65.62A5.54,5.54,0,0,0,373.56,448.33Z"
      />
      <path
        class="e"
        d="m397.15 445.39h-12a4.61 4.61 0 0 0 1.72 3.15 5.58 5.58 0 0 0 3.65 1.19 5.51 5.51 0 0 0 4.29-1.79l1.48 1.74a6.52 6.52 0 0 1-2.51 1.79 8.68 8.68 0 0 1-3.34 0.61 8.79 8.79 0 0 1-4.17-1 6.93 6.93 0 0 1-2.82-2.7 8.31 8.31 0 0 1 0-7.81 7 7 0 0 1 2.55-2.67 7.64 7.64 0 0 1 3.83-1 7.47 7.47 0 0 1 3.79 1 6.79 6.79 0 0 1 2.6 2.71 8.15 8.15 0 0 1 0.94 4c0.04 0.18 0.02 0.45-0.01 0.78zm-10.52-5a4.7 4.7 0 0 0-1.52 3.08h9.52a4.8 4.8 0 0 0-1.53-3.07 5 5 0 0 0-6.47 0z"
      />
      <path
        class="e"
        d="M401.68,451.59a7.58,7.58,0,0,1-2.63-1.24l1.12-2.13a8,8,0,0,0,2.38,1.12,9.8,9.8,0,0,0,2.83.42c2.35,0,3.53-.67,3.53-2a1.31,1.31,0,0,0-.48-1.07,3,3,0,0,0-1.19-.57c-.48-.12-1.15-.26-2-.41a21.13,21.13,0,0,1-2.93-.64,4.67,4.67,0,0,1-1.94-1.23,3.46,3.46,0,0,1-.81-2.44,3.79,3.79,0,0,1,1.68-3.23,7.45,7.45,0,0,1,4.5-1.22,12.57,12.57,0,0,1,3,.36,8,8,0,0,1,2.44,1L410,440.41a7.66,7.66,0,0,0-4.28-1.18,5,5,0,0,0-2.61.56,1.69,1.69,0,0,0-.89,1.49,1.39,1.39,0,0,0,.5,1.14,3.06,3.06,0,0,0,1.25.62c.49.13,1.19.28,2.08.45a20.67,20.67,0,0,1,2.9.66,4.27,4.27,0,0,1,1.89,1.19,3.32,3.32,0,0,1,.78,2.35,3.69,3.69,0,0,1-1.72,3.2,8.08,8.08,0,0,1-4.69,1.19A12.33,12.33,0,0,1,401.68,451.59Z"
      />
    </g>
    <rect class="d fourth" x="462" y="402" width="329" height="80" />
    <g class="a">
      <path class="e" d="m504.58 432.86h2.8v19.6h-2.8z" />
      <path
        class="e"
        d="m525.29 439.12a6.43 6.43 0 0 1 1.66 4.8v8.54h-2.68v-8.23a4.56 4.56 0 0 0-1-3.25 3.89 3.89 0 0 0-3-1.09 4.62 4.62 0 0 0-3.44 1.27 5 5 0 0 0-1.26 3.66v7.64h-2.69v-14.84h2.58v2.24a5.24 5.24 0 0 1 2.19-1.76 7.73 7.73 0 0 1 3.15-0.62 6.15 6.15 0 0 1 4.49 1.64z"
      />
      <path
        class="e"
        d="M540.28,451.59a3.87,3.87,0,0,1-1.45.77,6,6,0,0,1-1.8.27,4.73,4.73,0,0,1-3.47-1.21,4.59,4.59,0,0,1-1.23-3.44v-8.15h-2.52v-2.21h2.52v-3.25H535v3.25h4.25v2.21H535v8a2.62,2.62,0,0,0,.6,1.85,2.24,2.24,0,0,0,1.72.64,3.23,3.23,0,0,0,2.1-.7Z"
      />
      <path
        class="e"
        d="m556.38 445.94h-12a4.69 4.69 0 0 0 1.72 3.15 5.65 5.65 0 0 0 3.66 1.19 5.51 5.51 0 0 0 4.28-1.79l1.49 1.73a6.6 6.6 0 0 1-2.53 1.78 9.41 9.41 0 0 1-7.52-0.35 6.84 6.84 0 0 1-2.81-2.7 7.77 7.77 0 0 1-1-3.92 7.9 7.9 0 0 1 1-3.89 6.84 6.84 0 0 1 2.66-2.7 7.51 7.51 0 0 1 3.82-1 7.38 7.38 0 0 1 3.8 1 6.76 6.76 0 0 1 2.6 2.7 8.29 8.29 0 0 1 0.94 4 7.31 7.31 0 0 1-0.11 0.8zm-10.51-5a4.77 4.77 0 0 0-1.53 3.08h9.52a4.79 4.79 0 0 0-1.52-3.06 5 5 0 0 0-6.47 0z"
      />
      <path
        class="e"
        d="m564.81 438.15a7.6 7.6 0 0 1 3.33-0.67v2.6h-0.61a4.51 4.51 0 0 0-3.43 1.3 5.15 5.15 0 0 0-1.25 3.71v7.39h-2.69v-14.86h2.58v2.49a4.58 4.58 0 0 1 2.07-1.96z"
      />
      <path
        class="e"
        d="M581.54,439a5.85,5.85,0,0,1,1.64,4.51v9h-2.55v-2a4.24,4.24,0,0,1-1.92,1.58,7.19,7.19,0,0,1-3,.55,6.15,6.15,0,0,1-4-1.21,3.85,3.85,0,0,1-1.5-3.16,3.9,3.9,0,0,1,1.43-3.15,7,7,0,0,1,4.53-1.19h4.26v-.53a3.42,3.42,0,0,0-1-2.66,4.23,4.23,0,0,0-3-.93,7.84,7.84,0,0,0-2.57.44,6.78,6.78,0,0,0-2.13,1.16l-1.12-2a8.26,8.26,0,0,1,2.74-1.41,11.44,11.44,0,0,1,3.39-.49A6.74,6.74,0,0,1,581.54,439Zm-2.67,10.88a4,4,0,0,0,1.62-1.95v-2.07h-4.14c-2.28,0-3.42.77-3.42,2.3a2.07,2.07,0,0,0,.87,1.76,4,4,0,0,0,2.41.65A5,5,0,0,0,578.87,449.87Z"
      />
      <path
        class="e"
        d="M590.67,451.65a7.17,7.17,0,0,1-2.79-2.7,8.07,8.07,0,0,1,0-7.82,7.06,7.06,0,0,1,2.79-2.68,8.24,8.24,0,0,1,4-1,7.76,7.76,0,0,1,3.6.81,5.79,5.79,0,0,1,2.45,2.35l-2,1.32a4.55,4.55,0,0,0-1.74-1.59,4.87,4.87,0,0,0-2.29-.54,5.27,5.27,0,0,0-2.62.65,4.58,4.58,0,0,0-1.82,1.83,5.52,5.52,0,0,0-.66,2.73,5.58,5.58,0,0,0,.66,2.76,4.58,4.58,0,0,0,1.82,1.83,5.27,5.27,0,0,0,2.62.65,5,5,0,0,0,2.29-.53,4.66,4.66,0,0,0,1.74-1.6l2,1.29a5.87,5.87,0,0,1-2.45,2.36,7.65,7.65,0,0,1-3.6.83A8.25,8.25,0,0,1,590.67,451.65Z"
      />
      <path
        class="e"
        d="M612.77,451.59a3.79,3.79,0,0,1-1.45.77,6,6,0,0,1-1.8.27,4.73,4.73,0,0,1-3.47-1.21,4.59,4.59,0,0,1-1.23-3.44v-8.15H602.3v-2.21h2.52v-3.25h2.69v3.25h4.25v2.21h-4.25v8a2.62,2.62,0,0,0,.6,1.85,2.24,2.24,0,0,0,1.72.64,3.23,3.23,0,0,0,2.1-.7Z"
      />
      <path
        class="e"
        d="M616.06,434.26a1.69,1.69,0,0,1-.52-1.23,1.73,1.73,0,0,1,.52-1.25,1.8,1.8,0,0,1,1.3-.52,1.86,1.86,0,0,1,1.31.49,1.64,1.64,0,0,1,.51,1.22,1.76,1.76,0,0,1-.51,1.28,1.94,1.94,0,0,1-2.61,0Zm0,3.36h2.69v14.84H616Z"
      />
      <path
        class="e"
        d="m637 437.62-6.49 14.84h-2.75l-6.49-14.84h2.73l5.09 11.9 5.21-11.9z"
      />
      <path
        class="e"
        d="M652.42,445.94h-12a4.69,4.69,0,0,0,1.72,3.15,5.65,5.65,0,0,0,3.66,1.19,5.51,5.51,0,0,0,4.28-1.79l1.48,1.73A6.48,6.48,0,0,1,649,452a8.77,8.77,0,0,1-3.35.62,8.67,8.67,0,0,1-4.17-1,6.84,6.84,0,0,1-2.81-2.7,7.67,7.67,0,0,1-1-3.92,7.8,7.8,0,0,1,1-3.89,6.84,6.84,0,0,1,2.66-2.7,7.51,7.51,0,0,1,3.82-1,7.35,7.35,0,0,1,3.79,1,6.79,6.79,0,0,1,2.61,2.7,8.29,8.29,0,0,1,.94,4A7.31,7.31,0,0,1,652.42,445.94Zm-10.51-5a4.77,4.77,0,0,0-1.53,3.08h9.52a4.75,4.75,0,0,0-1.53-3.06,5,5,0,0,0-6.46,0Z"
      />
      <path
        class="e"
        d="M678.35,450v2.43H664.12v-19.6H678v2.44h-11v6h9.83v2.38h-9.83V450Z"
      />
      <path
        class="e"
        d="M705.49,439.1a6.49,6.49,0,0,1,1.64,4.82v8.54h-2.69v-8.23a4.7,4.7,0,0,0-1-3.25,3.63,3.63,0,0,0-2.84-1.09,4.24,4.24,0,0,0-3.25,1.27,5.14,5.14,0,0,0-1.2,3.66v7.64h-2.69v-8.23a4.65,4.65,0,0,0-1-3.25,3.63,3.63,0,0,0-2.84-1.09,4.27,4.27,0,0,0-3.25,1.27,5.14,5.14,0,0,0-1.2,3.66v7.64h-2.69V437.62h2.58v2.21a5.22,5.22,0,0,1,2.13-1.75,7.09,7.09,0,0,1,3-.6,6.51,6.51,0,0,1,3.08.7,4.85,4.85,0,0,1,2.07,2,5.69,5.69,0,0,1,2.34-2,7.59,7.59,0,0,1,3.38-.73A6,6,0,0,1,705.49,439.1Z"
      />
      <path
        class="e"
        d="M722.21,439a5.85,5.85,0,0,1,1.64,4.51v9H721.3v-2a4.24,4.24,0,0,1-1.92,1.58,7.19,7.19,0,0,1-3,.55,6.15,6.15,0,0,1-4-1.21,3.85,3.85,0,0,1-1.5-3.16,3.9,3.9,0,0,1,1.43-3.15,7,7,0,0,1,4.53-1.19h4.26v-.53a3.42,3.42,0,0,0-1-2.66,4.23,4.23,0,0,0-3-.93,7.84,7.84,0,0,0-2.57.44,6.78,6.78,0,0,0-2.13,1.16l-1.12-2A8.26,8.26,0,0,1,714.1,438a11.44,11.44,0,0,1,3.39-.49A6.76,6.76,0,0,1,722.21,439Zm-2.67,10.88a4,4,0,0,0,1.62-1.95v-2.07H717c-2.28,0-3.42.77-3.42,2.3a2.07,2.07,0,0,0,.87,1.76,4,4,0,0,0,2.41.65A5,5,0,0,0,719.54,449.87Z"
      />
      <path
        class="e"
        d="M728.87,434.26a1.68,1.68,0,0,1-.51-1.23,1.72,1.72,0,0,1,.51-1.25,1.8,1.8,0,0,1,1.31-.52,1.86,1.86,0,0,1,1.3.49A1.65,1.65,0,0,1,732,433a1.77,1.77,0,0,1-.52,1.28,1.94,1.94,0,0,1-2.61,0Zm0,3.36h2.69v14.84h-2.69Z"
      />
      <path class="e" d="m736.64 431.68h2.69v20.78h-2.69z" />
      <path
        class="e"
        d="M745.21,452.14a7.58,7.58,0,0,1-2.63-1.25l1.12-2.13a8,8,0,0,0,2.38,1.12,9.42,9.42,0,0,0,2.83.42c2.35,0,3.53-.67,3.53-2a1.32,1.32,0,0,0-.48-1.07,3.07,3.07,0,0,0-1.19-.57c-.48-.12-1.15-.26-2-.4a20.63,20.63,0,0,1-2.93-.65,4.48,4.48,0,0,1-1.94-1.23,3.39,3.39,0,0,1-.81-2.44,3.79,3.79,0,0,1,1.67-3.23,7.56,7.56,0,0,1,4.51-1.22,12.57,12.57,0,0,1,3,.36,8.27,8.27,0,0,1,2.44,1L753.5,441a7.77,7.77,0,0,0-4.29-1.17,4.9,4.9,0,0,0-2.6.56,1.69,1.69,0,0,0-.9,1.48,1.41,1.41,0,0,0,.51,1.15,3.2,3.2,0,0,0,1.24.61c.5.14,1.19.29,2.09.45a24.43,24.43,0,0,1,2.9.66,4.27,4.27,0,0,1,1.89,1.19,3.32,3.32,0,0,1,.78,2.35,3.7,3.7,0,0,1-1.72,3.21,8.16,8.16,0,0,1-4.69,1.19A12.7,12.7,0,0,1,745.21,452.14Z"
      />
    </g>
    <rect class="d fifth" x="806" y="402" width="307" height="80" />
    <g class="a">
      <path
        class="e"
        d="m858.33 450.61a9.64 9.64 0 0 1-3.73-3.6 10.34 10.34 0 0 1 0-10.25 9.64 9.64 0 0 1 3.73-3.6 11.6 11.6 0 0 1 10.67 0 9.61 9.61 0 0 1 3.73 3.59 10.53 10.53 0 0 1 0 10.28 9.59 9.59 0 0 1-3.73 3.58 11.6 11.6 0 0 1-10.67 0zm9.23-2.17a7.27 7.27 0 0 0 2.72-2.7 8 8 0 0 0 0-7.7 7.19 7.19 0 0 0-2.72-2.7 7.64 7.64 0 0 0-3.88-1 7.77 7.77 0 0 0-3.9 1 7.13 7.13 0 0 0-2.78 2.66 8 8 0 0 0 0 7.7 7.2 7.2 0 0 0 2.75 2.7 7.87 7.87 0 0 0 3.9 1 7.74 7.74 0 0 0 3.91-0.96z"
      />
      <path
        class="e"
        d="M881,435.56v1.29h4.31v2.21H881v12.63h-2.69V439.06h-2.52v-2.21h2.52v-1.32a4.73,4.73,0,0,1,1.29-3.5,4.89,4.89,0,0,1,3.61-1.29,6.16,6.16,0,0,1,1.69.23,4.07,4.07,0,0,1,1.34.64l-.81,2a3.39,3.39,0,0,0-2.07-.69C881.79,433,881,433.82,881,435.56Z"
      />
      <path
        class="e"
        d="m890.87 435.56v1.29h4.31v2.21h-4.25v12.63h-2.69v-12.63h-2.52v-2.21h2.52v-1.32a4.73 4.73 0 0 1 1.29-3.5 4.88 4.88 0 0 1 3.61-1.29 6 6 0 0 1 1.68 0.23 4.07 4.07 0 0 1 1.34 0.64l-0.81 2a3.39 3.39 0 0 0-2.07-0.69c-1.61 0.08-2.41 0.9-2.41 2.64zm6.86-4.65h2.69v20.78h-2.69z"
      />
      <path
        class="e"
        d="M905.58,433.49a1.68,1.68,0,0,1-.51-1.23,1.72,1.72,0,0,1,.51-1.25,1.76,1.76,0,0,1,1.31-.52,1.81,1.81,0,0,1,1.3.49,1.63,1.63,0,0,1,.52,1.22,1.78,1.78,0,0,1-1.82,1.79A1.83,1.83,0,0,1,905.58,433.49Zm0,3.36h2.69v14.84h-2.69Z"
      />
      <path
        class="e"
        d="m925.77 438.35a6.44 6.44 0 0 1 1.67 4.8v8.54h-2.69v-8.23a4.56 4.56 0 0 0-1-3.25 3.88 3.88 0 0 0-3-1.09 4.63 4.63 0 0 0-3.45 1.27 5 5 0 0 0-1.3 3.61v7.65h-2.69v-14.8h2.58v2.24a5.16 5.16 0 0 1 2.2-1.77 7.66 7.66 0 0 1 3.15-0.61 6.1 6.1 0 0 1 4.53 1.64z"
      />
      <path
        class="e"
        d="m945.86 445.16h-12a4.65 4.65 0 0 0 1.72 3.15 5.59 5.59 0 0 0 3.66 1.19 5.48 5.48 0 0 0 4.28-1.79l1.49 1.74a6.6 6.6 0 0 1-2.51 1.79 8.73 8.73 0 0 1-3.35 0.62 8.85 8.85 0 0 1-4.17-1 7 7 0 0 1-2.81-2.7 7.7 7.7 0 0 1-1-3.92 7.88 7.88 0 0 1 1-3.89 6.93 6.93 0 0 1 2.66-2.71 7.61 7.61 0 0 1 3.82-1 7.48 7.48 0 0 1 3.8 1 6.85 6.85 0 0 1 2.6 2.71 8.15 8.15 0 0 1 0.94 4 7 7 0 0 1-0.13 0.81zm-10.51-5a4.75 4.75 0 0 0-1.53 3.08h9.52a4.83 4.83 0 0 0-1.52-3.07 5 5 0 0 0-6.47 0z"
      />
      <path
        class="e"
        d="M961,450.61a9.67,9.67,0,0,1-3.71-3.58,10.53,10.53,0,0,1,0-10.28,9.61,9.61,0,0,1,3.73-3.59,11,11,0,0,1,5.32-1.3,11.17,11.17,0,0,1,4.2.77,8.79,8.79,0,0,1,3.25,2.28L972,436.68a7.32,7.32,0,0,0-5.52-2.32,7.93,7.93,0,0,0-4,1,7.16,7.16,0,0,0-2.76,2.7,8,8,0,0,0,0,7.7,7.23,7.23,0,0,0,2.76,2.7,8,8,0,0,0,4,1,7.37,7.37,0,0,0,5.52-2.35l1.82,1.76a8.72,8.72,0,0,1-3.27,2.3,11,11,0,0,1-4.21.78A10.82,10.82,0,0,1,961,450.61Z"
      />
      <path
        class="e"
        d="m979.18 450.88a7.26 7.26 0 0 1-2.76-2.71 8.2 8.2 0 0 1 0-7.81 7.14 7.14 0 0 1 2.76-2.69 8.69 8.69 0 0 1 7.94 0 7 7 0 0 1 2.74 2.69 8.12 8.12 0 0 1 0 7.81 7.13 7.13 0 0 1-2.74 2.71 8.53 8.53 0 0 1-7.94 0zm6.54-2a4.66 4.66 0 0 0 1.78-1.88 6.14 6.14 0 0 0 0-5.46 4.51 4.51 0 0 0-1.78-1.84 5.44 5.44 0 0 0-5.12 0 4.62 4.62 0 0 0-1.8 1.84 6.06 6.06 0 0 0 0 5.46 4.78 4.78 0 0 0 1.8 1.85 5.37 5.37 0 0 0 5.12 0z"
      />
      <path
        class="e"
        d="m1007 438.35a6.44 6.44 0 0 1 1.67 4.8v8.54h-2.67v-8.23a4.56 4.56 0 0 0-1-3.25 3.89 3.89 0 0 0-3-1.09 4.62 4.62 0 0 0-3.44 1.27 5 5 0 0 0-1.26 3.65v7.65h-2.69v-14.84h2.58v2.24a5.16 5.16 0 0 1 2.2-1.77 7.66 7.66 0 0 1 3.15-0.61 6.1 6.1 0 0 1 4.46 1.64z"
      />
      <path
        class="e"
        d="M1022,450.82a4.09,4.09,0,0,1-1.45.77,6,6,0,0,1-1.79.27,4.8,4.8,0,0,1-3.48-1.21,4.59,4.59,0,0,1-1.23-3.44v-8.15h-2.52v-2.21H1014V433.6h2.69v3.25H1021v2.21h-4.26v8a2.6,2.6,0,0,0,.6,1.84,2.25,2.25,0,0,0,1.72.65,3.24,3.24,0,0,0,2.1-.7Z"
      />
      <path
        class="e"
        d="m1038.1 445.16h-12a4.66 4.66 0 0 0 1.73 3.15 5.56 5.56 0 0 0 3.65 1.19 5.48 5.48 0 0 0 4.28-1.79l1.49 1.74a6.52 6.52 0 0 1-2.51 1.79 8.72 8.72 0 0 1-3.34 0.62 8.86 8.86 0 0 1-4.18-1 7 7 0 0 1-2.81-2.7 8.31 8.31 0 0 1 0-7.81 7 7 0 0 1 2.66-2.71 7.61 7.61 0 0 1 3.82-1 7.48 7.48 0 0 1 3.8 1 6.79 6.79 0 0 1 2.6 2.71 8.15 8.15 0 0 1 0.94 4 7 7 0 0 1-0.13 0.81zm-10.51-5a4.75 4.75 0 0 0-1.53 3.08h9.52a4.79 4.79 0 0 0-1.52-3.07 5 5 0 0 0-6.47 0z"
      />
      <path
        class="e"
        d="m1054.3 438.35a6.44 6.44 0 0 1 1.67 4.8v8.54h-2.69v-8.23a4.56 4.56 0 0 0-1-3.25 3.88 3.88 0 0 0-3-1.09 4.63 4.63 0 0 0-3.45 1.27 5 5 0 0 0-1.26 3.65v7.65h-2.69v-14.84h2.58v2.24a5.16 5.16 0 0 1 2.2-1.77 7.66 7.66 0 0 1 3.15-0.61 6.1 6.1 0 0 1 4.49 1.64z"
      />
      <path
        class="e"
        d="M1069.29,450.82a4.23,4.23,0,0,1-1.46.77,6,6,0,0,1-1.79.27,4.76,4.76,0,0,1-3.47-1.21,4.56,4.56,0,0,1-1.24-3.44v-8.15h-2.52v-2.21h2.52V433.6H1064v3.25h4.26v2.21H1064v8a2.6,2.6,0,0,0,.6,1.84,2.27,2.27,0,0,0,1.73.65,3.28,3.28,0,0,0,2.1-.7Z"
      />
    </g>
    <rect class="d" x="1128" y="402" width="315" height="80" />
    <g class="a">
      <path
        class="e"
        d="m1185.5 433.91a6.85 6.85 0 0 1 0 10 9.08 9.08 0 0 1-6 1.82h-4.85v5.94h-2.8v-19.6h7.65a9 9 0 0 1 6 1.84zm-2 8.27a4.5 4.5 0 0 0 0-6.52 6.33 6.33 0 0 0-4.07-1.14h-4.76v8.79h4.76a6.33 6.33 0 0 0 4.06-1.13z"
      />
      <path
        class="e"
        d="M1201.36,438.22a5.85,5.85,0,0,1,1.64,4.51v9h-2.55v-2a4.24,4.24,0,0,1-1.92,1.58,7.36,7.36,0,0,1-3,.55,6.2,6.2,0,0,1-4-1.21,4.13,4.13,0,0,1-.07-6.31,7,7,0,0,1,4.53-1.19h4.26v-.53a3.42,3.42,0,0,0-1-2.66,4.23,4.23,0,0,0-3-.93,8.1,8.1,0,0,0-2.57.43,6.6,6.6,0,0,0-2.13,1.17l-1.12-2a8,8,0,0,1,2.74-1.41,11.44,11.44,0,0,1,3.39-.49A6.69,6.69,0,0,1,1201.36,438.22Zm-2.67,10.88a4,4,0,0,0,1.62-2v-2.07h-4.14c-2.28,0-3.42.76-3.42,2.3a2.08,2.08,0,0,0,.87,1.76,4,4,0,0,0,2.41.64A5.08,5.08,0,0,0,1198.69,449.1Z"
      />
      <path
        class="e"
        d="M1220.83,436.85l-7.19,16.32a7.37,7.37,0,0,1-2.23,3.19,5.05,5.05,0,0,1-3.06.93,6.59,6.59,0,0,1-2.12-.35,4.42,4.42,0,0,1-1.69-1l1.15-2a3.72,3.72,0,0,0,2.66,1.09,2.51,2.51,0,0,0,1.63-.52,4.29,4.29,0,0,0,1.19-1.77l.48-1-6.55-14.81h2.8l5.18,11.87,5.12-11.87Z"
      />
      <path
        class="e"
        d="m1246.4 438.33a6.51 6.51 0 0 1 1.63 4.82v8.54h-2.68v-8.23a4.7 4.7 0 0 0-1-3.25 3.63 3.63 0 0 0-2.84-1.09 4.26 4.26 0 0 0-3.25 1.27 5.13 5.13 0 0 0-1.22 3.61v7.65h-2.69v-8.23a4.7 4.7 0 0 0-1-3.25 3.66 3.66 0 0 0-2.85-1.09 4.23 4.23 0 0 0-3.24 1.27 5.09 5.09 0 0 0-1.22 3.65v7.65h-2.69v-14.8h2.58v2.21a5.14 5.14 0 0 1 2.13-1.75 7.13 7.13 0 0 1 3-0.6 6.51 6.51 0 0 1 3.08 0.7 4.82 4.82 0 0 1 2.08 2 5.59 5.59 0 0 1 2.33-2 7.59 7.59 0 0 1 3.38-0.73 6 6 0 0 1 4.47 1.65z"
      />
      <path
        class="e"
        d="m1266.4 445.16h-12a4.65 4.65 0 0 0 1.72 3.15 5.59 5.59 0 0 0 3.66 1.19 5.48 5.48 0 0 0 4.28-1.79l1.48 1.74a6.48 6.48 0 0 1-2.5 1.79 9.41 9.41 0 0 1-7.52-0.35 7 7 0 0 1-2.81-2.7 8.23 8.23 0 0 1 0-7.81 6.93 6.93 0 0 1 2.66-2.71 7.61 7.61 0 0 1 3.82-1 7.44 7.44 0 0 1 3.79 1 6.88 6.88 0 0 1 2.61 2.71 8.26 8.26 0 0 1 0.94 4 7 7 0 0 1-0.13 0.78zm-10.51-5a4.75 4.75 0 0 0-1.53 3.08h9.52a4.8 4.8 0 0 0-1.53-3.07 5 5 0 0 0-6.46 0z"
      />
      <path
        class="e"
        d="m1282.6 438.35a6.43 6.43 0 0 1 1.66 4.8v8.54h-2.68v-8.23a4.56 4.56 0 0 0-1-3.25 3.89 3.89 0 0 0-3-1.09 4.62 4.62 0 0 0-3.44 1.27 5 5 0 0 0-1.26 3.65v7.65h-2.69v-14.84h2.58v2.24a5.19 5.19 0 0 1 2.19-1.77 7.73 7.73 0 0 1 3.15-0.61 6.11 6.11 0 0 1 4.49 1.64z"
      />
      <path
        class="e"
        d="M1297.58,450.82a4.18,4.18,0,0,1-1.45.77,6,6,0,0,1-1.8.27,4.77,4.77,0,0,1-3.47-1.21,4.59,4.59,0,0,1-1.23-3.44v-8.15h-2.52v-2.21h2.52V433.6h2.69v3.25h4.25v2.21h-4.25v8a2.6,2.6,0,0,0,.6,1.84,2.23,2.23,0,0,0,1.72.65,3.28,3.28,0,0,0,2.1-.7Z"
      />
      <path
        class="e"
        d="m1322.4 433.91a6.85 6.85 0 0 1 0 10 9.1 9.1 0 0 1-6 1.82h-4.85v5.94h-2.8v-19.6h7.65a9 9 0 0 1 6 1.84zm-2 8.27a4.5 4.5 0 0 0 0-6.52 6.28 6.28 0 0 0-4.06-1.14h-4.76v8.79h4.76a6.28 6.28 0 0 0 4.04-1.13z"
      />
      <path
        class="e"
        d="m1330.6 450.88a7.26 7.26 0 0 1-2.76-2.71 8.2 8.2 0 0 1 0-7.81 7.14 7.14 0 0 1 2.76-2.69 8.69 8.69 0 0 1 7.94 0 7.08 7.08 0 0 1 2.74 2.69 8.12 8.12 0 0 1 0 7.81 7.2 7.2 0 0 1-2.74 2.71 8.53 8.53 0 0 1-7.94 0zm6.54-2a4.66 4.66 0 0 0 1.78-1.85 6.14 6.14 0 0 0 0-5.46 4.51 4.51 0 0 0-1.78-1.84 5.09 5.09 0 0 0-2.56-0.64 5 5 0 0 0-2.56 0.64 4.62 4.62 0 0 0-1.8 1.84 6 6 0 0 0 0 5.46 4.78 4.78 0 0 0 1.8 1.85 5 5 0 0 0 2.56 0.65 5.1 5.1 0 0 0 2.56-0.68z"
      />
      <path
        class="e"
        d="m1350.7 437.38a7.46 7.46 0 0 1 3.33-0.67v2.6a4.88 4.88 0 0 0-0.62 0 4.5 4.5 0 0 0-3.42 1.31 5.09 5.09 0 0 0-1.25 3.7v7.4h-2.71v-14.87h2.58v2.49a4.53 4.53 0 0 1 2.09-1.96z"
      />
      <path
        class="e"
        d="M1366.26,450.82a4.09,4.09,0,0,1-1.45.77,6,6,0,0,1-1.79.27,4.8,4.8,0,0,1-3.48-1.21,4.59,4.59,0,0,1-1.23-3.44v-8.15h-2.52v-2.21h2.52V433.6H1361v3.25h4.26v2.21H1361v8a2.6,2.6,0,0,0,.6,1.84,2.25,2.25,0,0,0,1.72.65,3.24,3.24,0,0,0,2.1-.7Z"
      />
      <path
        class="e"
        d="M1379.63,438.22a5.81,5.81,0,0,1,1.64,4.51v9h-2.55v-2a4.16,4.16,0,0,1-1.91,1.58,7.44,7.44,0,0,1-3,.55,6.2,6.2,0,0,1-4-1.21,4.15,4.15,0,0,1-.07-6.31,7,7,0,0,1,4.54-1.19h4.25v-.53a3.42,3.42,0,0,0-1-2.66,4.26,4.26,0,0,0-3-.93,8.12,8.12,0,0,0-2.58.43,6.6,6.6,0,0,0-2.13,1.17l-1.11-2a7.81,7.81,0,0,1,2.74-1.41,11.34,11.34,0,0,1,3.39-.49A6.68,6.68,0,0,1,1379.63,438.22ZM1377,449.1a3.89,3.89,0,0,0,1.62-2v-2.07h-4.14c-2.28,0-3.42.76-3.42,2.3a2.08,2.08,0,0,0,.87,1.76,4,4,0,0,0,2.41.64A5.06,5.06,0,0,0,1377,449.1Z"
      />
      <path class="e" d="m1386.3 430.91h2.68v20.78h-2.68z" />
      <path
        class="e"
        d="M1394.82,451.37a7.86,7.86,0,0,1-2.63-1.25l1.12-2.13a8,8,0,0,0,2.38,1.12,9.8,9.8,0,0,0,2.83.42c2.35,0,3.53-.67,3.53-2a1.31,1.31,0,0,0-.48-1.07,3,3,0,0,0-1.19-.57c-.47-.12-1.15-.26-2-.41a19.31,19.31,0,0,1-2.92-.64,4.64,4.64,0,0,1-2-1.23,3.44,3.44,0,0,1-.81-2.44,3.79,3.79,0,0,1,1.68-3.23,7.47,7.47,0,0,1,4.51-1.22,12.48,12.48,0,0,1,3,.36,8,8,0,0,1,2.44,1l-1.15,2.13a7.66,7.66,0,0,0-4.28-1.18,5,5,0,0,0-2.61.56,1.71,1.71,0,0,0-.89,1.49,1.46,1.46,0,0,0,.5,1.15,3.32,3.32,0,0,0,1.25.61c.49.13,1.19.28,2.08.45a20.67,20.67,0,0,1,2.9.66,4.27,4.27,0,0,1,1.89,1.19,3.32,3.32,0,0,1,.79,2.35,3.7,3.7,0,0,1-1.73,3.2,8.06,8.06,0,0,1-4.69,1.2A12.7,12.7,0,0,1,1394.82,451.37Z"
      />
    </g>
    <rect class="d second" x="1458" y="402" width="289" height="80" />
    <g class="a">
      <path
        class="e"
        d="m1522.9 432.09-8.6 19.6h-2.77l-8.62-19.6h3l7.06 16.1 7.11-16.1z"
      />
      <path
        class="e"
        d="M1525,433.49a1.65,1.65,0,0,1-.52-1.23A1.69,1.69,0,0,1,1525,431a1.74,1.74,0,0,1,1.3-.52,1.8,1.8,0,0,1,1.3.49,1.6,1.6,0,0,1,.52,1.22,1.7,1.7,0,0,1-.52,1.27,1.74,1.74,0,0,1-1.3.52A1.8,1.8,0,0,1,1525,433.49Zm0,3.36h2.68v14.84H1525Z"
      />
      <path
        class="e"
        d="m1537.4 437.38a7.46 7.46 0 0 1 3.33-0.67v2.6a5 5 0 0 0-0.62 0 4.53 4.53 0 0 0-3.43 1.31 5.13 5.13 0 0 0-1.24 3.7v7.4h-2.69v-14.87h2.58v2.49a4.53 4.53 0 0 1 2.07-1.96z"
      />
      <path
        class="e"
        d="M1553,450.82a4.18,4.18,0,0,1-1.45.77,6,6,0,0,1-1.8.27,4.77,4.77,0,0,1-3.47-1.21,4.59,4.59,0,0,1-1.23-3.44v-8.15h-2.52v-2.21h2.52V433.6h2.69v3.25H1552v2.21h-4.25v8a2.6,2.6,0,0,0,.6,1.84,2.25,2.25,0,0,0,1.72.65,3.28,3.28,0,0,0,2.1-.7Z"
      />
      <path
        class="e"
        d="M1570.12,436.85v14.84h-2.55v-2.24a5.39,5.39,0,0,1-2.14,1.77,6.69,6.69,0,0,1-2.9.64,6.47,6.47,0,0,1-4.67-1.64,6.39,6.39,0,0,1-1.71-4.83v-8.54h2.69v8.23a4.6,4.6,0,0,0,1,3.26,3.86,3.86,0,0,0,3,1.11,4.42,4.42,0,0,0,3.36-1.29,5.06,5.06,0,0,0,1.23-3.64v-7.67Z"
      />
      <path
        class="e"
        d="M1585.37,438.22a5.85,5.85,0,0,1,1.64,4.51v9h-2.55v-2a4.24,4.24,0,0,1-1.92,1.58,7.39,7.39,0,0,1-3,.55,6.17,6.17,0,0,1-4-1.21,4.13,4.13,0,0,1-.07-6.31,7,7,0,0,1,4.53-1.19h4.26v-.53a3.42,3.42,0,0,0-1-2.66,4.23,4.23,0,0,0-3-.93,8.06,8.06,0,0,0-2.57.43,6.6,6.6,0,0,0-2.13,1.17l-1.12-2a7.9,7.9,0,0,1,2.74-1.41,11.44,11.44,0,0,1,3.39-.49A6.71,6.71,0,0,1,1585.37,438.22Zm-2.68,10.88a4,4,0,0,0,1.63-2v-2.07h-4.15c-2.27,0-3.41.76-3.41,2.3a2.08,2.08,0,0,0,.87,1.76,3.93,3.93,0,0,0,2.4.64A5,5,0,0,0,1582.69,449.1Z"
      />
      <path class="e" d="M1592,430.91h2.69v20.78H1592Z" />
      <path
        class="e"
        d="m1620.8 451.69-4.23-6c-0.52 0-0.93 0.05-1.23 0.05h-4.84v6h-2.81v-19.6h7.65a9 9 0 0 1 6 1.82 6.15 6.15 0 0 1 2.18 5 6.58 6.58 0 0 1-1.11 3.86 6.37 6.37 0 0 1-3.2 2.33l4.65 6.58zm-1.48-9.49a4 4 0 0 0 1.4-3.28 4 4 0 0 0-1.4-3.26 6.31 6.31 0 0 0-4.06-1.14h-4.76v8.82h4.76a6.31 6.31 0 0 0 4.06-1.14z"
      />
      <path
        class="e"
        d="M1641,445.16h-12a4.66,4.66,0,0,0,1.73,3.15,5.56,5.56,0,0,0,3.65,1.19,5.48,5.48,0,0,0,4.28-1.79l1.49,1.74a6.52,6.52,0,0,1-2.51,1.79,8.72,8.72,0,0,1-3.34.62,8.86,8.86,0,0,1-4.18-1,7,7,0,0,1-2.81-2.7,8.31,8.31,0,0,1,0-7.81,7,7,0,0,1,2.66-2.71,7.63,7.63,0,0,1,3.82-1,7.48,7.48,0,0,1,3.8,1,6.79,6.79,0,0,1,2.6,2.71,8.15,8.15,0,0,1,.94,4A7,7,0,0,1,1641,445.16Zm-10.51-5a4.75,4.75,0,0,0-1.53,3.08h9.52a4.74,4.74,0,0,0-1.52-3.07,5,5,0,0,0-6.47,0Z"
      />
      <path
        class="e"
        d="M1654.53,438.22a5.85,5.85,0,0,1,1.64,4.51v9h-2.55v-2a4.24,4.24,0,0,1-1.92,1.58,7.39,7.39,0,0,1-3,.55,6.21,6.21,0,0,1-4-1.21,4.14,4.14,0,0,1-.06-6.31,7,7,0,0,1,4.53-1.19h4.26v-.53a3.42,3.42,0,0,0-1-2.66,4.23,4.23,0,0,0-3-.93,8.16,8.16,0,0,0-2.58.43,6.54,6.54,0,0,0-2.12,1.17l-1.12-2a7.9,7.9,0,0,1,2.74-1.41,11.34,11.34,0,0,1,3.39-.49A6.71,6.71,0,0,1,1654.53,438.22Zm-2.68,10.88a4,4,0,0,0,1.63-2v-2.07h-4.15c-2.28,0-3.41.76-3.41,2.3a2.08,2.08,0,0,0,.87,1.76,3.93,3.93,0,0,0,2.4.64A5,5,0,0,0,1651.85,449.1Z"
      />
      <path class="e" d="m1661.2 430.91h2.69v20.78h-2.69z" />
      <path
        class="e"
        d="M1669,433.49a1.69,1.69,0,0,1-.52-1.23A1.73,1.73,0,0,1,1669,431a1.76,1.76,0,0,1,1.3-.52,1.82,1.82,0,0,1,1.31.49,1.62,1.62,0,0,1,.51,1.22,1.73,1.73,0,0,1-.51,1.27,1.76,1.76,0,0,1-1.31.52A1.82,1.82,0,0,1,1669,433.49Zm0,3.36h2.69v14.84H1669Z"
      />
      <path
        class="e"
        d="M1685.12,450.82a4.23,4.23,0,0,1-1.46.77,6,6,0,0,1-1.79.27,4.77,4.77,0,0,1-3.47-1.21,4.56,4.56,0,0,1-1.24-3.44v-8.15h-2.52v-2.21h2.52V433.6h2.69v3.25h4.26v2.21h-4.26v8a2.56,2.56,0,0,0,.61,1.84,2.23,2.23,0,0,0,1.72.65,3.28,3.28,0,0,0,2.1-.7Z"
      />
      <path
        class="e"
        d="M1701.21,436.85,1694,453.17a7.37,7.37,0,0,1-2.23,3.19,5.05,5.05,0,0,1-3.06.93,6.59,6.59,0,0,1-2.12-.35,4.42,4.42,0,0,1-1.69-1l1.15-2a3.72,3.72,0,0,0,2.66,1.09,2.56,2.56,0,0,0,1.64-.52,4.38,4.38,0,0,0,1.18-1.77l.48-1-6.55-14.81h2.8l5.18,11.87,5.12-11.87Z"
      />
    </g>
    <rect class="d third" x="30" y="307" width="233" height="80" />
    <g class="a">
      <path
        class="e"
        d="M76,355.84a9.49,9.49,0,0,1-3.71-3.59,10.44,10.44,0,0,1,0-10.27A9.52,9.52,0,0,1,76,338.39a11,11,0,0,1,5.32-1.3,11.17,11.17,0,0,1,4.2.77,8.79,8.79,0,0,1,3.25,2.28L87,341.91a7.33,7.33,0,0,0-5.52-2.33,8,8,0,0,0-4,1,7.07,7.07,0,0,0-2.75,2.7,7.91,7.91,0,0,0,0,7.7,7.07,7.07,0,0,0,2.75,2.7,8.06,8.06,0,0,0,4,1A7.32,7.32,0,0,0,87,352.3l1.82,1.76a8.88,8.88,0,0,1-3.26,2.3,11.12,11.12,0,0,1-4.22.78A10.75,10.75,0,0,1,76,355.84Z"
      />
      <path
        class="e"
        d="M102.35,343.45A5.85,5.85,0,0,1,104,348v9h-2.54v-2a4.31,4.31,0,0,1-1.92,1.58,7.42,7.42,0,0,1-3,.54,6.2,6.2,0,0,1-4-1.2,4.15,4.15,0,0,1-.07-6.31A7,7,0,0,1,97,348.38h4.26v-.54a3.45,3.45,0,0,0-1-2.66,4.28,4.28,0,0,0-3-.92,7.86,7.86,0,0,0-2.58.44,6.25,6.25,0,0,0-2.12,1.16l-1.12-2a7.9,7.9,0,0,1,2.74-1.41,11.34,11.34,0,0,1,3.39-.49A6.73,6.73,0,0,1,102.35,343.45Zm-2.68,10.88a4,4,0,0,0,1.63-1.95v-2.07H97.15c-2.28,0-3.41.76-3.41,2.3a2.07,2.07,0,0,0,.86,1.76A4,4,0,0,0,97,355,5.06,5.06,0,0,0,99.67,354.33Z"
      />
      <path class="e" d="M109,336.14h2.69v20.78H109Z" />
      <path
        class="e"
        d="M119.2,356.11a7.24,7.24,0,0,1-2.78-2.71,8.05,8.05,0,0,1,0-7.81,7.12,7.12,0,0,1,2.78-2.69,8.41,8.41,0,0,1,4-1,7.82,7.82,0,0,1,3.6.81,5.79,5.79,0,0,1,2.45,2.35l-2.05,1.32a4.63,4.63,0,0,0-1.73-1.6,5.05,5.05,0,0,0-2.3-.53,5.32,5.32,0,0,0-2.62.64,4.6,4.6,0,0,0-1.82,1.84,6,6,0,0,0,0,5.48,4.6,4.6,0,0,0,1.82,1.84,5.32,5.32,0,0,0,2.62.64,4.93,4.93,0,0,0,2.3-.53,4.6,4.6,0,0,0,1.73-1.59l2.05,1.28a5.89,5.89,0,0,1-2.45,2.37,7.7,7.7,0,0,1-3.6.82A8.31,8.31,0,0,1,119.2,356.11Z"
      />
      <path
        class="e"
        d="M146.6,342.08v14.84h-2.55v-2.24a5.41,5.41,0,0,1-2.14,1.78,6.68,6.68,0,0,1-2.9.62,6.45,6.45,0,0,1-4.67-1.63,6.38,6.38,0,0,1-1.71-4.83v-8.54h2.69v8.23a4.6,4.6,0,0,0,1,3.26,3.86,3.86,0,0,0,3,1.11,4.42,4.42,0,0,0,3.36-1.29,5.06,5.06,0,0,0,1.23-3.64v-7.67Z"
      />
      <path class="e" d="m151.72 336.14h2.69v20.78h-2.69z" />
      <path
        class="e"
        d="M169.66,343.45a5.85,5.85,0,0,1,1.63,4.51v9h-2.54v-2a4.31,4.31,0,0,1-1.92,1.58,7.4,7.4,0,0,1-3,.54,6.21,6.21,0,0,1-4-1.2,4.15,4.15,0,0,1-.07-6.31,7,7,0,0,1,4.54-1.19h4.26v-.54a3.45,3.45,0,0,0-1-2.66,4.28,4.28,0,0,0-3-.92,7.9,7.9,0,0,0-2.58.44,6.35,6.35,0,0,0-2.12,1.16l-1.12-2a7.9,7.9,0,0,1,2.74-1.41,11.34,11.34,0,0,1,3.39-.49A6.73,6.73,0,0,1,169.66,343.45ZM167,354.33a4,4,0,0,0,1.63-1.95v-2.07h-4.15c-2.27,0-3.41.76-3.41,2.3a2.08,2.08,0,0,0,.87,1.76,3.93,3.93,0,0,0,2.4.64A5,5,0,0,0,167,354.33Z"
      />
      <path
        class="e"
        d="M184.62,356.05a4.18,4.18,0,0,1-1.45.77,6,6,0,0,1-1.8.26,4.77,4.77,0,0,1-3.47-1.2,4.59,4.59,0,0,1-1.23-3.44v-8.15h-2.52v-2.21h2.52v-3.25h2.69v3.25h4.25v2.21h-4.25v8a2.6,2.6,0,0,0,.6,1.84,2.25,2.25,0,0,0,1.72.65,3.28,3.28,0,0,0,2.1-.7Z"
      />
      <path
        class="e"
        d="m189.75 356.11a7.12 7.12 0 0 1-2.75-2.71 8.12 8.12 0 0 1 0-7.81 7 7 0 0 1 2.76-2.69 8.15 8.15 0 0 1 4-1 8.06 8.06 0 0 1 4 1 7 7 0 0 1 2.75 2.69 8.2 8.2 0 0 1 0 7.81 7.16 7.16 0 0 1-2.75 2.71 8 8 0 0 1-4 1 8.05 8.05 0 0 1-4.01-1zm6.53-2a4.66 4.66 0 0 0 1.78-1.85 6.06 6.06 0 0 0 0-5.46 4.51 4.51 0 0 0-1.78-1.84 5.44 5.44 0 0 0-5.12 0 4.6 4.6 0 0 0-1.79 1.84 6 6 0 0 0 0 5.46 4.75 4.75 0 0 0 1.79 1.85 5.37 5.37 0 0 0 5.12 0z"
      />
      <path
        class="e"
        d="m209.79 342.61a7.54 7.54 0 0 1 3.34-0.67v2.6a5 5 0 0 0-0.62 0 4.51 4.51 0 0 0-3.43 1.31 5.14 5.14 0 0 0-1.25 3.71v7.39h-2.68v-14.87h2.57v2.49a4.63 4.63 0 0 1 2.07-1.96z"
      />
      <path
        class="e"
        d="M217.3,356.59a7.58,7.58,0,0,1-2.63-1.24l1.12-2.13a7.84,7.84,0,0,0,2.38,1.12,9.73,9.73,0,0,0,2.82.42c2.36,0,3.53-.67,3.53-2a1.33,1.33,0,0,0-.47-1.07,3.07,3.07,0,0,0-1.19-.57c-.48-.12-1.16-.26-2-.41a20.8,20.8,0,0,1-2.93-.64,4.7,4.7,0,0,1-2-1.23,3.46,3.46,0,0,1-.81-2.44,3.79,3.79,0,0,1,1.68-3.23,7.47,7.47,0,0,1,4.51-1.22,12.57,12.57,0,0,1,3,.36,7.84,7.84,0,0,1,2.43,1l-1.14,2.13a7.71,7.71,0,0,0-4.29-1.18,5,5,0,0,0-2.6.56,1.7,1.7,0,0,0-.9,1.49,1.4,1.4,0,0,0,.51,1.14,3,3,0,0,0,1.24.62c.5.13,1.19.28,2.09.45a21,21,0,0,1,2.9.66,4.38,4.38,0,0,1,1.89,1.19,3.32,3.32,0,0,1,.78,2.35,3.69,3.69,0,0,1-1.72,3.2,8.08,8.08,0,0,1-4.69,1.19A12.28,12.28,0,0,1,217.3,356.59Z"
      />
    </g>
    <rect class="d fourth" x="278" y="307" width="144" height="80" />
    <g class="a">
      <path
        class="e"
        d="M336.67,356.92l-4.23-6c-.52,0-.93.05-1.23.05h-4.84v6h-2.8v-19.6h7.64a9,9,0,0,1,6,1.82,6.15,6.15,0,0,1,2.19,5,6.59,6.59,0,0,1-1.12,3.86,6.34,6.34,0,0,1-3.19,2.33l4.64,6.58Zm-1.48-9.5a4.51,4.51,0,0,0,0-6.53,6.31,6.31,0,0,0-4.06-1.14h-4.76v8.82h4.76A6.26,6.26,0,0,0,335.19,347.42Z"
      />
      <path
        class="e"
        d="M346.72,339.75v6.83h9.83V349h-9.83v7.93h-2.8v-19.6h13.84v2.43Z"
      />
      <path
        class="e"
        d="m375.34 339.14a6.85 6.85 0 0 1 0 10 9 9 0 0 1-6 1.82h-4.84v5.94h-2.8v-19.6h7.65a9 9 0 0 1 5.99 1.84zm-2 8.27a4.5 4.5 0 0 0 0-6.52 6.28 6.28 0 0 0-4.06-1.14h-4.78v8.79h4.76a6.28 6.28 0 0 0 4.06-1.13z"
      />
    </g>
    <rect class="d fifth" x="1447" y="307" width="327" height="80" />
    <g class="a">
      <path
        class="e"
        d="M1488.57,337.32h8.26a11.92,11.92,0,0,1,5.51,1.23,9,9,0,0,1,3.74,3.46,10.48,10.48,0,0,1,0,10.22,9,9,0,0,1-3.74,3.45,11.8,11.8,0,0,1-5.51,1.24h-8.26Zm8.09,17.16a9.17,9.17,0,0,0,4.19-.92,6.7,6.7,0,0,0,2.78-2.59,8.05,8.05,0,0,0,0-7.7,6.63,6.63,0,0,0-2.78-2.59,9,9,0,0,0-4.19-.93h-5.29v14.73Z"
      />
      <path
        class="e"
        d="M1521.43,343.45a5.85,5.85,0,0,1,1.64,4.51v9h-2.55v-2a4.31,4.31,0,0,1-1.92,1.58,7.4,7.4,0,0,1-2.95.54,6.16,6.16,0,0,1-4-1.2,4.14,4.14,0,0,1-.08-6.31,7,7,0,0,1,4.54-1.19h4.26v-.54a3.45,3.45,0,0,0-1-2.66,4.28,4.28,0,0,0-3-.92,7.8,7.8,0,0,0-2.57.44,6.4,6.4,0,0,0-2.13,1.16l-1.12-2a7.9,7.9,0,0,1,2.74-1.41,11.34,11.34,0,0,1,3.39-.49A6.73,6.73,0,0,1,1521.43,343.45Zm-2.68,10.88a4,4,0,0,0,1.63-1.95v-2.07h-4.15c-2.27,0-3.41.76-3.41,2.3a2.08,2.08,0,0,0,.87,1.76,3.93,3.93,0,0,0,2.4.64A5,5,0,0,0,1518.75,354.33Z"
      />
      <path
        class="e"
        d="M1536.39,356.05a4.18,4.18,0,0,1-1.45.77,6,6,0,0,1-1.8.26,4.77,4.77,0,0,1-3.47-1.2,4.59,4.59,0,0,1-1.23-3.44v-8.15h-2.52v-2.21h2.52v-3.25h2.69v3.25h4.25v2.21h-4.25v8a2.6,2.6,0,0,0,.6,1.84,2.25,2.25,0,0,0,1.72.65,3.28,3.28,0,0,0,2.1-.7Z"
      />
      <path
        class="e"
        d="M1549.76,343.45a5.85,5.85,0,0,1,1.64,4.51v9h-2.55v-2a4.31,4.31,0,0,1-1.92,1.58,7.36,7.36,0,0,1-3,.54,6.2,6.2,0,0,1-4-1.2,4.13,4.13,0,0,1-.07-6.31,7,7,0,0,1,4.54-1.19h4.25v-.54a3.42,3.42,0,0,0-1-2.66,4.27,4.27,0,0,0-3-.92,7.86,7.86,0,0,0-2.58.44,6.4,6.4,0,0,0-2.13,1.16l-1.12-2a8,8,0,0,1,2.75-1.41,11.32,11.32,0,0,1,3.38-.49A6.69,6.69,0,0,1,1549.76,343.45Zm-2.67,10.88a3.94,3.94,0,0,0,1.62-1.95v-2.07h-4.14c-2.28,0-3.42.76-3.42,2.3a2.08,2.08,0,0,0,.87,1.76,4,4,0,0,0,2.41.64A5.08,5.08,0,0,0,1547.09,354.33Z"
      />
      <path
        class="e"
        d="m1581.3 337.32-8.59 19.6h-2.74l-8.62-19.6h3l7.06 16.1 7.11-16.1z"
      />
      <path
        class="e"
        d="M1583.5,338.72a1.69,1.69,0,0,1-.52-1.23,1.71,1.71,0,0,1,.52-1.25,1.76,1.76,0,0,1,1.3-.52,1.82,1.82,0,0,1,1.31.49,1.62,1.62,0,0,1,.51,1.22,1.73,1.73,0,0,1-.51,1.27,1.76,1.76,0,0,1-1.31.52A1.82,1.82,0,0,1,1583.5,338.72Zm0,3.36h2.69v14.84h-2.69Z"
      />
      <path
        class="e"
        d="M1592,356.59a7.58,7.58,0,0,1-2.63-1.24l1.12-2.13a7.84,7.84,0,0,0,2.38,1.12,9.73,9.73,0,0,0,2.82.42c2.36,0,3.53-.67,3.53-2a1.33,1.33,0,0,0-.47-1.07,3.07,3.07,0,0,0-1.19-.57c-.48-.12-1.16-.26-2-.41a20.8,20.8,0,0,1-2.93-.64,4.64,4.64,0,0,1-2-1.23,3.46,3.46,0,0,1-.81-2.44,3.79,3.79,0,0,1,1.68-3.23,7.47,7.47,0,0,1,4.51-1.22,12.57,12.57,0,0,1,3,.36,7.84,7.84,0,0,1,2.43,1l-1.14,2.13a7.71,7.71,0,0,0-4.29-1.18,5,5,0,0,0-2.6.56,1.7,1.7,0,0,0-.9,1.49,1.4,1.4,0,0,0,.51,1.14,3,3,0,0,0,1.24.62c.5.13,1.19.28,2.09.45a21,21,0,0,1,2.9.66,4.38,4.38,0,0,1,1.89,1.19,3.32,3.32,0,0,1,.78,2.35,3.69,3.69,0,0,1-1.72,3.2,8.08,8.08,0,0,1-4.69,1.19A12.28,12.28,0,0,1,1592,356.59Z"
      />
      <path
        class="e"
        d="M1619.13,342.08v14.84h-2.55v-2.24a5.41,5.41,0,0,1-2.14,1.78,6.68,6.68,0,0,1-2.9.62,6.46,6.46,0,0,1-4.67-1.63,6.38,6.38,0,0,1-1.71-4.83v-8.54h2.69v8.23a4.6,4.6,0,0,0,1,3.26,3.86,3.86,0,0,0,3,1.11,4.42,4.42,0,0,0,3.36-1.29,5.06,5.06,0,0,0,1.23-3.64v-7.67Z"
      />
      <path
        class="e"
        d="M1634.38,343.45A5.85,5.85,0,0,1,1636,348v9h-2.55v-2a4.31,4.31,0,0,1-1.92,1.58,7.4,7.4,0,0,1-3,.54,6.16,6.16,0,0,1-4-1.2,4.13,4.13,0,0,1-.07-6.31,7,7,0,0,1,4.53-1.19h4.26v-.54a3.45,3.45,0,0,0-1-2.66,4.28,4.28,0,0,0-3-.92,7.8,7.8,0,0,0-2.57.44,6.4,6.4,0,0,0-2.13,1.16l-1.12-2a7.9,7.9,0,0,1,2.74-1.41,11.34,11.34,0,0,1,3.39-.49A6.71,6.71,0,0,1,1634.38,343.45Zm-2.68,10.88a4,4,0,0,0,1.63-1.95v-2.07h-4.15c-2.27,0-3.41.76-3.41,2.3a2.08,2.08,0,0,0,.87,1.76,3.93,3.93,0,0,0,2.4.64A5,5,0,0,0,1631.7,354.33Z"
      />
      <path class="e" d="M1641,336.14h2.69v20.78H1641Z" />
      <path
        class="e"
        d="M1648.85,338.72a1.69,1.69,0,0,1-.52-1.23,1.71,1.71,0,0,1,.52-1.25,1.76,1.76,0,0,1,1.3-.52,1.82,1.82,0,0,1,1.31.49,1.62,1.62,0,0,1,.51,1.22,1.73,1.73,0,0,1-.51,1.27,1.76,1.76,0,0,1-1.31.52A1.82,1.82,0,0,1,1648.85,338.72Zm0,3.36h2.69v14.84h-2.69Z"
      />
      <path
        class="e"
        d="m1667.6 354.71v2.21h-12.44v-1.74l8.77-10.89h-8.6v-2.21h12v1.76l-8.76 10.87z"
      />
      <path
        class="e"
        d="M1681.33,343.45A5.85,5.85,0,0,1,1683,348v9h-2.55v-2a4.31,4.31,0,0,1-1.92,1.58,7.36,7.36,0,0,1-3,.54,6.2,6.2,0,0,1-4-1.2,4.13,4.13,0,0,1-.07-6.31,7,7,0,0,1,4.54-1.19h4.25v-.54a3.42,3.42,0,0,0-1-2.66,4.27,4.27,0,0,0-3-.92,7.86,7.86,0,0,0-2.58.44,6.4,6.4,0,0,0-2.13,1.16l-1.12-2a8,8,0,0,1,2.75-1.41,11.32,11.32,0,0,1,3.38-.49A6.69,6.69,0,0,1,1681.33,343.45Zm-2.67,10.88a3.94,3.94,0,0,0,1.62-1.95v-2.07h-4.14c-2.28,0-3.42.76-3.42,2.3a2.08,2.08,0,0,0,.87,1.76,4,4,0,0,0,2.41.64A5.08,5.08,0,0,0,1678.66,354.33Z"
      />
      <path
        class="e"
        d="M1696.3,356.05a4.14,4.14,0,0,1-1.46.77,6,6,0,0,1-1.79.26,4.77,4.77,0,0,1-3.47-1.2,4.59,4.59,0,0,1-1.23-3.44v-8.15h-2.52v-2.21h2.52v-3.25H1691v3.25h4.26v2.21H1691v8a2.56,2.56,0,0,0,.61,1.84,2.23,2.23,0,0,0,1.72.65,3.28,3.28,0,0,0,2.1-.7Z"
      />
      <path
        class="e"
        d="M1699.59,338.72a1.65,1.65,0,0,1-.52-1.23,1.68,1.68,0,0,1,.52-1.25,1.74,1.74,0,0,1,1.3-.52,1.8,1.8,0,0,1,1.3.49,1.6,1.6,0,0,1,.52,1.22,1.7,1.7,0,0,1-.52,1.27,1.74,1.74,0,0,1-1.3.52A1.8,1.8,0,0,1,1699.59,338.72Zm0,3.36h2.69v14.84h-2.69Z"
      />
      <path
        class="e"
        d="m1709.7 356.11a7.12 7.12 0 0 1-2.76-2.71 8.2 8.2 0 0 1 0-7.81 7 7 0 0 1 2.76-2.69 8.69 8.69 0 0 1 7.94 0 7.08 7.08 0 0 1 2.74 2.69 8.2 8.2 0 0 1 0 7.81 7.2 7.2 0 0 1-2.74 2.71 8.61 8.61 0 0 1-7.94 0zm6.54-2a4.71 4.71 0 0 0 1.77-1.85 6.06 6.06 0 0 0 0-5.46 4.55 4.55 0 0 0-1.77-1.84 5.46 5.46 0 0 0-5.13 0 4.66 4.66 0 0 0-1.79 1.84 6 6 0 0 0 0 5.46 4.83 4.83 0 0 0 1.79 1.85 5.39 5.39 0 0 0 5.13 0z"
      />
      <path
        class="e"
        d="m1737.6 343.58a6.4 6.4 0 0 1 1.67 4.8v8.54h-2.69v-8.24a4.55 4.55 0 0 0-1-3.24 3.89 3.89 0 0 0-3-1.1 4.63 4.63 0 0 0-3.45 1.28 5 5 0 0 0-1.25 3.65v7.65h-2.69v-14.84h2.57v2.24a5.22 5.22 0 0 1 2.2-1.77 7.66 7.66 0 0 1 3.15-0.61 6.08 6.08 0 0 1 4.49 1.64z"
      />
    </g>
    <rect class="d" x="19" y="212" width="234" height="80" />
    <g class="a">
      <path
        class="e"
        d="m82.74 261.92v-14.28l-7.08 11.9h-1.32l-7.08-11.82v14.2h-2.69v-19.6h2.29l8.14 13.77 8.06-13.77h2.3v19.6z"
      />
      <path
        class="e"
        d="m104.27 255.39h-12a4.65 4.65 0 0 0 1.73 3.15 5.59 5.59 0 0 0 3.66 1.19 5.48 5.48 0 0 0 4.28-1.79l1.49 1.74a6.6 6.6 0 0 1-2.51 1.79 9.52 9.52 0 0 1-7.52-0.35 7 7 0 0 1-2.81-2.7 8.23 8.23 0 0 1 0-7.81 6.93 6.93 0 0 1 2.66-2.71 7.61 7.61 0 0 1 3.82-1 7.5 7.5 0 0 1 3.8 1 6.85 6.85 0 0 1 2.6 2.71 8.26 8.26 0 0 1 0.94 4 7 7 0 0 1-0.14 0.78zm-10.51-5a4.75 4.75 0 0 0-1.53 3.08h9.52a4.83 4.83 0 0 0-1.52-3.07 5 5 0 0 0-6.47 0z"
      />
      <path
        class="e"
        d="M122.05,241.14v20.78h-2.57v-2.35a5.88,5.88,0,0,1-2.27,1.87,7.06,7.06,0,0,1-3,.64,7.75,7.75,0,0,1-3.86-.95,6.72,6.72,0,0,1-2.67-2.67,7.91,7.91,0,0,1-1-4,7.81,7.81,0,0,1,1-3.95,6.76,6.76,0,0,1,2.67-2.66,7.75,7.75,0,0,1,3.86-.95,7.09,7.09,0,0,1,2.94.6,5.57,5.57,0,0,1,2.24,1.78v-8.18ZM117,259.08a4.69,4.69,0,0,0,1.79-1.85,6.14,6.14,0,0,0,0-5.46,4.53,4.53,0,0,0-1.79-1.84,5.14,5.14,0,0,0-2.55-.64,5.07,5.07,0,0,0-2.56.64,4.62,4.62,0,0,0-1.8,1.84,6.06,6.06,0,0,0,0,5.46,4.78,4.78,0,0,0,1.8,1.85,5.07,5.07,0,0,0,2.56.65A5.14,5.14,0,0,0,117,259.08Z"
      />
      <path
        class="e"
        d="M127.19,243.72a1.65,1.65,0,0,1-.52-1.23,1.68,1.68,0,0,1,.52-1.25,1.74,1.74,0,0,1,1.3-.52,1.8,1.8,0,0,1,1.3.49,1.6,1.6,0,0,1,.52,1.22,1.7,1.7,0,0,1-.52,1.27,1.74,1.74,0,0,1-1.3.52A1.8,1.8,0,0,1,127.19,243.72Zm0,3.36h2.68v14.84h-2.68Z"
      />
      <path
        class="e"
        d="M145.08,248.45a5.85,5.85,0,0,1,1.64,4.51v9h-2.55v-2a4.31,4.31,0,0,1-1.92,1.58,7.36,7.36,0,0,1-2.95.54,6.2,6.2,0,0,1-4-1.2,4.13,4.13,0,0,1-.07-6.31,7,7,0,0,1,4.53-1.19H144v-.54a3.42,3.42,0,0,0-1-2.66,4.27,4.27,0,0,0-3-.92,7.86,7.86,0,0,0-2.58.44,6.4,6.4,0,0,0-2.13,1.16l-1.12-2a8,8,0,0,1,2.75-1.41,11.32,11.32,0,0,1,3.38-.49A6.69,6.69,0,0,1,145.08,248.45Zm-2.67,10.88a3.94,3.94,0,0,0,1.62-1.95v-2.07h-4.14c-2.28,0-3.42.76-3.42,2.3a2.08,2.08,0,0,0,.87,1.76,4,4,0,0,0,2.41.64A5.08,5.08,0,0,0,142.41,259.33Z"
      />
      <path
        class="e"
        d="m166 253.21-3.58 3.64v5.07h-2.8v-19.6h2.8v10.94l10.64-10.94h3.19l-8.37 8.82 8.9 10.78h-3.28z"
      />
      <path
        class="e"
        d="M179.13,243.72a1.65,1.65,0,0,1-.52-1.23,1.68,1.68,0,0,1,.52-1.25,1.74,1.74,0,0,1,1.3-.52,1.8,1.8,0,0,1,1.3.49,1.6,1.6,0,0,1,.52,1.22,1.7,1.7,0,0,1-.52,1.27,1.74,1.74,0,0,1-1.3.52A1.8,1.8,0,0,1,179.13,243.72Zm0,3.36h2.68v14.84h-2.68Z"
      />
      <path
        class="e"
        d="M195.24,261.05a4.18,4.18,0,0,1-1.45.77,6,6,0,0,1-1.8.26,4.77,4.77,0,0,1-3.47-1.2,4.59,4.59,0,0,1-1.23-3.44v-8.15h-2.52v-2.21h2.52v-3.25H190v3.25h4.25v2.21H190v8a2.6,2.6,0,0,0,.6,1.84,2.25,2.25,0,0,0,1.72.65,3.28,3.28,0,0,0,2.1-.7Z"
      />
      <path
        class="e"
        d="M199.25,261.59a7.73,7.73,0,0,1-2.64-1.24l1.12-2.13a8.08,8.08,0,0,0,2.38,1.12,9.85,9.85,0,0,0,2.83.42c2.35,0,3.53-.67,3.53-2a1.31,1.31,0,0,0-.48-1.07,2.94,2.94,0,0,0-1.19-.57c-.47-.12-1.15-.26-2-.41a20.62,20.62,0,0,1-2.92-.64,4.64,4.64,0,0,1-1.95-1.23,3.46,3.46,0,0,1-.81-2.44,3.79,3.79,0,0,1,1.68-3.23,7.47,7.47,0,0,1,4.51-1.22,12.57,12.57,0,0,1,3,.36,7.84,7.84,0,0,1,2.43,1l-1.15,2.13a7.66,7.66,0,0,0-4.28-1.18,5,5,0,0,0-2.6.56,1.7,1.7,0,0,0-.9,1.49,1.42,1.42,0,0,0,.5,1.14,3.13,3.13,0,0,0,1.25.62c.49.13,1.19.28,2.09.45a20.49,20.49,0,0,1,2.89.66,4.27,4.27,0,0,1,1.89,1.19,3.28,3.28,0,0,1,.79,2.35,3.72,3.72,0,0,1-1.72,3.2,8.1,8.1,0,0,1-4.69,1.19A12.28,12.28,0,0,1,199.25,261.59Z"
      />
    </g>
    <rect class="d second" x="268" y="212" width="299" height="80" />
    <g class="a">
      <path
        class="e"
        d="m326.67 261.92-4.23-6h-1.23-4.84v6h-2.8v-19.6h7.64a9 9 0 0 1 6 1.82 6.15 6.15 0 0 1 2.19 5 6.59 6.59 0 0 1-1.12 3.86 6.34 6.34 0 0 1-3.19 2.33l4.64 6.58zm-1.48-9.5a4.51 4.51 0 0 0 0-6.53 6.31 6.31 0 0 0-4.06-1.14h-4.76v8.82h4.76a6.26 6.26 0 0 0 4.06-1.15z"
      />
      <path
        class="e"
        d="m346.89 255.39h-12a4.65 4.65 0 0 0 1.72 3.15 5.58 5.58 0 0 0 3.65 1.19 5.5 5.5 0 0 0 4.29-1.79l1.48 1.74a6.56 6.56 0 0 1-2.5 1.79 9.52 9.52 0 0 1-7.52-0.35 7 7 0 0 1-2.82-2.7 8.27 8.27 0 0 1 0-7.81 6.93 6.93 0 0 1 2.66-2.71 7.58 7.58 0 0 1 3.82-1 7.44 7.44 0 0 1 3.79 1 6.88 6.88 0 0 1 2.61 2.71 8.25 8.25 0 0 1 0.93 4c-0.06 0.18-0.08 0.45-0.11 0.78zm-10.52-5a4.79 4.79 0 0 0-1.52 3.08h9.52a4.8 4.8 0 0 0-1.53-3.07 4.61 4.61 0 0 0-3.23-1.18 4.67 4.67 0 0 0-3.24 1.16z"
      />
      <path
        class="e"
        d="M351.42,261.59a7.67,7.67,0,0,1-2.63-1.24l1.12-2.13a8,8,0,0,0,2.38,1.12,9.8,9.8,0,0,0,2.83.42c2.35,0,3.53-.67,3.53-2a1.31,1.31,0,0,0-.48-1.07,3,3,0,0,0-1.19-.57c-.47-.12-1.15-.26-2-.41a20.62,20.62,0,0,1-2.92-.64,4.64,4.64,0,0,1-1.95-1.23,3.46,3.46,0,0,1-.81-2.44,3.79,3.79,0,0,1,1.68-3.23,7.47,7.47,0,0,1,4.51-1.22,12.55,12.55,0,0,1,3,.36,8,8,0,0,1,2.44,1l-1.15,2.13a7.66,7.66,0,0,0-4.28-1.18,5,5,0,0,0-2.61.56,1.71,1.71,0,0,0-.89,1.49,1.42,1.42,0,0,0,.5,1.14,3.06,3.06,0,0,0,1.25.62c.49.13,1.19.28,2.08.45a20.67,20.67,0,0,1,2.9.66,4.27,4.27,0,0,1,1.89,1.19,3.32,3.32,0,0,1,.79,2.35,3.7,3.7,0,0,1-1.73,3.2,8.06,8.06,0,0,1-4.69,1.19A12.33,12.33,0,0,1,351.42,261.59Z"
      />
      <path
        class="e"
        d="m367.08 261.11a7.12 7.12 0 0 1-2.76-2.71 8.12 8.12 0 0 1 0-7.81 7 7 0 0 1 2.76-2.69 8.15 8.15 0 0 1 4-1 8.06 8.06 0 0 1 4 1 7.11 7.11 0 0 1 2.75 2.69 8.2 8.2 0 0 1 0 7.81 7.23 7.23 0 0 1-2.75 2.71 8 8 0 0 1-4 1 8.05 8.05 0 0 1-4-1zm6.53-2a4.66 4.66 0 0 0 1.78-1.85 6.06 6.06 0 0 0 0-5.46 4.51 4.51 0 0 0-1.78-1.84 5.44 5.44 0 0 0-5.12 0 4.6 4.6 0 0 0-1.79 1.84 6 6 0 0 0 0 5.46 4.75 4.75 0 0 0 1.79 1.85 5.37 5.37 0 0 0 5.12 0z"
      />
      <path
        class="e"
        d="M396.31,247.08v14.84h-2.55v-2.24a5.48,5.48,0,0,1-2.14,1.78,6.71,6.71,0,0,1-2.9.62,6.49,6.49,0,0,1-4.68-1.63,6.37,6.37,0,0,1-1.7-4.83v-8.54H385v8.23a4.61,4.61,0,0,0,1,3.26,3.86,3.86,0,0,0,3,1.11,4.42,4.42,0,0,0,3.36-1.29,5.06,5.06,0,0,0,1.23-3.64v-7.67Z"
      />
      <path
        class="e"
        d="m406.08 247.61a7.46 7.46 0 0 1 3.33-0.67v2.6a4.88 4.88 0 0 0-0.62 0 4.54 4.54 0 0 0-3.43 1.31 5.13 5.13 0 0 0-1.24 3.71v7.39h-2.69v-14.87h2.57v2.49a4.53 4.53 0 0 1 2.08-1.96z"
      />
      <path
        class="e"
        d="M415,261.11a7.2,7.2,0,0,1-2.79-2.71,8.05,8.05,0,0,1,0-7.81A7.08,7.08,0,0,1,415,247.9a8.35,8.35,0,0,1,4-1,7.76,7.76,0,0,1,3.6.81,5.73,5.73,0,0,1,2.45,2.35l-2,1.32a4.66,4.66,0,0,0-1.74-1.6,5,5,0,0,0-2.3-.53,5.26,5.26,0,0,0-2.61.64,4.54,4.54,0,0,0-1.82,1.84,6,6,0,0,0,0,5.48,4.54,4.54,0,0,0,1.82,1.84,5.26,5.26,0,0,0,2.61.64,4.9,4.9,0,0,0,2.3-.53,4.63,4.63,0,0,0,1.74-1.59l2,1.28a5.83,5.83,0,0,1-2.45,2.37,7.64,7.64,0,0,1-3.6.82A8.24,8.24,0,0,1,415,261.11Z"
      />
      <path
        class="e"
        d="m441.69 255.39h-12a4.66 4.66 0 0 0 1.73 3.15 5.58 5.58 0 0 0 3.65 1.19 5.48 5.48 0 0 0 4.28-1.79l1.49 1.74a6.52 6.52 0 0 1-2.51 1.79 8.72 8.72 0 0 1-3.34 0.61 8.85 8.85 0 0 1-4.18-1 7 7 0 0 1-2.81-2.7 8.31 8.31 0 0 1 0-7.81 7 7 0 0 1 2.66-2.71 7.64 7.64 0 0 1 3.83-1 7.47 7.47 0 0 1 3.79 1 6.79 6.79 0 0 1 2.6 2.71 8.15 8.15 0 0 1 0.94 4 7 7 0 0 1-0.13 0.82zm-10.51-5a4.75 4.75 0 0 0-1.53 3.08h9.52a4.74 4.74 0 0 0-1.52-3.07 5 5 0 0 0-6.47 0z"
      />
      <path
        class="e"
        d="M470.25,242.32v19.6h-2.79v-8.74H456.2v8.74h-2.8v-19.6h2.8v8.43h11.26v-8.43Z"
      />
      <path
        class="e"
        d="M489.58,247.08v14.84H487v-2.24a5.44,5.44,0,0,1-2.15,1.78,6.64,6.64,0,0,1-2.89.62,6.49,6.49,0,0,1-4.68-1.63,6.38,6.38,0,0,1-1.71-4.83v-8.54h2.69v8.23a4.61,4.61,0,0,0,1,3.26,3.85,3.85,0,0,0,3,1.11,4.46,4.46,0,0,0,3.37-1.29,5.06,5.06,0,0,0,1.23-3.64v-7.67Z"
      />
      <path
        class="e"
        d="M506.43,247.89a6.76,6.76,0,0,1,2.67,2.66,7.81,7.81,0,0,1,1,3.95,7.91,7.91,0,0,1-1,4,6.72,6.72,0,0,1-2.67,2.67,7.75,7.75,0,0,1-3.86.95,7,7,0,0,1-3-.64,5.88,5.88,0,0,1-2.27-1.87v2.35H494.7V241.14h2.69v8.18a5.57,5.57,0,0,1,2.24-1.78,7.09,7.09,0,0,1,2.94-.6A7.75,7.75,0,0,1,506.43,247.89Zm-1.53,11.19a4.78,4.78,0,0,0,1.8-1.85,6.06,6.06,0,0,0,0-5.46,4.62,4.62,0,0,0-1.8-1.84,5.07,5.07,0,0,0-2.56-.64,5.14,5.14,0,0,0-2.55.64,4.53,4.53,0,0,0-1.79,1.84,6.14,6.14,0,0,0,0,5.46,4.69,4.69,0,0,0,1.79,1.85,5.14,5.14,0,0,0,2.55.65A5.07,5.07,0,0,0,504.9,259.08Z"
      />
      <path
        class="e"
        d="M514.55,261.59a7.58,7.58,0,0,1-2.63-1.24l1.12-2.13a7.84,7.84,0,0,0,2.38,1.12,9.75,9.75,0,0,0,2.83.42c2.35,0,3.52-.67,3.52-2a1.3,1.3,0,0,0-.47-1.07,3.07,3.07,0,0,0-1.19-.57c-.48-.12-1.15-.26-2-.41a20.8,20.8,0,0,1-2.93-.64,4.67,4.67,0,0,1-1.94-1.23,3.41,3.41,0,0,1-.82-2.44,3.79,3.79,0,0,1,1.68-3.23,7.49,7.49,0,0,1,4.51-1.22,12.57,12.57,0,0,1,3,.36,8,8,0,0,1,2.44,1l-1.15,2.13a7.69,7.69,0,0,0-4.29-1.18,5,5,0,0,0-2.6.56,1.7,1.7,0,0,0-.9,1.49,1.4,1.4,0,0,0,.51,1.14,3,3,0,0,0,1.24.62c.5.13,1.19.28,2.09.45a21,21,0,0,1,2.9.66,4.27,4.27,0,0,1,1.89,1.19,3.32,3.32,0,0,1,.78,2.35,3.69,3.69,0,0,1-1.72,3.2,8.08,8.08,0,0,1-4.69,1.19A12.33,12.33,0,0,1,514.55,261.59Z"
      />
    </g>
    <rect class="d third" x="1251" y="212" width="362" height="80" />
    <g class="a">
      <path
        class="e"
        d="m1298.5 244.75h-6.72v-2.43h16.26v2.43h-6.72v17.17h-2.77z"
      />
      <path
        class="e"
        d="m1323 248.58a6.43 6.43 0 0 1 1.66 4.8v8.54h-2.66v-8.24a4.55 4.55 0 0 0-1-3.24 3.89 3.89 0 0 0-3-1.1 4.63 4.63 0 0 0-3.44 1.28 5 5 0 0 0-1.26 3.65v7.65h-2.69v-20.78h2.69v8a5.49 5.49 0 0 1 2.2-1.65 7.4 7.4 0 0 1 3-0.59 6.1 6.1 0 0 1 4.5 1.68z"
      />
      <path
        class="e"
        d="m1332.1 261.11a7.26 7.26 0 0 1-2.76-2.71 8.2 8.2 0 0 1 0-7.81 7.14 7.14 0 0 1 2.76-2.69 8.69 8.69 0 0 1 7.94 0 7 7 0 0 1 2.74 2.69 8.12 8.12 0 0 1 0 7.81 7.13 7.13 0 0 1-2.74 2.71 8.61 8.61 0 0 1-7.94 0zm6.54-2a4.66 4.66 0 0 0 1.78-1.85 6.14 6.14 0 0 0 0-5.46 4.51 4.51 0 0 0-1.78-1.84 5.44 5.44 0 0 0-5.12 0 4.62 4.62 0 0 0-1.8 1.84 6.06 6.06 0 0 0 0 5.46 4.78 4.78 0 0 0 1.8 1.85 5.37 5.37 0 0 0 5.12 0z"
      />
      <path
        class="e"
        d="M1361.37,247.08v14.84h-2.54v-2.24a5.44,5.44,0,0,1-2.15,1.78,6.64,6.64,0,0,1-2.89.62,6.49,6.49,0,0,1-4.68-1.63,6.38,6.38,0,0,1-1.71-4.83v-8.54h2.69v8.23a4.61,4.61,0,0,0,1,3.26,3.85,3.85,0,0,0,3,1.11,4.42,4.42,0,0,0,3.36-1.29,5,5,0,0,0,1.24-3.64v-7.67Z"
      />
      <path
        class="e"
        d="M1380.69,247.08V259.9c0,2.6-.64,4.51-1.93,5.76s-3.21,1.86-5.77,1.86a13.53,13.53,0,0,1-4-.58,7.85,7.85,0,0,1-3.08-1.64l1.29-2.07a7.6,7.6,0,0,0,2.56,1.42,9.91,9.91,0,0,0,3.15.52,5.28,5.28,0,0,0,3.85-1.24,5.07,5.07,0,0,0,1.25-3.75V259a6.07,6.07,0,0,1-2.34,1.73,7.87,7.87,0,0,1-3,.57,8.07,8.07,0,0,1-3.85-.91,6.77,6.77,0,0,1-2.7-2.55,7.54,7.54,0,0,1,0-7.47,6.68,6.68,0,0,1,2.7-2.54,8.3,8.3,0,0,1,3.85-.89,7.86,7.86,0,0,1,3.12.61,5.86,5.86,0,0,1,2.37,1.82v-2.29Zm-5.11,11.25a4.5,4.5,0,0,0,1.82-1.72,4.79,4.79,0,0,0,.66-2.5,4.53,4.53,0,0,0-1.43-3.49,5.76,5.76,0,0,0-7.36,0,4.56,4.56,0,0,0-1.43,3.49,4.79,4.79,0,0,0,.66,2.5,4.5,4.5,0,0,0,1.82,1.72,5.57,5.57,0,0,0,2.65.62A5.47,5.47,0,0,0,1375.58,258.33Z"
      />
      <path
        class="e"
        d="m1398.2 248.58a6.43 6.43 0 0 1 1.66 4.8v8.54h-2.69v-8.24a4.55 4.55 0 0 0-1-3.24 3.89 3.89 0 0 0-3-1.1 4.61 4.61 0 0 0-3.44 1.28 5 5 0 0 0-1.26 3.65v7.65h-2.69v-20.78h2.69v8a5.53 5.53 0 0 1 2.19-1.65 7.46 7.46 0 0 1 3-0.59 6.12 6.12 0 0 1 4.54 1.68z"
      />
      <path
        class="e"
        d="M1413.23,261.05a4.14,4.14,0,0,1-1.46.77,5.92,5.92,0,0,1-1.79.26,4.77,4.77,0,0,1-3.47-1.2,4.59,4.59,0,0,1-1.23-3.44v-8.15h-2.52v-2.21h2.52v-3.25H1408v3.25h4.26v2.21H1408v8a2.6,2.6,0,0,0,.61,1.84,2.23,2.23,0,0,0,1.72.65,3.28,3.28,0,0,0,2.1-.7Z"
      />
      <path class="e" d="m1424.4 242.32h2.8v17.16h10.61v2.44h-13.41z" />
      <path
        class="e"
        d="m1453.8 255.39h-12a4.65 4.65 0 0 0 1.72 3.15 5.59 5.59 0 0 0 3.66 1.19 5.48 5.48 0 0 0 4.28-1.79l1.49 1.74a6.6 6.6 0 0 1-2.51 1.79 9.52 9.52 0 0 1-7.52-0.35 7 7 0 0 1-2.81-2.7 7.7 7.7 0 0 1-1-3.92 7.88 7.88 0 0 1 1-3.89 6.93 6.93 0 0 1 2.66-2.71 7.61 7.61 0 0 1 3.82-1 7.48 7.48 0 0 1 3.8 1 6.85 6.85 0 0 1 2.6 2.71 8.26 8.26 0 0 1 0.94 4 7 7 0 0 1-0.13 0.78zm-10.51-5a4.75 4.75 0 0 0-1.53 3.08h9.52a4.83 4.83 0 0 0-1.52-3.07 5 5 0 0 0-6.47 0z"
      />
      <path
        class="e"
        d="M1467.28,248.45a5.81,5.81,0,0,1,1.64,4.51v9h-2.55v-2a4.23,4.23,0,0,1-1.91,1.58,7.45,7.45,0,0,1-3,.54,6.2,6.2,0,0,1-4-1.2,4.13,4.13,0,0,1-.07-6.31,7,7,0,0,1,4.54-1.19h4.25v-.54a3.42,3.42,0,0,0-1-2.66,4.27,4.27,0,0,0-3-.92,7.86,7.86,0,0,0-2.58.44,6.4,6.4,0,0,0-2.13,1.16l-1.12-2a8,8,0,0,1,2.75-1.41,11.34,11.34,0,0,1,3.39-.49A6.68,6.68,0,0,1,1467.28,248.45Zm-2.67,10.88a3.89,3.89,0,0,0,1.62-1.95v-2.07h-4.14c-2.28,0-3.42.76-3.42,2.3a2.08,2.08,0,0,0,.87,1.76,4,4,0,0,0,2.41.64A5.06,5.06,0,0,0,1464.61,259.33Z"
      />
      <path
        class="e"
        d="M1488,241.14v20.78h-2.58v-2.35a5.81,5.81,0,0,1-2.27,1.87,7,7,0,0,1-3,.64,7.7,7.7,0,0,1-3.86-.95,6.81,6.81,0,0,1-2.68-2.67,8,8,0,0,1-1-4,7.91,7.91,0,0,1,1-3.95,6.85,6.85,0,0,1,2.68-2.66,7.7,7.7,0,0,1,3.86-.95,7.16,7.16,0,0,1,2.94.6,5.64,5.64,0,0,1,2.24,1.78v-8.18Zm-5.1,17.94a4.62,4.62,0,0,0,1.79-1.85,6.06,6.06,0,0,0,0-5.46,4.47,4.47,0,0,0-1.79-1.84,5.42,5.42,0,0,0-5.11,0,4.66,4.66,0,0,0-1.79,1.84,6,6,0,0,0,0,5.46,4.83,4.83,0,0,0,1.79,1.85,5.35,5.35,0,0,0,5.11,0Z"
      />
      <path
        class="e"
        d="m1506.4 255.39h-12a4.65 4.65 0 0 0 1.72 3.15 5.59 5.59 0 0 0 3.66 1.19 5.48 5.48 0 0 0 4.28-1.79l1.48 1.74a6.48 6.48 0 0 1-2.5 1.79 9.52 9.52 0 0 1-7.52-0.35 7 7 0 0 1-2.81-2.7 8.23 8.23 0 0 1 0-7.81 6.93 6.93 0 0 1 2.66-2.71 7.61 7.61 0 0 1 3.82-1 7.44 7.44 0 0 1 3.79 1 6.88 6.88 0 0 1 2.61 2.71 8.26 8.26 0 0 1 0.94 4 7 7 0 0 1-0.13 0.78zm-10.51-5a4.75 4.75 0 0 0-1.53 3.08h9.52a4.8 4.8 0 0 0-1.53-3.07 5 5 0 0 0-6.46 0z"
      />
      <path
        class="e"
        d="m1514.9 247.61a7.46 7.46 0 0 1 3.33-0.67v2.6a4.88 4.88 0 0 0-0.62 0 4.5 4.5 0 0 0-3.42 1.31 5.1 5.1 0 0 0-1.25 3.71v7.39h-2.69v-14.87h2.58v2.49a4.53 4.53 0 0 1 2.07-1.96z"
      />
      <path
        class="e"
        d="M1522.37,261.59a7.67,7.67,0,0,1-2.63-1.24l1.12-2.13a8,8,0,0,0,2.38,1.12,9.8,9.8,0,0,0,2.83.42c2.35,0,3.53-.67,3.53-2a1.31,1.31,0,0,0-.48-1.07,3,3,0,0,0-1.19-.57c-.48-.12-1.15-.26-2-.41a21.13,21.13,0,0,1-2.93-.64,4.67,4.67,0,0,1-1.94-1.23,3.46,3.46,0,0,1-.81-2.44,3.79,3.79,0,0,1,1.68-3.23,7.45,7.45,0,0,1,4.5-1.22,12.57,12.57,0,0,1,3,.36,8,8,0,0,1,2.44,1l-1.15,2.13a7.66,7.66,0,0,0-4.28-1.18,5,5,0,0,0-2.61.56,1.69,1.69,0,0,0-.89,1.49,1.39,1.39,0,0,0,.5,1.14,3.06,3.06,0,0,0,1.25.62c.49.13,1.19.28,2.08.45a20.67,20.67,0,0,1,2.9.66,4.27,4.27,0,0,1,1.89,1.19,3.32,3.32,0,0,1,.78,2.35,3.69,3.69,0,0,1-1.72,3.2,8.08,8.08,0,0,1-4.69,1.19A12.33,12.33,0,0,1,1522.37,261.59Z"
      />
      <path
        class="e"
        d="m1548.1 248.58a6.44 6.44 0 0 1 1.67 4.8v8.54h-2.73v-8.24a4.55 4.55 0 0 0-1-3.24 3.89 3.89 0 0 0-3-1.1 4.63 4.63 0 0 0-3.45 1.28 5 5 0 0 0-1.26 3.65v7.65h-2.69v-20.78h2.69v8a5.49 5.49 0 0 1 2.2-1.65 7.43 7.43 0 0 1 3-0.59 6.1 6.1 0 0 1 4.57 1.68z"
      />
      <path
        class="e"
        d="m1554.8 243.72a1.68 1.68 0 0 1-0.51-1.23 1.71 1.71 0 0 1 0.51-1.25 1.76 1.76 0 0 1 1.31-0.52 1.81 1.81 0 0 1 1.3 0.49 1.63 1.63 0 0 1 0.52 1.22 1.78 1.78 0 0 1-1.82 1.79 1.83 1.83 0 0 1-1.31-0.5zm0 3.36h2.69v14.84h-2.69z"
      />
      <path
        class="e"
        d="M1574.26,247.89a6.83,6.83,0,0,1,2.67,2.66,7.92,7.92,0,0,1,1,3.95,8,8,0,0,1-1,4,6.79,6.79,0,0,1-2.67,2.67,7.76,7.76,0,0,1-3.87.95,6.85,6.85,0,0,1-2.92-.61,6.14,6.14,0,0,1-2.26-1.79v7.67h-2.69V247.08h2.58v2.35a5.7,5.7,0,0,1,2.27-1.86,7.13,7.13,0,0,1,3-.63A7.76,7.76,0,0,1,1574.26,247.89Zm-1.53,11.19a4.75,4.75,0,0,0,1.79-1.85,6,6,0,0,0,0-5.46,4.6,4.6,0,0,0-1.79-1.84,5.09,5.09,0,0,0-2.56-.64,5,5,0,0,0-2.54.66,4.84,4.84,0,0,0-1.79,1.83,5.48,5.48,0,0,0-.66,2.72,5.68,5.68,0,0,0,.65,2.73,4.62,4.62,0,0,0,1.79,1.85,5.35,5.35,0,0,0,5.11,0Z"
      />
    </g>
    <rect class="d fourth" x="1628" y="212" width="158" height="80" />
    <g class="a">
      <path
        class="e"
        d="m1675.4 244.75v6.83h9.83v2.42h-9.83v7.93h-2.8v-19.6h13.83v2.43z"
      />
      <path
        class="e"
        d="m1701.9 257h-10.41l-2.16 4.9h-2.88l8.87-19.6h2.77l8.91 19.6h-2.94zm-1-2.24-4.23-9.61-4.23 9.61z"
      />
      <path
        class="e"
        d="M1729.86,263.71a6.47,6.47,0,0,1-2.21,1.71,6.39,6.39,0,0,1-2.72.58,7.07,7.07,0,0,1-3.46-.88,16.44,16.44,0,0,1-3.68-3,10.47,10.47,0,0,1-5-1.48,9.66,9.66,0,0,1-3.47-3.57,10.37,10.37,0,0,1,.11-10.07,9.66,9.66,0,0,1,3.74-3.6,11.6,11.6,0,0,1,10.67,0,9.59,9.59,0,0,1,3.72,3.59,9.89,9.89,0,0,1,1.35,5.14,10.15,10.15,0,0,1-1,4.42,9.55,9.55,0,0,1-2.72,3.39,10.13,10.13,0,0,1-4,1.9,7.12,7.12,0,0,0,1.89,1.5,4,4,0,0,0,1.86.46,4.67,4.67,0,0,0,3.58-1.68Zm-18-7.74a7,7,0,0,0,2.74,2.7,7.91,7.91,0,0,0,3.91,1,7.75,7.75,0,0,0,3.86-1,7.08,7.08,0,0,0,2.73-2.7,7.91,7.91,0,0,0,0-7.7,7.08,7.08,0,0,0-2.73-2.7,7.65,7.65,0,0,0-3.86-1,7.81,7.81,0,0,0-3.91,1,7,7,0,0,0-2.74,2.7,7.91,7.91,0,0,0,0,7.7Z"
      />
      <path
        class="e"
        d="M1733.55,261.59a7.58,7.58,0,0,1-2.63-1.24l1.12-2.13a8,8,0,0,0,2.38,1.12,9.8,9.8,0,0,0,2.83.42c2.35,0,3.53-.67,3.53-2a1.31,1.31,0,0,0-.48-1.07,3,3,0,0,0-1.19-.57c-.48-.12-1.15-.26-2-.41a21.13,21.13,0,0,1-2.93-.64,4.67,4.67,0,0,1-1.94-1.23,3.46,3.46,0,0,1-.81-2.44,3.79,3.79,0,0,1,1.68-3.23,7.45,7.45,0,0,1,4.5-1.22,12.57,12.57,0,0,1,3,.36,8,8,0,0,1,2.44,1l-1.15,2.13a7.66,7.66,0,0,0-4.28-1.18,5,5,0,0,0-2.61.56,1.71,1.71,0,0,0-.89,1.49,1.39,1.39,0,0,0,.5,1.14,3.06,3.06,0,0,0,1.25.62c.49.13,1.19.28,2.08.45a20.67,20.67,0,0,1,2.9.66,4.27,4.27,0,0,1,1.89,1.19,3.32,3.32,0,0,1,.78,2.35,3.69,3.69,0,0,1-1.72,3.2,8.08,8.08,0,0,1-4.69,1.19A12.33,12.33,0,0,1,1733.55,261.59Z"
      />
    </g>
    <rect class="d fifth" x="66" y="117" width="271" height="80" />
    <g class="a">
      <path
        class="e"
        d="m128.74 166.92v-14.28l-7.08 11.9h-1.29l-7.08-11.82v14.2h-2.69v-19.6h2.29l8.11 13.77 8.06-13.77h2.3v19.6z"
      />
      <path
        class="e"
        d="M137,148.72a1.65,1.65,0,0,1-.52-1.23,1.68,1.68,0,0,1,.52-1.25,1.74,1.74,0,0,1,1.3-.52,1.8,1.8,0,0,1,1.3.49,1.6,1.6,0,0,1,.52,1.22,1.7,1.7,0,0,1-.52,1.27,1.74,1.74,0,0,1-1.3.52A1.8,1.8,0,0,1,137,148.72Zm0,3.36h2.68v14.84h-2.68Z"
      />
      <path
        class="e"
        d="M147.15,166.11a7.27,7.27,0,0,1-2.79-2.71,8.05,8.05,0,0,1,0-7.81,7.15,7.15,0,0,1,2.79-2.69,8.36,8.36,0,0,1,4-1,7.75,7.75,0,0,1,3.59.81,5.73,5.73,0,0,1,2.45,2.35l-2,1.32a4.66,4.66,0,0,0-1.74-1.6,5,5,0,0,0-2.29-.53,5.3,5.3,0,0,0-2.62.64,4.54,4.54,0,0,0-1.82,1.84,6,6,0,0,0,0,5.48,4.54,4.54,0,0,0,1.82,1.84,5.3,5.3,0,0,0,2.62.64,4.86,4.86,0,0,0,2.29-.53,4.63,4.63,0,0,0,1.74-1.59l2,1.28a5.83,5.83,0,0,1-2.45,2.37,7.63,7.63,0,0,1-3.59.82A8.25,8.25,0,0,1,147.15,166.11Z"
      />
      <path
        class="e"
        d="m165.36 152.61a7.54 7.54 0 0 1 3.34-0.67v2.6a5 5 0 0 0-0.62 0 4.51 4.51 0 0 0-3.43 1.31 5.14 5.14 0 0 0-1.25 3.71v7.39h-2.68v-14.87h2.57v2.49a4.63 4.63 0 0 1 2.07-1.96z"
      />
      <path
        class="e"
        d="m174.24 166.11a7.12 7.12 0 0 1-2.76-2.71 8.2 8.2 0 0 1 0-7.81 7 7 0 0 1 2.76-2.69 8.69 8.69 0 0 1 7.94 0 7.08 7.08 0 0 1 2.74 2.69 8.12 8.12 0 0 1 0 7.81 7.2 7.2 0 0 1-2.74 2.71 8.61 8.61 0 0 1-7.94 0zm6.54-2a4.73 4.73 0 0 0 1.78-1.85 6.14 6.14 0 0 0 0-5.46 4.57 4.57 0 0 0-1.78-1.84 5.46 5.46 0 0 0-5.13 0 4.66 4.66 0 0 0-1.79 1.84 6 6 0 0 0 0 5.46 4.83 4.83 0 0 0 1.79 1.85 5.39 5.39 0 0 0 5.13 0z"
      />
      <path
        class="e"
        d="M192.06,166.11a7.34,7.34,0,0,1-2.79-2.71,8.12,8.12,0,0,1,0-7.81,7.21,7.21,0,0,1,2.79-2.69,8.39,8.39,0,0,1,4.05-1,7.75,7.75,0,0,1,3.59.81,5.67,5.67,0,0,1,2.45,2.35l-2,1.32a4.63,4.63,0,0,0-1.73-1.6,5.05,5.05,0,0,0-2.3-.53,5.32,5.32,0,0,0-2.62.64,4.6,4.6,0,0,0-1.82,1.84,6,6,0,0,0,0,5.48,4.6,4.6,0,0,0,1.82,1.84,5.32,5.32,0,0,0,2.62.64,4.93,4.93,0,0,0,2.3-.53,4.6,4.6,0,0,0,1.73-1.59l2,1.28a5.78,5.78,0,0,1-2.45,2.37,7.63,7.63,0,0,1-3.59.82A8.28,8.28,0,0,1,192.06,166.11Z"
      />
      <path
        class="e"
        d="m207.78 166.11a7.26 7.26 0 0 1-2.78-2.71 8.2 8.2 0 0 1 0-7.81 7.14 7.14 0 0 1 2.76-2.69 8.69 8.69 0 0 1 7.94 0 7 7 0 0 1 2.74 2.69 8.12 8.12 0 0 1 0 7.81 7.13 7.13 0 0 1-2.74 2.71 8.61 8.61 0 0 1-7.94 0zm6.54-2a4.66 4.66 0 0 0 1.78-1.85 6.14 6.14 0 0 0 0-5.46 4.51 4.51 0 0 0-1.78-1.84 5.44 5.44 0 0 0-5.12 0 4.62 4.62 0 0 0-1.8 1.84 6.06 6.06 0 0 0 0 5.46 4.78 4.78 0 0 0 1.8 1.85 5.37 5.37 0 0 0 5.12 0z"
      />
      <path
        class="e"
        d="m235.6 153.58a6.44 6.44 0 0 1 1.67 4.8v8.54h-2.69v-8.24a4.55 4.55 0 0 0-1-3.24 3.89 3.89 0 0 0-3-1.1 4.63 4.63 0 0 0-3.44 1.28 5 5 0 0 0-1.26 3.65v7.65h-2.69v-14.84h2.58v2.24a5.16 5.16 0 0 1 2.2-1.77 7.66 7.66 0 0 1 3.15-0.61 6.1 6.1 0 0 1 4.48 1.64z"
      />
      <path
        class="e"
        d="M250.6,166.05a4.23,4.23,0,0,1-1.46.77,6,6,0,0,1-1.79.26,4.75,4.75,0,0,1-3.47-1.2,4.56,4.56,0,0,1-1.24-3.44v-8.15h-2.52v-2.21h2.52v-3.25h2.69v3.25h4.26v2.21h-4.26v8a2.6,2.6,0,0,0,.6,1.84,2.25,2.25,0,0,0,1.73.65,3.23,3.23,0,0,0,2.09-.7Z"
      />
      <path
        class="e"
        d="m266.69 160.39h-12a4.66 4.66 0 0 0 1.73 3.15 5.58 5.58 0 0 0 3.65 1.19 5.48 5.48 0 0 0 4.28-1.79l1.49 1.74a6.52 6.52 0 0 1-2.51 1.79 8.72 8.72 0 0 1-3.34 0.61 8.85 8.85 0 0 1-4.18-1 7 7 0 0 1-2.81-2.7 8.31 8.31 0 0 1 0-7.81 7 7 0 0 1 2.66-2.71 7.64 7.64 0 0 1 3.83-1 7.47 7.47 0 0 1 3.79 1 6.79 6.79 0 0 1 2.6 2.71 8.15 8.15 0 0 1 0.94 4 7 7 0 0 1-0.13 0.82zm-10.51-5a4.75 4.75 0 0 0-1.53 3.08h9.52a4.74 4.74 0 0 0-1.52-3.07 5 5 0 0 0-6.47 0z"
      />
      <path
        class="e"
        d="m282.89 153.58a6.44 6.44 0 0 1 1.67 4.8v8.54h-2.69v-8.24a4.55 4.55 0 0 0-1-3.24 3.89 3.89 0 0 0-3-1.1 4.63 4.63 0 0 0-3.45 1.28 5 5 0 0 0-1.26 3.65v7.65h-2.69v-14.84h2.58v2.24a5.16 5.16 0 0 1 2.2-1.77 7.66 7.66 0 0 1 3.15-0.61 6.1 6.1 0 0 1 4.49 1.64z"
      />
      <path
        class="e"
        d="M297.89,166.05a4.14,4.14,0,0,1-1.46.77,6,6,0,0,1-1.79.26,4.77,4.77,0,0,1-3.47-1.2,4.59,4.59,0,0,1-1.23-3.44v-8.15h-2.52v-2.21h2.52v-3.25h2.68v3.25h4.26v2.21h-4.26v8a2.56,2.56,0,0,0,.61,1.84,2.23,2.23,0,0,0,1.72.65,3.28,3.28,0,0,0,2.1-.7Z"
      />
    </g>
    <rect class="d" x="352" y="117" width="129" height="80" />
    <g class="a">
      <path
        class="e"
        d="M390,166.46a8.88,8.88,0,0,1-3.24-1.78l1-2.19a8.45,8.45,0,0,0,2.87,1.64,10.58,10.58,0,0,0,3.6.63,6.4,6.4,0,0,0,3.64-.83,2.54,2.54,0,0,0,1.2-2.19,2.17,2.17,0,0,0-.66-1.64,4.43,4.43,0,0,0-1.62-1q-1-.33-2.73-.75a28.49,28.49,0,0,1-3.57-1.07,5.83,5.83,0,0,1-2.32-1.64,4.33,4.33,0,0,1-1-3,5,5,0,0,1,.83-2.82,5.63,5.63,0,0,1,2.5-2,10.06,10.06,0,0,1,4.17-.76,13.16,13.16,0,0,1,3.42.45,9.23,9.23,0,0,1,2.89,1.29l-.93,2.24a10.22,10.22,0,0,0-2.63-1.19,9.86,9.86,0,0,0-2.75-.41,6,6,0,0,0-3.56.87,2.65,2.65,0,0,0-1.19,2.24,2.14,2.14,0,0,0,.67,1.64,4.54,4.54,0,0,0,1.66,1,27.33,27.33,0,0,0,2.71.74,29.47,29.47,0,0,1,3.55,1.06,6,6,0,0,1,2.31,1.63,4.31,4.31,0,0,1,1,2.94,5,5,0,0,1-.84,2.81,5.66,5.66,0,0,1-2.55,2,10.63,10.63,0,0,1-4.2.74A13.58,13.58,0,0,1,390,166.46Z"
      />
      <path
        class="e"
        d="m424.13 166.92v-14.28l-7.13 11.9h-1.28l-7.09-11.82v14.2h-2.63v-19.6h2.3l8.18 13.77 8.06-13.77h2.3v19.6z"
      />
      <path
        class="e"
        d="M434.14,166.46a8.79,8.79,0,0,1-3.24-1.78l1-2.19a8.35,8.35,0,0,0,2.87,1.64,10.49,10.49,0,0,0,3.6.63,6.43,6.43,0,0,0,3.64-.83,2.54,2.54,0,0,0,1.2-2.19,2.17,2.17,0,0,0-.66-1.64,4.52,4.52,0,0,0-1.62-1q-1-.33-2.73-.75a27.49,27.49,0,0,1-3.57-1.07,5.86,5.86,0,0,1-2.33-1.64,4.37,4.37,0,0,1-1-3,5,5,0,0,1,.82-2.82,5.66,5.66,0,0,1,2.51-2,10.06,10.06,0,0,1,4.17-.76,13.16,13.16,0,0,1,3.42.45,9.07,9.07,0,0,1,2.88,1.29l-.92,2.24a10.22,10.22,0,0,0-2.63-1.19,9.91,9.91,0,0,0-2.75-.41,6.06,6.06,0,0,0-3.57.87,2.67,2.67,0,0,0-1.19,2.24,2.18,2.18,0,0,0,.67,1.64,4.67,4.67,0,0,0,1.67,1,26.27,26.27,0,0,0,2.7.74,29.15,29.15,0,0,1,3.56,1.06,6.13,6.13,0,0,1,2.31,1.63,4.3,4.3,0,0,1,1,2.94,5,5,0,0,1-.84,2.81,5.69,5.69,0,0,1-2.54,2,10.63,10.63,0,0,1-4.2.74A13.58,13.58,0,0,1,434.14,166.46Z"
      />
    </g>
    <rect class="d second" x="496" y="117" width="171" height="80" />
    <g class="a">
      <path
        class="e"
        d="M549.15,157h2.69v7.65a10.48,10.48,0,0,1-3.39,1.85,12.64,12.64,0,0,1-4,.64,10.92,10.92,0,0,1-5.35-1.3,9.64,9.64,0,0,1-3.74-3.59,9.79,9.79,0,0,1-1.36-5.13,9.92,9.92,0,0,1,1.36-5.16,9.57,9.57,0,0,1,3.75-3.58,11.18,11.18,0,0,1,5.39-1.29,11.43,11.43,0,0,1,4.27.77,8.73,8.73,0,0,1,3.26,2.26l-1.73,1.73a7.9,7.9,0,0,0-5.69-2.27,8.29,8.29,0,0,0-4,1,7.08,7.08,0,0,0-2.79,2.69,7.9,7.9,0,0,0,0,7.73,7.1,7.1,0,0,0,2.79,2.7,8.13,8.13,0,0,0,4,1,8.32,8.32,0,0,0,4.59-1.26Z"
      />
      <path
        class="e"
        d="M566.91,153.45a5.81,5.81,0,0,1,1.64,4.51v9H566v-2a4.23,4.23,0,0,1-1.91,1.58,7.45,7.45,0,0,1-3,.54,6.2,6.2,0,0,1-4-1.2,4.13,4.13,0,0,1-.07-6.31,7,7,0,0,1,4.54-1.19h4.25v-.54a3.45,3.45,0,0,0-1-2.66,4.31,4.31,0,0,0-3-.92,7.86,7.86,0,0,0-2.58.44,6.4,6.4,0,0,0-2.13,1.16l-1.12-2a8,8,0,0,1,2.75-1.41,11.34,11.34,0,0,1,3.39-.49A6.68,6.68,0,0,1,566.91,153.45Zm-2.67,10.88a3.89,3.89,0,0,0,1.62-2v-2.07h-4.14c-2.28,0-3.42.76-3.42,2.3a2.08,2.08,0,0,0,.87,1.76,4,4,0,0,0,2.41.64A5.06,5.06,0,0,0,564.24,164.33Z"
      />
      <path
        class="e"
        d="m596.54 153.56a6.51 6.51 0 0 1 1.64 4.82v8.54h-2.69v-8.24a4.64 4.64 0 0 0-1-3.24 3.64 3.64 0 0 0-2.84-1.1 4.28 4.28 0 0 0-3.25 1.28 5.13 5.13 0 0 0-1.2 3.65v7.65h-2.69v-8.24a4.69 4.69 0 0 0-1-3.24 3.65 3.65 0 0 0-2.84-1.1 4.25 4.25 0 0 0-3.25 1.28 5.09 5.09 0 0 0-1.21 3.65v7.65h-2.68v-14.84h2.57v2.21a5.14 5.14 0 0 1 2.13-1.75 7.17 7.17 0 0 1 3-0.6 6.56 6.56 0 0 1 3.08 0.7 4.91 4.91 0 0 1 2.07 2 5.62 5.62 0 0 1 2.34-2 7.52 7.52 0 0 1 3.37-0.73 6 6 0 0 1 4.45 1.65z"
      />
      <path
        class="e"
        d="m616.57 160.39h-12a4.65 4.65 0 0 0 1.72 3.15 5.59 5.59 0 0 0 3.66 1.19 5.48 5.48 0 0 0 4.28-1.79l1.48 1.74a6.48 6.48 0 0 1-2.5 1.79 9.52 9.52 0 0 1-7.52-0.35 7 7 0 0 1-2.81-2.7 7.7 7.7 0 0 1-1-3.92 7.88 7.88 0 0 1 1-3.89 6.93 6.93 0 0 1 2.66-2.71 7.61 7.61 0 0 1 3.82-1 7.44 7.44 0 0 1 3.79 1 6.81 6.81 0 0 1 2.61 2.71 8.26 8.26 0 0 1 0.94 4 7 7 0 0 1-0.13 0.78zm-10.51-5a4.75 4.75 0 0 0-1.53 3.08h9.52a4.8 4.8 0 0 0-1.53-3.07 5 5 0 0 0-6.46 0z"
      />
      <path
        class="e"
        d="M621.11,166.59a7.73,7.73,0,0,1-2.64-1.24l1.12-2.13a8.08,8.08,0,0,0,2.38,1.12,9.85,9.85,0,0,0,2.83.42c2.35,0,3.53-.67,3.53-2a1.31,1.31,0,0,0-.48-1.07,2.94,2.94,0,0,0-1.19-.57c-.47-.12-1.15-.26-2-.41a20.62,20.62,0,0,1-2.92-.64,4.64,4.64,0,0,1-2-1.23,3.46,3.46,0,0,1-.81-2.44,3.79,3.79,0,0,1,1.68-3.23,7.47,7.47,0,0,1,4.51-1.22,12.57,12.57,0,0,1,3,.36,7.84,7.84,0,0,1,2.43,1l-1.15,2.13a7.66,7.66,0,0,0-4.28-1.18,5,5,0,0,0-2.6.56,1.7,1.7,0,0,0-.9,1.49,1.42,1.42,0,0,0,.5,1.14,3.13,3.13,0,0,0,1.25.62c.49.13,1.19.28,2.09.45a20.49,20.49,0,0,1,2.89.66,4.27,4.27,0,0,1,1.89,1.19,3.28,3.28,0,0,1,.79,2.35,3.72,3.72,0,0,1-1.72,3.2,8.1,8.1,0,0,1-4.69,1.19A12.28,12.28,0,0,1,621.11,166.59Z"
      />
    </g>

    <rect class="d third" x="682" y="117" width="306" height="80" />
    <g class="a">
      <path
        class="e"
        d="m740.2 149.14a6.83 6.83 0 0 1 0 10 9 9 0 0 1-6 1.82h-4.84v5.94h-2.8v-19.6h7.64a9 9 0 0 1 6 1.84zm-2 8.27a4.5 4.5 0 0 0 0-6.52 6.31 6.31 0 0 0-4.06-1.14h-4.76v8.79h4.76a6.31 6.31 0 0 0 4.05-1.13z"
      />
      <path
        class="e"
        d="m750.51 152.61a7.46 7.46 0 0 1 3.33-0.67v2.6a5 5 0 0 0-0.62 0 4.53 4.53 0 0 0-3.43 1.31 5.13 5.13 0 0 0-1.24 3.71v7.39h-2.69v-14.87h2.58v2.49a4.53 4.53 0 0 1 2.07-1.96z"
      />
      <path
        class="e"
        d="m759.38 166.11a7.16 7.16 0 0 1-2.75-2.71 8.12 8.12 0 0 1 0-7.81 7 7 0 0 1 2.75-2.69 8.69 8.69 0 0 1 7.94 0 7.11 7.11 0 0 1 2.75 2.69 8.2 8.2 0 0 1 0 7.81 7.23 7.23 0 0 1-2.75 2.71 8.61 8.61 0 0 1-7.94 0zm6.54-2a4.66 4.66 0 0 0 1.78-1.85 6.14 6.14 0 0 0 0-5.46 4.51 4.51 0 0 0-1.78-1.84 5.44 5.44 0 0 0-5.12 0 4.53 4.53 0 0 0-1.79 1.84 6 6 0 0 0 0 5.46 4.69 4.69 0 0 0 1.79 1.85 5.37 5.37 0 0 0 5.12 0z"
      />
      <path
        class="e"
        d="M788.78,146.14v20.78h-2.57v-2.35a5.88,5.88,0,0,1-2.27,1.87,7,7,0,0,1-3,.64,7.76,7.76,0,0,1-3.87-1,6.72,6.72,0,0,1-2.67-2.67,7.91,7.91,0,0,1-1-4,7.81,7.81,0,0,1,1-3.95,6.76,6.76,0,0,1,2.67-2.66,7.76,7.76,0,0,1,3.87-.95,7.16,7.16,0,0,1,2.94.6,5.71,5.71,0,0,1,2.24,1.78v-8.18Zm-5.09,17.94a4.69,4.69,0,0,0,1.79-1.85,6.14,6.14,0,0,0,0-5.46,4.53,4.53,0,0,0-1.79-1.84,5.14,5.14,0,0,0-2.55-.64,5.07,5.07,0,0,0-2.56.64,4.53,4.53,0,0,0-1.79,1.84,6,6,0,0,0,0,5.46,4.69,4.69,0,0,0,1.79,1.85,5.07,5.07,0,0,0,2.56.65A5.14,5.14,0,0,0,783.69,164.08Z"
      />
      <path
        class="e"
        d="M807.71,152.08v14.84h-2.55v-2.24a5.41,5.41,0,0,1-2.14,1.78,6.68,6.68,0,0,1-2.9.62,6.46,6.46,0,0,1-4.67-1.63,6.38,6.38,0,0,1-1.71-4.83v-8.54h2.69v8.23a4.6,4.6,0,0,0,1,3.26,3.86,3.86,0,0,0,3,1.11,4.42,4.42,0,0,0,3.36-1.29,5.06,5.06,0,0,0,1.23-3.64v-7.67Z"
      />
      <path
        class="e"
        d="M815.26,166.11a7.2,7.2,0,0,1-2.79-2.71,8.05,8.05,0,0,1,0-7.81,7.08,7.08,0,0,1,2.79-2.69,8.35,8.35,0,0,1,4-1,7.76,7.76,0,0,1,3.6.81,5.73,5.73,0,0,1,2.45,2.35l-2,1.32a4.66,4.66,0,0,0-1.74-1.6,5,5,0,0,0-2.3-.53,5.26,5.26,0,0,0-2.61.64,4.48,4.48,0,0,0-1.82,1.84,6,6,0,0,0,0,5.48,4.48,4.48,0,0,0,1.82,1.84,5.26,5.26,0,0,0,2.61.64,4.9,4.9,0,0,0,2.3-.53,4.63,4.63,0,0,0,1.74-1.59l2,1.28a5.83,5.83,0,0,1-2.45,2.37,7.64,7.64,0,0,1-3.6.82A8.24,8.24,0,0,1,815.26,166.11Z"
      />
      <path
        class="e"
        d="M837.36,166.05a4.18,4.18,0,0,1-1.45.77,6,6,0,0,1-1.8.26,4.77,4.77,0,0,1-3.47-1.2,4.59,4.59,0,0,1-1.23-3.44v-8.15h-2.52v-2.21h2.52v-3.25h2.69v3.25h4.25v2.21H832.1v8a2.6,2.6,0,0,0,.6,1.84,2.25,2.25,0,0,0,1.72.65,3.28,3.28,0,0,0,2.1-.7Z"
      />
      <path
        class="e"
        d="M848.53,147.32h8.26a12,12,0,0,1,5.52,1.23,9,9,0,0,1,3.74,3.46,10.48,10.48,0,0,1,0,10.22,9,9,0,0,1-3.74,3.45,11.84,11.84,0,0,1-5.52,1.24h-8.26Zm8.1,17.16a9.13,9.13,0,0,0,4.18-.92A6.72,6.72,0,0,0,863.6,161a8.05,8.05,0,0,0,0-7.7,6.66,6.66,0,0,0-2.79-2.59,9,9,0,0,0-4.18-.93h-5.3v14.73Z"
      />
      <path
        class="e"
        d="m884.63 160.39h-12a4.61 4.61 0 0 0 1.72 3.15 5.58 5.58 0 0 0 3.65 1.19 5.51 5.51 0 0 0 4.29-1.79l1.48 1.74a6.52 6.52 0 0 1-2.51 1.79 8.68 8.68 0 0 1-3.34 0.61 8.79 8.79 0 0 1-4.17-1 6.93 6.93 0 0 1-2.82-2.7 8.31 8.31 0 0 1 0-7.81 7 7 0 0 1 2.66-2.71 7.64 7.64 0 0 1 3.83-1 7.47 7.47 0 0 1 3.79 1 6.79 6.79 0 0 1 2.6 2.71 8.15 8.15 0 0 1 0.94 4c-0.07 0.22-0.09 0.49-0.12 0.82zm-10.52-5a4.7 4.7 0 0 0-1.52 3.08h9.52a4.8 4.8 0 0 0-1.53-3.07 5 5 0 0 0-6.47 0z"
      />
      <path
        class="e"
        d="m911.41 153.56a6.51 6.51 0 0 1 1.64 4.82v8.54h-2.69v-8.24a4.64 4.64 0 0 0-1-3.24 3.64 3.64 0 0 0-2.84-1.1 4.28 4.28 0 0 0-3.25 1.28 5.13 5.13 0 0 0-1.2 3.65v7.65h-2.69v-8.24a4.69 4.69 0 0 0-1-3.24 3.65 3.65 0 0 0-2.84-1.1 4.25 4.25 0 0 0-3.25 1.28 5.09 5.09 0 0 0-1.21 3.65v7.65h-2.68v-14.84h2.6v2.21a5.14 5.14 0 0 1 2.13-1.75 7.17 7.17 0 0 1 3-0.6 6.56 6.56 0 0 1 3.08 0.7 4.91 4.91 0 0 1 2.07 2 5.62 5.62 0 0 1 2.34-2 7.52 7.52 0 0 1 3.37-0.73 6 6 0 0 1 4.42 1.65z"
      />
      <path
        class="e"
        d="m920.46 166.11a7.16 7.16 0 0 1-2.75-2.71 8.12 8.12 0 0 1 0-7.81 7 7 0 0 1 2.75-2.69 8.22 8.22 0 0 1 4-1 8.06 8.06 0 0 1 4 1 7.11 7.11 0 0 1 2.75 2.69 8.2 8.2 0 0 1 0 7.81 7.23 7.23 0 0 1-2.75 2.71 8 8 0 0 1-4 1 8.12 8.12 0 0 1-4-1zm6.54-2a4.66 4.66 0 0 0 1.78-1.85 6.14 6.14 0 0 0 0-5.46 4.51 4.51 0 0 0-1.78-1.84 5.44 5.44 0 0 0-5.12 0 4.6 4.6 0 0 0-1.79 1.84 6 6 0 0 0 0 5.46 4.75 4.75 0 0 0 1.79 1.85 5.37 5.37 0 0 0 5.12 0z"
      />
      <path
        class="e"
        d="M936.62,166.59a7.58,7.58,0,0,1-2.63-1.24l1.12-2.13a8,8,0,0,0,2.38,1.12,9.8,9.8,0,0,0,2.83.42c2.35,0,3.53-.67,3.53-2a1.31,1.31,0,0,0-.48-1.07,3,3,0,0,0-1.19-.57c-.48-.12-1.15-.26-2-.41a20.8,20.8,0,0,1-2.93-.64,4.67,4.67,0,0,1-1.94-1.23,3.46,3.46,0,0,1-.81-2.44,3.79,3.79,0,0,1,1.68-3.23,7.45,7.45,0,0,1,4.5-1.22,12.57,12.57,0,0,1,3,.36,8,8,0,0,1,2.44,1l-1.15,2.13a7.67,7.67,0,0,0-4.29-1.18,5,5,0,0,0-2.6.56,1.7,1.7,0,0,0-.9,1.49,1.4,1.4,0,0,0,.51,1.14,3.06,3.06,0,0,0,1.25.62c.49.13,1.19.28,2.08.45a20.67,20.67,0,0,1,2.9.66,4.27,4.27,0,0,1,1.89,1.19,3.32,3.32,0,0,1,.78,2.35,3.69,3.69,0,0,1-1.72,3.2,8.08,8.08,0,0,1-4.69,1.19A12.33,12.33,0,0,1,936.62,166.59Z"
      />
    </g>
    <rect class="d fourth" x="1003" y="117" width="256" height="80" />
    <g class="a">
      <path
        class="e"
        d="M1048,165.84a9.49,9.49,0,0,1-3.71-3.59,10.44,10.44,0,0,1,0-10.27,9.52,9.52,0,0,1,3.72-3.59,11,11,0,0,1,5.32-1.3,11.17,11.17,0,0,1,4.2.77,8.79,8.79,0,0,1,3.25,2.28l-1.82,1.77a7.33,7.33,0,0,0-5.52-2.33,8,8,0,0,0-4,1,7.07,7.07,0,0,0-2.75,2.7,7.91,7.91,0,0,0,0,7.7,7.07,7.07,0,0,0,2.75,2.7,8.06,8.06,0,0,0,4,1,7.32,7.32,0,0,0,5.52-2.35l1.82,1.76a8.88,8.88,0,0,1-3.26,2.3,11.12,11.12,0,0,1-4.22.78A10.75,10.75,0,0,1,1048,165.84Z"
      />
      <path
        class="e"
        d="M1074.35,153.45A5.85,5.85,0,0,1,1076,158v9h-2.54v-2a4.31,4.31,0,0,1-1.92,1.58,7.42,7.42,0,0,1-3,.54,6.2,6.2,0,0,1-4-1.2,4.15,4.15,0,0,1-.07-6.31,7,7,0,0,1,4.54-1.19h4.26v-.54a3.45,3.45,0,0,0-1-2.66,4.28,4.28,0,0,0-3-.92,7.86,7.86,0,0,0-2.58.44,6.25,6.25,0,0,0-2.12,1.16l-1.12-2a7.9,7.9,0,0,1,2.74-1.41,11.34,11.34,0,0,1,3.39-.49A6.73,6.73,0,0,1,1074.35,153.45Zm-2.68,10.88a4,4,0,0,0,1.63-2v-2.07h-4.15c-2.28,0-3.41.76-3.41,2.3a2.09,2.09,0,0,0,.86,1.76,4,4,0,0,0,2.41.64A5.06,5.06,0,0,0,1071.67,164.33Z"
      />
      <path
        class="e"
        d="M1081.72,166.59a7.67,7.67,0,0,1-2.63-1.24l1.12-2.13a8.08,8.08,0,0,0,2.38,1.12,9.85,9.85,0,0,0,2.83.42c2.35,0,3.53-.67,3.53-2a1.31,1.31,0,0,0-.48-1.07,3,3,0,0,0-1.19-.57c-.47-.12-1.15-.26-2-.41a20.62,20.62,0,0,1-2.92-.64,4.64,4.64,0,0,1-1.95-1.23,3.46,3.46,0,0,1-.81-2.44,3.79,3.79,0,0,1,1.68-3.23,7.47,7.47,0,0,1,4.51-1.22,12.48,12.48,0,0,1,3,.36,7.9,7.9,0,0,1,2.44,1l-1.15,2.13a7.66,7.66,0,0,0-4.28-1.18,5,5,0,0,0-2.61.56,1.71,1.71,0,0,0-.89,1.49,1.42,1.42,0,0,0,.5,1.14,3.06,3.06,0,0,0,1.25.62c.49.13,1.19.28,2.08.45a20.67,20.67,0,0,1,2.9.66,4.27,4.27,0,0,1,1.89,1.19,3.28,3.28,0,0,1,.79,2.35,3.7,3.7,0,0,1-1.73,3.2,8.06,8.06,0,0,1-4.69,1.19A12.33,12.33,0,0,1,1081.72,166.59Z"
      />
      <path
        class="e"
        d="m1108.4 160.39h-12a4.65 4.65 0 0 0 1.72 3.15 5.59 5.59 0 0 0 3.66 1.19 5.48 5.48 0 0 0 4.28-1.79l1.49 1.74a6.6 6.6 0 0 1-2.51 1.79 8.73 8.73 0 0 1-3.35 0.61 8.84 8.84 0 0 1-4.17-1 7 7 0 0 1-2.81-2.7 8.23 8.23 0 0 1 0-7.81 6.93 6.93 0 0 1 2.66-2.71 7.61 7.61 0 0 1 3.82-1 7.5 7.5 0 0 1 3.8 1 6.85 6.85 0 0 1 2.6 2.71 8.26 8.26 0 0 1 0.94 4 7 7 0 0 1-0.13 0.82zm-10.51-5a4.75 4.75 0 0 0-1.53 3.08h9.52a4.83 4.83 0 0 0-1.52-3.07 5 5 0 0 0-6.47 0z"
      />
      <path
        class="e"
        d="M1121.5,166.46a8.79,8.79,0,0,1-3.24-1.78l1-2.19a8.45,8.45,0,0,0,2.87,1.64,10.58,10.58,0,0,0,3.6.63,6.43,6.43,0,0,0,3.64-.83,2.54,2.54,0,0,0,1.2-2.19,2.17,2.17,0,0,0-.66-1.64,4.52,4.52,0,0,0-1.62-1q-1-.33-2.73-.75a27.49,27.49,0,0,1-3.57-1.07,5.76,5.76,0,0,1-2.32-1.64,4.33,4.33,0,0,1-1-3,5,5,0,0,1,.83-2.82,5.63,5.63,0,0,1,2.5-2,10.06,10.06,0,0,1,4.17-.76,13.16,13.16,0,0,1,3.42.45,9.07,9.07,0,0,1,2.88,1.29l-.92,2.24a10.22,10.22,0,0,0-2.63-1.19,9.91,9.91,0,0,0-2.75-.41,6.06,6.06,0,0,0-3.57.87,2.67,2.67,0,0,0-1.19,2.24,2.15,2.15,0,0,0,.68,1.64,4.54,4.54,0,0,0,1.66,1,26.27,26.27,0,0,0,2.7.74,29.15,29.15,0,0,1,3.56,1.06,6,6,0,0,1,2.31,1.63,4.31,4.31,0,0,1,1,2.94,5,5,0,0,1-.84,2.81,5.66,5.66,0,0,1-2.55,2,10.63,10.63,0,0,1-4.2.74A13.58,13.58,0,0,1,1121.5,166.46Z"
      />
      <path
        class="e"
        d="M1145.4,166.05a4.23,4.23,0,0,1-1.46.77,6,6,0,0,1-1.79.26,4.79,4.79,0,0,1-3.48-1.2,4.59,4.59,0,0,1-1.23-3.44v-8.15h-2.52v-2.21h2.52v-3.25h2.69v3.25h4.26v2.21h-4.26v8a2.6,2.6,0,0,0,.6,1.84,2.25,2.25,0,0,0,1.73.65,3.23,3.23,0,0,0,2.09-.7Z"
      />
      <path
        class="e"
        d="M1162.47,152.08v14.84h-2.54v-2.24a5.48,5.48,0,0,1-2.14,1.78,6.71,6.71,0,0,1-2.9.62,6.49,6.49,0,0,1-4.68-1.63,6.38,6.38,0,0,1-1.71-4.83v-8.54h2.69v8.23a4.61,4.61,0,0,0,1,3.26,3.86,3.86,0,0,0,3,1.11,4.41,4.41,0,0,0,3.35-1.29,5,5,0,0,0,1.24-3.64v-7.67Z"
      />
      <path
        class="e"
        d="M1181.6,146.14v20.78H1179v-2.35a5.81,5.81,0,0,1-2.27,1.87,7,7,0,0,1-3,.64,7.7,7.7,0,0,1-3.86-1,6.81,6.81,0,0,1-2.68-2.67,8,8,0,0,1-1-4,7.91,7.91,0,0,1,1-3.95,6.85,6.85,0,0,1,2.68-2.66,7.7,7.7,0,0,1,3.86-.95,7.16,7.16,0,0,1,2.94.6,5.64,5.64,0,0,1,2.24,1.78v-8.18Zm-5.1,17.94a4.56,4.56,0,0,0,1.79-1.85,6.06,6.06,0,0,0,0-5.46,4.41,4.41,0,0,0-1.79-1.84,5.42,5.42,0,0,0-5.11,0,4.66,4.66,0,0,0-1.79,1.84,6,6,0,0,0,0,5.46,4.83,4.83,0,0,0,1.79,1.85,5.35,5.35,0,0,0,5.11,0Z"
      />
      <path
        class="e"
        d="M1186.74,148.72a1.65,1.65,0,0,1-.52-1.23,1.68,1.68,0,0,1,.52-1.25,1.74,1.74,0,0,1,1.3-.52,1.81,1.81,0,0,1,1.3.49,1.6,1.6,0,0,1,.52,1.22,1.7,1.7,0,0,1-.52,1.27,1.76,1.76,0,0,1-1.3.52A1.8,1.8,0,0,1,1186.74,148.72Zm0,3.36h2.69v14.84h-2.69Z"
      />
      <path
        class="e"
        d="m1207.9 160.39h-12a4.65 4.65 0 0 0 1.72 3.15 5.59 5.59 0 0 0 3.66 1.19 5.48 5.48 0 0 0 4.28-1.79l1.49 1.74a6.6 6.6 0 0 1-2.51 1.79 8.73 8.73 0 0 1-3.35 0.61 8.84 8.84 0 0 1-4.17-1 7 7 0 0 1-2.81-2.7 8.23 8.23 0 0 1 0-7.81 6.93 6.93 0 0 1 2.66-2.71 7.61 7.61 0 0 1 3.82-1 7.48 7.48 0 0 1 3.8 1 6.85 6.85 0 0 1 2.6 2.71 8.15 8.15 0 0 1 0.94 4 7 7 0 0 1-0.13 0.82zm-10.51-5a4.75 4.75 0 0 0-1.53 3.08h9.52a4.83 4.83 0 0 0-1.52-3.07 5 5 0 0 0-6.47 0z"
      />
      <path
        class="e"
        d="M1212.4,166.59a7.58,7.58,0,0,1-2.63-1.24l1.12-2.13a7.84,7.84,0,0,0,2.38,1.12,9.73,9.73,0,0,0,2.82.42c2.36,0,3.53-.67,3.53-2a1.33,1.33,0,0,0-.47-1.07,3.07,3.07,0,0,0-1.19-.57c-.48-.12-1.16-.26-2-.41a20.8,20.8,0,0,1-2.93-.64,4.64,4.64,0,0,1-2-1.23,3.46,3.46,0,0,1-.81-2.44,3.79,3.79,0,0,1,1.68-3.23,7.47,7.47,0,0,1,4.51-1.22,12.57,12.57,0,0,1,3,.36,7.84,7.84,0,0,1,2.43,1l-1.14,2.13a7.71,7.71,0,0,0-4.29-1.18,5,5,0,0,0-2.6.56,1.7,1.7,0,0,0-.9,1.49,1.4,1.4,0,0,0,.51,1.14,3,3,0,0,0,1.24.62c.5.13,1.19.28,2.09.45a21,21,0,0,1,2.9.66,4.38,4.38,0,0,1,1.89,1.19,3.32,3.32,0,0,1,.78,2.35,3.69,3.69,0,0,1-1.72,3.2,8.08,8.08,0,0,1-4.69,1.19A12.28,12.28,0,0,1,1212.4,166.59Z"
      />
    </g>
    <rect class="d fifth" x="1274" y="117" width="159" height="80" />
    <g class="a">
      <path
        class="e"
        d="M1336.76,168.71a6.5,6.5,0,0,1-2.22,1.71,6.34,6.34,0,0,1-2.71.58,7.07,7.07,0,0,1-3.46-.88,16.44,16.44,0,0,1-3.68-3,10.44,10.44,0,0,1-5-1.48,9.66,9.66,0,0,1-3.47-3.57,10.37,10.37,0,0,1,.11-10.07,9.66,9.66,0,0,1,3.74-3.6,11.6,11.6,0,0,1,10.67,0,9.65,9.65,0,0,1,3.72,3.59,9.88,9.88,0,0,1,1.34,5.14,10.14,10.14,0,0,1-1,4.42,9.55,9.55,0,0,1-2.72,3.39,10.1,10.1,0,0,1-4,1.9,7.45,7.45,0,0,0,1.89,1.5,4.07,4.07,0,0,0,1.87.46,4.67,4.67,0,0,0,3.58-1.68Zm-18-7.74a7,7,0,0,0,2.74,2.7,7.91,7.91,0,0,0,3.91,1,7.77,7.77,0,0,0,3.86-1A7.08,7.08,0,0,0,1332,161a8,8,0,0,0,0-7.7,7.08,7.08,0,0,0-2.73-2.7,7.68,7.68,0,0,0-3.86-1,7.81,7.81,0,0,0-3.91,1,7,7,0,0,0-2.74,2.7,7.91,7.91,0,0,0,0,7.7Z"
      />
      <path
        class="e"
        d="m1356 158.47a4.94 4.94 0 0 1 1 3.15 4.54 4.54 0 0 1-1.91 3.92 9.51 9.51 0 0 1-5.59 1.38h-9.35v-19.6h8.79a8.62 8.62 0 0 1 5.19 1.34 4.35 4.35 0 0 1 1.84 3.72 4.79 4.79 0 0 1-0.72 2.66 4.58 4.58 0 0 1-1.91 1.71 5 5 0 0 1 2.66 1.72zm-13.07-8.89v6.28h5.74a5.86 5.86 0 0 0 3.32-0.8 2.94 2.94 0 0 0 0-4.68 5.86 5.86 0 0 0-3.32-0.8zm10 14.29a2.76 2.76 0 0 0 1.21-2.47c0-2.18-1.6-3.28-4.79-3.28h-6.44v6.53h6.44a6.63 6.63 0 0 0 3.56-0.78z"
      />
      <path
        class="e"
        d="m1374.4 166.92-4.23-6h-1.23-4.85v6h-2.8v-19.6h7.65a9 9 0 0 1 6 1.82 6.15 6.15 0 0 1 2.18 5 6.52 6.52 0 0 1-1.11 3.86 6.3 6.3 0 0 1-3.19 2.33l4.65 6.58zm-1.49-9.5a4.51 4.51 0 0 0 0-6.53 6.28 6.28 0 0 0-4.06-1.14h-4.76v8.82h4.76a6.22 6.22 0 0 0 4.06-1.15z"
      />
      <path
        class="e"
        d="M1382,166.59a7.67,7.67,0,0,1-2.63-1.24l1.12-2.13a8,8,0,0,0,2.38,1.12,9.8,9.8,0,0,0,2.83.42c2.35,0,3.53-.67,3.53-2a1.31,1.31,0,0,0-.48-1.07,3,3,0,0,0-1.19-.57c-.47-.12-1.15-.26-2-.41a20.62,20.62,0,0,1-2.92-.64,4.64,4.64,0,0,1-1.95-1.23,3.46,3.46,0,0,1-.81-2.44,3.79,3.79,0,0,1,1.68-3.23,7.47,7.47,0,0,1,4.51-1.22,12.55,12.55,0,0,1,3,.36,8,8,0,0,1,2.44,1l-1.15,2.13a7.66,7.66,0,0,0-4.28-1.18,5,5,0,0,0-2.61.56,1.71,1.71,0,0,0-.89,1.49,1.42,1.42,0,0,0,.5,1.14,3.06,3.06,0,0,0,1.25.62c.49.13,1.19.28,2.08.45a20.67,20.67,0,0,1,2.9.66,4.27,4.27,0,0,1,1.89,1.19,3.32,3.32,0,0,1,.79,2.35,3.7,3.7,0,0,1-1.73,3.2,8.06,8.06,0,0,1-4.69,1.19A12.33,12.33,0,0,1,1382,166.59Z"
      />
    </g>
    <rect class="d" x="1448" y="117" width="287" height="80" />
    <g class="a">
      <path
        class="e"
        d="m1504.2 149.14a6.85 6.85 0 0 1 0 10 9.07 9.07 0 0 1-6 1.82h-4.84v5.94h-2.8v-19.6h7.64a9.07 9.07 0 0 1 6 1.84zm-2 8.27a4.5 4.5 0 0 0 0-6.52 6.3 6.3 0 0 0-4.06-1.14h-4.76v8.79h4.76a6.3 6.3 0 0 0 4.04-1.13z"
      />
      <path
        class="e"
        d="m1514.5 152.61a7.46 7.46 0 0 1 3.33-0.67v2.6a4.88 4.88 0 0 0-0.62 0 4.53 4.53 0 0 0-3.43 1.31 5.13 5.13 0 0 0-1.24 3.71v7.39h-2.69v-14.87h2.58v2.49a4.53 4.53 0 0 1 2.07-1.96z"
      />
      <path
        class="e"
        d="m1523.4 166.11a7.16 7.16 0 0 1-2.75-2.71 8.12 8.12 0 0 1 0-7.81 7 7 0 0 1 2.75-2.69 8.22 8.22 0 0 1 4-1 8.09 8.09 0 0 1 4 1 7.11 7.11 0 0 1 2.75 2.69 8.2 8.2 0 0 1 0 7.81 7.23 7.23 0 0 1-2.75 2.71 8 8 0 0 1-4 1 8.12 8.12 0 0 1-4-1zm6.54-2a4.66 4.66 0 0 0 1.78-1.85 6.14 6.14 0 0 0 0-5.46 4.51 4.51 0 0 0-1.78-1.84 5.44 5.44 0 0 0-5.12 0 4.6 4.6 0 0 0-1.79 1.84 6 6 0 0 0 0 5.46 4.75 4.75 0 0 0 1.79 1.85 5.37 5.37 0 0 0 5.12 0z"
      />
      <path
        class="e"
        d="M1552.78,146.14v20.78h-2.57v-2.35a5.88,5.88,0,0,1-2.27,1.87,7,7,0,0,1-3,.64,7.76,7.76,0,0,1-3.87-1,6.72,6.72,0,0,1-2.67-2.67,7.91,7.91,0,0,1-1-4,7.81,7.81,0,0,1,1-3.95,6.76,6.76,0,0,1,2.67-2.66,7.76,7.76,0,0,1,3.87-.95,7.16,7.16,0,0,1,2.94.6,5.71,5.71,0,0,1,2.24,1.78v-8.18Zm-5.09,17.94a4.69,4.69,0,0,0,1.79-1.85,6.14,6.14,0,0,0,0-5.46,4.53,4.53,0,0,0-1.79-1.84,5.14,5.14,0,0,0-2.55-.64,5.07,5.07,0,0,0-2.56.64,4.53,4.53,0,0,0-1.79,1.84,6,6,0,0,0,0,5.46,4.69,4.69,0,0,0,1.79,1.85,5.07,5.07,0,0,0,2.56.65A5.14,5.14,0,0,0,1547.69,164.08Z"
      />
      <path
        class="e"
        d="M1571.71,152.08v14.84h-2.55v-2.24a5.41,5.41,0,0,1-2.14,1.78,6.65,6.65,0,0,1-2.9.62,6.48,6.48,0,0,1-4.67-1.63,6.38,6.38,0,0,1-1.71-4.83v-8.54h2.69v8.23a4.6,4.6,0,0,0,1,3.26,3.86,3.86,0,0,0,3,1.11,4.42,4.42,0,0,0,3.36-1.29,5.06,5.06,0,0,0,1.23-3.64v-7.67Z"
      />
      <path
        class="e"
        d="M1579.26,166.11a7.2,7.2,0,0,1-2.79-2.71,8.05,8.05,0,0,1,0-7.81,7.08,7.08,0,0,1,2.79-2.69,8.35,8.35,0,0,1,4-1,7.76,7.76,0,0,1,3.6.81,5.73,5.73,0,0,1,2.45,2.35l-2,1.32a4.66,4.66,0,0,0-1.74-1.6,5,5,0,0,0-2.29-.53,5.27,5.27,0,0,0-2.62.64,4.54,4.54,0,0,0-1.82,1.84,6,6,0,0,0,0,5.48,4.54,4.54,0,0,0,1.82,1.84,5.27,5.27,0,0,0,2.62.64,4.86,4.86,0,0,0,2.29-.53,4.63,4.63,0,0,0,1.74-1.59l2,1.28a5.83,5.83,0,0,1-2.45,2.37,7.64,7.64,0,0,1-3.6.82A8.24,8.24,0,0,1,1579.26,166.11Z"
      />
      <path
        class="e"
        d="M1601.36,166.05a4.09,4.09,0,0,1-1.45.77,6,6,0,0,1-1.79.26,4.78,4.78,0,0,1-3.48-1.2,4.59,4.59,0,0,1-1.23-3.44v-8.15h-2.52v-2.21h2.52v-3.25h2.69v3.25h4.26v2.21h-4.26v8a2.6,2.6,0,0,0,.6,1.84,2.25,2.25,0,0,0,1.72.65,3.28,3.28,0,0,0,2.1-.7Z"
      />
      <path
        class="e"
        d="m1626.2 149.14a6.85 6.85 0 0 1 0 10 9 9 0 0 1-6 1.82h-4.85v5.94h-2.79v-19.6h7.64a9 9 0 0 1 6 1.84zm-2 8.27a4.52 4.52 0 0 0 0-6.52 6.33 6.33 0 0 0-4.07-1.14h-4.76v8.79h4.76a6.33 6.33 0 0 0 4.06-1.13z"
      />
      <path
        class="e"
        d="M1642.06,153.45a5.85,5.85,0,0,1,1.64,4.51v9h-2.55v-2a4.31,4.31,0,0,1-1.92,1.58,7.36,7.36,0,0,1-3,.54,6.2,6.2,0,0,1-4-1.2,4.13,4.13,0,0,1-.07-6.31,7,7,0,0,1,4.53-1.19H1641v-.54a3.45,3.45,0,0,0-1-2.66,4.27,4.27,0,0,0-3-.92,7.86,7.86,0,0,0-2.58.44,6.4,6.4,0,0,0-2.13,1.16l-1.12-2a8,8,0,0,1,2.75-1.41,11.32,11.32,0,0,1,3.38-.49A6.69,6.69,0,0,1,1642.06,153.45Zm-2.67,10.88a4,4,0,0,0,1.62-2v-2.07h-4.14c-2.28,0-3.42.76-3.42,2.3a2.08,2.08,0,0,0,.87,1.76,4,4,0,0,0,2.41.64A5.08,5.08,0,0,0,1639.39,164.33Z"
      />
      <path
        class="e"
        d="M1663,152.08V164.9c0,2.6-.64,4.51-1.93,5.76s-3.21,1.86-5.77,1.86a13.53,13.53,0,0,1-4-.58,7.85,7.85,0,0,1-3.08-1.64l1.29-2.07a7.6,7.6,0,0,0,2.56,1.42,9.81,9.81,0,0,0,3.15.52,5.3,5.3,0,0,0,3.85-1.24,5.06,5.06,0,0,0,1.24-3.75V164a6,6,0,0,1-2.33,1.73,7.87,7.87,0,0,1-3,.57,8,8,0,0,1-3.85-.91,6.86,6.86,0,0,1-2.71-2.55,7.61,7.61,0,0,1,0-7.47,6.77,6.77,0,0,1,2.71-2.54,8.27,8.27,0,0,1,3.85-.89,7.86,7.86,0,0,1,3.12.61,5.76,5.76,0,0,1,2.36,1.82v-2.29Zm-5.11,11.25a4.5,4.5,0,0,0,1.82-1.72,4.79,4.79,0,0,0,.66-2.5,4.56,4.56,0,0,0-1.43-3.49,5.76,5.76,0,0,0-7.36,0,4.56,4.56,0,0,0-1.43,3.49,4.79,4.79,0,0,0,.66,2.5,4.5,4.5,0,0,0,1.82,1.72,5.89,5.89,0,0,0,5.26,0Z"
      />
      <path
        class="e"
        d="m1681.4 160.39h-12a4.65 4.65 0 0 0 1.72 3.15 5.59 5.59 0 0 0 3.66 1.19 5.48 5.48 0 0 0 4.28-1.79l1.48 1.74a6.48 6.48 0 0 1-2.5 1.79 9.52 9.52 0 0 1-7.52-0.35 7 7 0 0 1-2.81-2.7 7.7 7.7 0 0 1-1-3.92 7.88 7.88 0 0 1 1-3.89 6.93 6.93 0 0 1 2.66-2.71 7.61 7.61 0 0 1 3.82-1 7.5 7.5 0 0 1 3.8 1 6.85 6.85 0 0 1 2.6 2.71 8.26 8.26 0 0 1 0.94 4 7 7 0 0 1-0.13 0.78zm-10.51-5a4.75 4.75 0 0 0-1.53 3.08h9.52a4.83 4.83 0 0 0-1.52-3.07 5 5 0 0 0-6.47 0z"
      />
      <path
        class="e"
        d="M1686,166.59a7.58,7.58,0,0,1-2.63-1.24l1.12-2.13a7.84,7.84,0,0,0,2.38,1.12,9.73,9.73,0,0,0,2.82.42c2.36,0,3.53-.67,3.53-2a1.33,1.33,0,0,0-.47-1.07,3.07,3.07,0,0,0-1.19-.57c-.48-.12-1.16-.26-2-.41a20.8,20.8,0,0,1-2.93-.64,4.64,4.64,0,0,1-1.95-1.23,3.46,3.46,0,0,1-.81-2.44,3.79,3.79,0,0,1,1.68-3.23,7.47,7.47,0,0,1,4.51-1.22,12.57,12.57,0,0,1,3,.36,7.84,7.84,0,0,1,2.43,1l-1.14,2.13a7.71,7.71,0,0,0-4.29-1.18,5,5,0,0,0-2.6.56,1.7,1.7,0,0,0-.9,1.49,1.4,1.4,0,0,0,.51,1.14,3,3,0,0,0,1.24.62c.5.13,1.19.28,2.09.45a21,21,0,0,1,2.9.66,4.38,4.38,0,0,1,1.89,1.19,3.32,3.32,0,0,1,.78,2.35,3.69,3.69,0,0,1-1.72,3.2,8.08,8.08,0,0,1-4.69,1.19A12.28,12.28,0,0,1,1686,166.59Z"
      />
    </g>
    <rect class="d second" x="209" y="22" width="327" height="80" />
    <g class="a">
      <path class="e" d="m257.57 52.32h2.8v19.6h-2.8z" />
      <path
        class="e"
        d="m278.27 58.58a6.44 6.44 0 0 1 1.67 4.8v8.54h-2.69v-8.24a4.55 4.55 0 0 0-1-3.24 3.91 3.91 0 0 0-3-1.1 4.63 4.63 0 0 0-3.45 1.28 5 5 0 0 0-1.26 3.65v7.65h-2.68v-14.84h2.57v2.24a5.22 5.22 0 0 1 2.2-1.77 7.66 7.66 0 0 1 3.15-0.61 6.08 6.08 0 0 1 4.49 1.64z"
      />
      <path class="e" d="M284,63.07h7.53v2.32H284Z" />
      <path
        class="e"
        d="M297,71.46a8.79,8.79,0,0,1-3.24-1.78l1-2.19a8.35,8.35,0,0,0,2.87,1.64,10.49,10.49,0,0,0,3.6.63,6.43,6.43,0,0,0,3.64-.83,2.54,2.54,0,0,0,1.2-2.19,2.17,2.17,0,0,0-.66-1.64,4.52,4.52,0,0,0-1.62-1q-1-.33-2.73-.75a27.49,27.49,0,0,1-3.57-1.07,5.86,5.86,0,0,1-2.33-1.64,4.37,4.37,0,0,1-1-3,5,5,0,0,1,.82-2.82,5.66,5.66,0,0,1,2.51-2,10.06,10.06,0,0,1,4.17-.76,13.16,13.16,0,0,1,3.42.45,9.07,9.07,0,0,1,2.88,1.29l-.92,2.24a10.22,10.22,0,0,0-2.63-1.19,9.91,9.91,0,0,0-2.75-.41,6.06,6.06,0,0,0-3.57.87A2.67,2.67,0,0,0,297,57.58a2.18,2.18,0,0,0,.67,1.64,4.67,4.67,0,0,0,1.67,1,26.27,26.27,0,0,0,2.7.74A29.15,29.15,0,0,1,305.59,62a6.13,6.13,0,0,1,2.31,1.63,4.3,4.3,0,0,1,1,2.94,5,5,0,0,1-.84,2.81,5.69,5.69,0,0,1-2.54,2,10.63,10.63,0,0,1-4.2.74A13.58,13.58,0,0,1,297,71.46Z"
      />
      <path
        class="e"
        d="M320.9,71.05a4.09,4.09,0,0,1-1.45.77,6,6,0,0,1-1.79.26,4.78,4.78,0,0,1-3.48-1.2A4.59,4.59,0,0,1,313,67.44V59.29h-2.52V57.08H313V53.83h2.69v3.25h4.26v2.21h-4.26v8a2.6,2.6,0,0,0,.6,1.84,2.25,2.25,0,0,0,1.72.65,3.28,3.28,0,0,0,2.1-.7Z"
      />
      <path
        class="e"
        d="M326,71.11a7.12,7.12,0,0,1-2.76-2.71,8.12,8.12,0,0,1,0-7.81A7,7,0,0,1,326,57.9a8.69,8.69,0,0,1,7.94,0,7.08,7.08,0,0,1,2.74,2.69,8.2,8.2,0,0,1,0,7.81A7.2,7.2,0,0,1,334,71.11a8.61,8.61,0,0,1-7.94,0Zm6.53-2a4.66,4.66,0,0,0,1.78-1.85,6.06,6.06,0,0,0,0-5.46,4.51,4.51,0,0,0-1.78-1.84,5,5,0,0,0-2.56-.64,5.09,5.09,0,0,0-2.56.64,4.66,4.66,0,0,0-1.79,1.84,6,6,0,0,0,0,5.46,4.83,4.83,0,0,0,1.79,1.85,5.1,5.1,0,0,0,2.56.65A5,5,0,0,0,332.56,69.08Z"
      />
      <path
        class="e"
        d="M346.07,57.61a7.54,7.54,0,0,1,3.34-.67v2.6a5,5,0,0,0-.62,0,4.51,4.51,0,0,0-3.43,1.31,5.14,5.14,0,0,0-1.25,3.71v7.39h-2.68V57.08H344v2.49A4.63,4.63,0,0,1,346.07,57.61Z"
      />
      <path
        class="e"
        d="m365.93 65.39h-12a4.65 4.65 0 0 0 1.72 3.15 5.58 5.58 0 0 0 3.65 1.19 5.5 5.5 0 0 0 4.29-1.79l1.41 1.74a6.52 6.52 0 0 1-2.51 1.79 8.68 8.68 0 0 1-3.34 0.61 8.79 8.79 0 0 1-4.17-1 7 7 0 0 1-2.82-2.7 8.27 8.27 0 0 1 0-7.81 7 7 0 0 1 2.65-2.71 7.64 7.64 0 0 1 3.83-1 7.44 7.44 0 0 1 3.79 1 6.79 6.79 0 0 1 2.6 2.71 8.15 8.15 0 0 1 0.94 4c0.03 0.22 0.03 0.49-0.04 0.82zm-10.52-5a4.74 4.74 0 0 0-1.52 3.08h9.52a4.8 4.8 0 0 0-1.53-3.07 4.61 4.61 0 0 0-3.23-1.18 4.67 4.67 0 0 0-3.24 1.16z"
      />
      <path
        class="e"
        d="M377.63,52.32h8.26a12,12,0,0,1,5.52,1.23A9.07,9.07,0,0,1,395.15,57a10.48,10.48,0,0,1,0,10.22,9.11,9.11,0,0,1-3.74,3.45,11.87,11.87,0,0,1-5.52,1.24h-8.26Zm8.09,17.16a9.14,9.14,0,0,0,4.19-.92A6.7,6.7,0,0,0,392.69,66a8.05,8.05,0,0,0,0-7.7,6.63,6.63,0,0,0-2.78-2.59,9,9,0,0,0-4.19-.93h-5.29V69.48Z"
      />
      <path
        class="e"
        d="M400.41,53.72a1.65,1.65,0,0,1-.52-1.23,1.68,1.68,0,0,1,.52-1.25,1.74,1.74,0,0,1,1.3-.52,1.8,1.8,0,0,1,1.3.49,1.6,1.6,0,0,1,.52,1.22A1.7,1.7,0,0,1,403,53.7a1.74,1.74,0,0,1-1.3.52A1.8,1.8,0,0,1,400.41,53.72Zm0,3.36h2.68V71.92h-2.68Z"
      />
      <path
        class="e"
        d="M408.93,71.59a7.67,7.67,0,0,1-2.63-1.24l1.12-2.13a8.08,8.08,0,0,0,2.38,1.12,9.85,9.85,0,0,0,2.83.42c2.35,0,3.53-.67,3.53-2a1.31,1.31,0,0,0-.48-1.07,3,3,0,0,0-1.19-.57c-.47-.12-1.15-.26-2-.41a20.62,20.62,0,0,1-2.92-.64,4.64,4.64,0,0,1-2-1.23,3.46,3.46,0,0,1-.81-2.44,3.79,3.79,0,0,1,1.68-3.23A7.47,7.47,0,0,1,413,56.94a12.48,12.48,0,0,1,3,.36,7.9,7.9,0,0,1,2.44,1l-1.15,2.13a7.66,7.66,0,0,0-4.28-1.18,5,5,0,0,0-2.61.56,1.71,1.71,0,0,0-.89,1.49,1.42,1.42,0,0,0,.5,1.14,3.06,3.06,0,0,0,1.25.62c.49.13,1.19.28,2.08.45a20.67,20.67,0,0,1,2.9.66,4.27,4.27,0,0,1,1.89,1.19,3.32,3.32,0,0,1,.79,2.35,3.7,3.7,0,0,1-1.73,3.2,8.06,8.06,0,0,1-4.69,1.19A12.33,12.33,0,0,1,408.93,71.59Z"
      />
      <path
        class="e"
        d="M433.94,57.89a6.76,6.76,0,0,1,2.67,2.66,7.81,7.81,0,0,1,1,4,7.91,7.91,0,0,1-1,4,6.72,6.72,0,0,1-2.67,2.67,7.76,7.76,0,0,1-3.87,1,6.85,6.85,0,0,1-2.92-.61,6.14,6.14,0,0,1-2.26-1.79v7.67h-2.68V57.08h2.57v2.35a5.77,5.77,0,0,1,2.27-1.86,7.13,7.13,0,0,1,3-.63A7.76,7.76,0,0,1,433.94,57.89Zm-1.53,11.19a4.69,4.69,0,0,0,1.79-1.85,6,6,0,0,0,0-5.46,4.53,4.53,0,0,0-1.79-1.84,5.07,5.07,0,0,0-2.56-.64,4.92,4.92,0,0,0-2.53.66,4.79,4.79,0,0,0-1.8,1.83,5.58,5.58,0,0,0-.65,2.72,5.67,5.67,0,0,0,.64,2.73,4.69,4.69,0,0,0,1.79,1.85,5.14,5.14,0,0,0,2.55.65A5.07,5.07,0,0,0,432.41,69.08Z"
      />
      <path class="e" d="m441.3 51.14h2.7v20.78h-2.7z" />
      <path
        class="e"
        d="M459.24,58.45A5.85,5.85,0,0,1,460.87,63v9h-2.54V70a4.31,4.31,0,0,1-1.92,1.58,7.42,7.42,0,0,1-3,.54,6.2,6.2,0,0,1-4-1.2,4.15,4.15,0,0,1-.07-6.31,7,7,0,0,1,4.54-1.19h4.26v-.54a3.45,3.45,0,0,0-1-2.66,4.28,4.28,0,0,0-3-.92,7.86,7.86,0,0,0-2.58.44,6.25,6.25,0,0,0-2.12,1.16l-1.12-2a7.9,7.9,0,0,1,2.74-1.41,11.34,11.34,0,0,1,3.39-.49A6.73,6.73,0,0,1,459.24,58.45Zm-2.68,10.88a4,4,0,0,0,1.63-2V65.31H454c-2.28,0-3.42.76-3.42,2.3a2.1,2.1,0,0,0,.87,1.76,4,4,0,0,0,2.41.64A5.06,5.06,0,0,0,456.56,69.33Z"
      />
      <path
        class="e"
        d="M478.71,57.08l-7.2,16.32a7.28,7.28,0,0,1-2.22,3.19,5.06,5.06,0,0,1-3.07.93,6.53,6.53,0,0,1-2.11-.35,4.46,4.46,0,0,1-1.7-1l1.15-2a3.74,3.74,0,0,0,2.66,1.09,2.56,2.56,0,0,0,1.64-.52,4.43,4.43,0,0,0,1.19-1.78l.48-1L463,57.08h2.8L471,69l5.13-11.87Z"
      />
      <path
        class="e"
        d="M481.79,71.59a7.58,7.58,0,0,1-2.63-1.24l1.12-2.13a7.84,7.84,0,0,0,2.38,1.12,9.75,9.75,0,0,0,2.83.42c2.35,0,3.52-.67,3.52-2a1.33,1.33,0,0,0-.47-1.07,3.07,3.07,0,0,0-1.19-.57c-.48-.12-1.16-.26-2-.41a20.8,20.8,0,0,1-2.93-.64,4.67,4.67,0,0,1-1.94-1.23,3.41,3.41,0,0,1-.82-2.44,3.79,3.79,0,0,1,1.68-3.23,7.49,7.49,0,0,1,4.51-1.22,12.57,12.57,0,0,1,3,.36,8,8,0,0,1,2.44,1l-1.15,2.13a7.69,7.69,0,0,0-4.29-1.18,5,5,0,0,0-2.6.56,1.7,1.7,0,0,0-.9,1.49,1.4,1.4,0,0,0,.51,1.14A3,3,0,0,0,484,63c.5.13,1.19.28,2.09.45a21,21,0,0,1,2.9.66,4.38,4.38,0,0,1,1.89,1.19,3.32,3.32,0,0,1,.78,2.35,3.69,3.69,0,0,1-1.72,3.2,8.08,8.08,0,0,1-4.69,1.19A12.33,12.33,0,0,1,481.79,71.59Z"
      />
    </g>
    <rect class="d third" x="551" y="22" width="298" height="80" />
    <g class="a">
      <path
        class="e"
        d="m599.07 70.84a9.66 9.66 0 0 1-3.74-3.6 10.34 10.34 0 0 1 0-10.25 9.66 9.66 0 0 1 3.74-3.6 11.6 11.6 0 0 1 10.67 0 9.65 9.65 0 0 1 3.72 3.61 10.51 10.51 0 0 1 0 10.27 9.58 9.58 0 0 1-3.72 3.59 11.6 11.6 0 0 1-10.67 0zm9.22-2.17a7.13 7.13 0 0 0 2.71-2.67 8 8 0 0 0 0-7.7 7.13 7.13 0 0 0-2.72-2.7 7.61 7.61 0 0 0-3.87-1 7.81 7.81 0 0 0-3.91 1 7 7 0 0 0-2.74 2.7 7.91 7.91 0 0 0 0 7.7 7 7 0 0 0 2.74 2.7 7.91 7.91 0 0 0 3.91 1 7.7 7.7 0 0 0 3.88-1.03z"
      />
      <path
        class="e"
        d="M621.72,55.79v1.29H626v2.21h-4.25V71.92h-2.69V59.29h-2.52V57.08h2.52V55.76a4.73,4.73,0,0,1,1.29-3.5A4.86,4.86,0,0,1,624,51a6.09,6.09,0,0,1,1.68.23,4.07,4.07,0,0,1,1.34.64l-.81,2a3.4,3.4,0,0,0-2.07-.7C622.52,53.18,621.72,54.05,621.72,55.79Z"
      />
      <path
        class="e"
        d="M631.6,55.79v1.29h4.32v2.21h-4.26V71.92H629V59.29h-2.52V57.08H629V55.76a4.73,4.73,0,0,1,1.29-3.5A4.88,4.88,0,0,1,633.87,51a6,6,0,0,1,1.68.23,4.22,4.22,0,0,1,1.35.64l-.82,2a3.36,3.36,0,0,0-2.07-.7C632.41,53.18,631.6,54.05,631.6,55.79Zm6.86-4.65h2.69V71.92h-2.69Z"
      />
      <path
        class="e"
        d="M646.32,53.72a1.65,1.65,0,0,1-.52-1.23,1.68,1.68,0,0,1,.52-1.25,1.74,1.74,0,0,1,1.3-.52,1.8,1.8,0,0,1,1.3.49,1.6,1.6,0,0,1,.52,1.22,1.7,1.7,0,0,1-.52,1.27,1.74,1.74,0,0,1-1.3.52A1.8,1.8,0,0,1,646.32,53.72Zm0,3.36H649V71.92h-2.68Z"
      />
      <path
        class="e"
        d="m666.5 58.58a6.4 6.4 0 0 1 1.67 4.8v8.54h-2.69v-8.24a4.55 4.55 0 0 0-1-3.24 3.89 3.89 0 0 0-3-1.1 4.61 4.61 0 0 0-3.48 1.28 4.94 4.94 0 0 0-1.27 3.65v7.65h-2.68v-14.84h2.57v2.24a5.22 5.22 0 0 1 2.2-1.77 7.7 7.7 0 0 1 3.15-0.61 6.08 6.08 0 0 1 4.53 1.64z"
      />
      <path
        class="e"
        d="m686.59 65.39h-12a4.66 4.66 0 0 0 1.73 3.15 5.58 5.58 0 0 0 3.65 1.19 5.48 5.48 0 0 0 4.28-1.79l1.49 1.74a6.52 6.52 0 0 1-2.51 1.79 8.72 8.72 0 0 1-3.34 0.61 8.85 8.85 0 0 1-4.18-1 7 7 0 0 1-2.81-2.7 8.31 8.31 0 0 1 0-7.81 7 7 0 0 1 2.66-2.71 7.63 7.63 0 0 1 3.82-1 7.48 7.48 0 0 1 3.8 1 6.79 6.79 0 0 1 2.6 2.71 8.15 8.15 0 0 1 0.94 4 7 7 0 0 1-0.13 0.82zm-10.51-5a4.75 4.75 0 0 0-1.53 3.08h9.52a4.74 4.74 0 0 0-1.52-3.07 5 5 0 0 0-6.47 0z"
      />
      <path
        class="e"
        d="m701.76 70.84a9.49 9.49 0 0 1-3.71-3.59 10.44 10.44 0 0 1 0-10.27 9.52 9.52 0 0 1 3.72-3.59 11 11 0 0 1 5.32-1.3 11.17 11.17 0 0 1 4.2 0.77 8.79 8.79 0 0 1 3.25 2.28l-1.82 1.77a7.31 7.31 0 0 0-5.52-2.33 8 8 0 0 0-4 1 7.07 7.07 0 0 0-2.75 2.7 7.91 7.91 0 0 0 0 7.7 7.07 7.07 0 0 0 2.75 2.7 8.06 8.06 0 0 0 4 1 7.3 7.3 0 0 0 5.52-2.35l1.82 1.76a8.79 8.79 0 0 1-3.26 2.3 11.12 11.12 0 0 1-4.22 0.78 10.75 10.75 0 0 1-5.3-1.33z"
      />
      <path
        class="e"
        d="m719.92 71.11a7.12 7.12 0 0 1-2.76-2.71 8.12 8.12 0 0 1 0-7.81 7 7 0 0 1 2.76-2.69 8.15 8.15 0 0 1 4-1 8.06 8.06 0 0 1 4 1 7.11 7.11 0 0 1 2.75 2.69 8.2 8.2 0 0 1 0 7.81 7.23 7.23 0 0 1-2.75 2.71 8 8 0 0 1-4 1 8.05 8.05 0 0 1-4-1zm6.53-2a4.66 4.66 0 0 0 1.78-1.85 6.06 6.06 0 0 0 0-5.46 4.51 4.51 0 0 0-1.78-1.84 5.44 5.44 0 0 0-5.12 0 4.6 4.6 0 0 0-1.79 1.84 6 6 0 0 0 0 5.46 4.75 4.75 0 0 0 1.79 1.85 5.37 5.37 0 0 0 5.12 0z"
      />
      <path
        class="e"
        d="m747.73 58.58a6.44 6.44 0 0 1 1.67 4.8v8.54h-2.69v-8.24a4.55 4.55 0 0 0-1-3.24 3.89 3.89 0 0 0-3-1.1 4.63 4.63 0 0 0-3.45 1.28 5 5 0 0 0-1.26 3.65v7.65h-2.69v-14.84h2.58v2.24a5.16 5.16 0 0 1 2.2-1.77 7.66 7.66 0 0 1 3.15-0.61 6.1 6.1 0 0 1 4.49 1.64z"
      />
      <path
        class="e"
        d="M762.73,71.05a4.23,4.23,0,0,1-1.46.77,6,6,0,0,1-1.79.26,4.77,4.77,0,0,1-3.47-1.2,4.6,4.6,0,0,1-1.24-3.44V59.29h-2.52V57.08h2.52V53.83h2.69v3.25h4.26v2.21h-4.26v8a2.6,2.6,0,0,0,.6,1.84,2.27,2.27,0,0,0,1.73.65,3.28,3.28,0,0,0,2.1-.7Z"
      />
      <path
        class="e"
        d="m778.83 65.39h-12a4.65 4.65 0 0 0 1.72 3.15 5.58 5.58 0 0 0 3.65 1.19 5.5 5.5 0 0 0 4.29-1.79l1.48 1.74a6.52 6.52 0 0 1-2.51 1.79 8.68 8.68 0 0 1-3.34 0.61 8.79 8.79 0 0 1-4.17-1 7 7 0 0 1-2.82-2.7 8.31 8.31 0 0 1 0-7.81 7.07 7.07 0 0 1 2.66-2.71 7.64 7.64 0 0 1 3.83-1 7.47 7.47 0 0 1 3.79 1 6.88 6.88 0 0 1 2.59 2.75 8.25 8.25 0 0 1 0.93 4c-0.05 0.18-0.07 0.45-0.1 0.78zm-10.52-5a4.74 4.74 0 0 0-1.52 3.08h9.52a4.8 4.8 0 0 0-1.53-3.07 5 5 0 0 0-6.47 0z"
      />
      <path
        class="e"
        d="M795,58.58a6.44,6.44,0,0,1,1.67,4.8v8.54H794V63.68a4.55,4.55,0,0,0-1-3.24,3.89,3.89,0,0,0-3-1.1,4.63,4.63,0,0,0-3.45,1.28,5,5,0,0,0-1.26,3.65v7.65h-2.68V57.08h2.57v2.24a5.22,5.22,0,0,1,2.2-1.77,7.66,7.66,0,0,1,3.15-.61A6.08,6.08,0,0,1,795,58.58Z"
      />
      <path
        class="e"
        d="M810,71.05a4.14,4.14,0,0,1-1.46.77,6,6,0,0,1-1.79.26,4.77,4.77,0,0,1-3.47-1.2,4.59,4.59,0,0,1-1.23-3.44V59.29h-2.52V57.08h2.52V53.83h2.68v3.25H809v2.21h-4.26v8a2.6,2.6,0,0,0,.61,1.84,2.23,2.23,0,0,0,1.72.65,3.28,3.28,0,0,0,2.1-.7Z"
      />
    </g>
    <rect class="d fourth" x="864" y="22" width="226" height="80" />
    <g class="a">
      <path
        class="e"
        d="m926.74 71.92v-14.28l-7.08 11.9h-1.29l-7.08-11.82v14.2h-2.69v-19.6h2.29l8.11 13.77 8.06-13.77h2.3v19.6z"
      />
      <path
        class="e"
        d="M935,53.72a1.65,1.65,0,0,1-.52-1.23,1.68,1.68,0,0,1,.52-1.25,1.74,1.74,0,0,1,1.3-.52,1.8,1.8,0,0,1,1.3.49,1.6,1.6,0,0,1,.52,1.22,1.7,1.7,0,0,1-.52,1.27,1.74,1.74,0,0,1-1.3.52A1.8,1.8,0,0,1,935,53.72Zm0,3.36h2.68V71.92h-2.68Z"
      />
      <path
        class="e"
        d="M945.15,71.11a7.27,7.27,0,0,1-2.79-2.71,8.12,8.12,0,0,1,0-7.81,7.15,7.15,0,0,1,2.79-2.69,8.36,8.36,0,0,1,4.05-1,7.75,7.75,0,0,1,3.59.81,5.67,5.67,0,0,1,2.45,2.35l-2,1.32a4.66,4.66,0,0,0-1.74-1.6,5,5,0,0,0-2.29-.53,5.3,5.3,0,0,0-2.62.64,4.54,4.54,0,0,0-1.82,1.84,6,6,0,0,0,0,5.48,4.54,4.54,0,0,0,1.82,1.84,5.3,5.3,0,0,0,2.62.64,4.86,4.86,0,0,0,2.29-.53,4.63,4.63,0,0,0,1.74-1.59l2,1.28a5.78,5.78,0,0,1-2.45,2.37,7.63,7.63,0,0,1-3.59.82A8.25,8.25,0,0,1,945.15,71.11Z"
      />
      <path
        class="e"
        d="m963.36 57.61a7.54 7.54 0 0 1 3.34-0.67v2.6a5 5 0 0 0-0.62 0 4.51 4.51 0 0 0-3.43 1.31 5.14 5.14 0 0 0-1.25 3.71v7.39h-2.68v-14.87h2.57v2.49a4.63 4.63 0 0 1 2.07-1.96z"
      />
      <path
        class="e"
        d="m972.24 71.11a7.12 7.12 0 0 1-2.76-2.71 8.2 8.2 0 0 1 0-7.81 7 7 0 0 1 2.76-2.69 8.69 8.69 0 0 1 7.94 0 7.08 7.08 0 0 1 2.74 2.69 8.12 8.12 0 0 1 0 7.81 7.2 7.2 0 0 1-2.74 2.71 8.61 8.61 0 0 1-7.94 0zm6.54-2a4.73 4.73 0 0 0 1.78-1.85 6.14 6.14 0 0 0 0-5.46 4.57 4.57 0 0 0-1.78-1.84 5.46 5.46 0 0 0-5.13 0 4.66 4.66 0 0 0-1.79 1.84 6 6 0 0 0 0 5.46 4.83 4.83 0 0 0 1.79 1.85 5.39 5.39 0 0 0 5.13 0z"
      />
      <path
        class="e"
        d="M988.4,71.59a7.73,7.73,0,0,1-2.64-1.24l1.12-2.13a8.08,8.08,0,0,0,2.38,1.12,9.85,9.85,0,0,0,2.83.42c2.35,0,3.53-.67,3.53-2a1.31,1.31,0,0,0-.48-1.07,2.94,2.94,0,0,0-1.19-.57c-.47-.12-1.15-.26-2-.41a20.62,20.62,0,0,1-2.92-.64,4.64,4.64,0,0,1-2-1.23,3.46,3.46,0,0,1-.81-2.44,3.79,3.79,0,0,1,1.68-3.23,7.47,7.47,0,0,1,4.51-1.22,12.57,12.57,0,0,1,3,.36,7.94,7.94,0,0,1,2.43,1l-1.15,2.13a7.66,7.66,0,0,0-4.28-1.18,5,5,0,0,0-2.61.56,1.71,1.71,0,0,0-.89,1.49,1.42,1.42,0,0,0,.5,1.14,3.06,3.06,0,0,0,1.25.62c.49.13,1.19.28,2.09.45a20.49,20.49,0,0,1,2.89.66,4.27,4.27,0,0,1,1.89,1.19,3.28,3.28,0,0,1,.79,2.35,3.7,3.7,0,0,1-1.73,3.2,8,8,0,0,1-4.68,1.19A12.23,12.23,0,0,1,988.4,71.59Z"
      />
      <path
        class="e"
        d="M1001.71,53.72a1.65,1.65,0,0,1-.52-1.23,1.68,1.68,0,0,1,.52-1.25,1.74,1.74,0,0,1,1.3-.52,1.8,1.8,0,0,1,1.3.49,1.6,1.6,0,0,1,.52,1.22,1.7,1.7,0,0,1-.52,1.27,1.74,1.74,0,0,1-1.3.52A1.8,1.8,0,0,1,1001.71,53.72Zm0,3.36h2.68V71.92h-2.68Z"
      />
      <path
        class="e"
        d="M1017.82,71.05a4.09,4.09,0,0,1-1.45.77,6,6,0,0,1-1.79.26,4.78,4.78,0,0,1-3.48-1.2,4.59,4.59,0,0,1-1.23-3.44V59.29h-2.52V57.08h2.52V53.83h2.69v3.25h4.25v2.21h-4.25v8a2.6,2.6,0,0,0,.6,1.84,2.25,2.25,0,0,0,1.72.65,3.28,3.28,0,0,0,2.1-.7Z"
      />
      <path
        class="e"
        d="m1033.9 65.39h-12a4.65 4.65 0 0 0 1.72 3.15 5.59 5.59 0 0 0 3.66 1.19 5.48 5.48 0 0 0 4.28-1.79l1.49 1.74a6.6 6.6 0 0 1-2.51 1.79 8.73 8.73 0 0 1-3.35 0.61 8.84 8.84 0 0 1-4.17-1 7 7 0 0 1-2.81-2.7 8.23 8.23 0 0 1 0-7.81 6.93 6.93 0 0 1 2.66-2.71 7.61 7.61 0 0 1 3.82-1 7.48 7.48 0 0 1 3.8 1 6.79 6.79 0 0 1 2.6 2.71 8.15 8.15 0 0 1 0.94 4 7 7 0 0 1-0.13 0.82zm-10.51-5a4.75 4.75 0 0 0-1.53 3.08h9.52a4.79 4.79 0 0 0-1.52-3.07 5 5 0 0 0-6.47 0z"
      />
      <path
        class="e"
        d="M1038.46,71.59a7.58,7.58,0,0,1-2.63-1.24l1.12-2.13a7.84,7.84,0,0,0,2.38,1.12,9.73,9.73,0,0,0,2.82.42c2.36,0,3.53-.67,3.53-2a1.33,1.33,0,0,0-.47-1.07,3.07,3.07,0,0,0-1.19-.57c-.48-.12-1.16-.26-2-.41a20.8,20.8,0,0,1-2.93-.64,4.7,4.7,0,0,1-2-1.23,3.46,3.46,0,0,1-.81-2.44,3.79,3.79,0,0,1,1.68-3.23,7.47,7.47,0,0,1,4.51-1.22,12.57,12.57,0,0,1,3,.36,7.84,7.84,0,0,1,2.43,1l-1.14,2.13a7.71,7.71,0,0,0-4.29-1.18,5,5,0,0,0-2.6.56,1.7,1.7,0,0,0-.9,1.49,1.4,1.4,0,0,0,.51,1.14,3,3,0,0,0,1.24.62c.5.13,1.19.28,2.09.45a21,21,0,0,1,2.9.66,4.38,4.38,0,0,1,1.89,1.19,3.32,3.32,0,0,1,.78,2.35,3.69,3.69,0,0,1-1.72,3.2,8.08,8.08,0,0,1-4.69,1.19A12.33,12.33,0,0,1,1038.46,71.59Z"
      />
    </g>
    <rect class="d fifth" x="1105" y="22" width="303" height="80" />
    <g class="a">
      <path
        class="e"
        d="m1163.1 67h-10.42l-2.16 4.9h-2.88l8.88-19.6h2.77l8.9 19.6h-2.94zm-1-2.24-4.23-9.61-4.23 9.61z"
      />
      <path
        class="e"
        d="m1183.1 58.58a6.44 6.44 0 0 1 1.67 4.8v8.54h-2.69v-8.24a4.55 4.55 0 0 0-1-3.24 3.89 3.89 0 0 0-3-1.1 4.63 4.63 0 0 0-3.44 1.28 5 5 0 0 0-1.26 3.65v7.65h-2.69v-14.84h2.58v2.24a5.16 5.16 0 0 1 2.2-1.77 7.66 7.66 0 0 1 3.15-0.61 6.1 6.1 0 0 1 4.48 1.64z"
      />
      <path
        class="e"
        d="m1202.2 58.58a6.43 6.43 0 0 1 1.66 4.8v8.54h-2.68v-8.24a4.55 4.55 0 0 0-1-3.24 3.89 3.89 0 0 0-3-1.1 4.63 4.63 0 0 0-3.44 1.28 5 5 0 0 0-1.26 3.65v7.65h-2.69v-14.84h2.58v2.24a5.19 5.19 0 0 1 2.19-1.77 7.73 7.73 0 0 1 3.15-0.61 6.11 6.11 0 0 1 4.49 1.64z"
      />
      <path
        class="e"
        d="M1222.64,57.08V71.92h-2.55V69.68a5.41,5.41,0,0,1-2.14,1.78,6.68,6.68,0,0,1-2.9.62,6.45,6.45,0,0,1-4.67-1.63,6.38,6.38,0,0,1-1.71-4.83V57.08h2.69v8.23a4.6,4.6,0,0,0,1,3.26,3.86,3.86,0,0,0,3,1.11,4.42,4.42,0,0,0,3.36-1.29,5.06,5.06,0,0,0,1.23-3.64V57.08Z"
      />
      <path
        class="e"
        d="M1237.89,58.45a5.85,5.85,0,0,1,1.63,4.51v9H1237V70a4.31,4.31,0,0,1-1.92,1.58,7.42,7.42,0,0,1-3,.54,6.2,6.2,0,0,1-4-1.2,4.15,4.15,0,0,1-.07-6.31,7,7,0,0,1,4.54-1.19h4.26v-.54a3.45,3.45,0,0,0-1-2.66,4.28,4.28,0,0,0-3-.92,7.86,7.86,0,0,0-2.58.44,6.25,6.25,0,0,0-2.12,1.16l-1.12-2a7.9,7.9,0,0,1,2.74-1.41,11.34,11.34,0,0,1,3.39-.49A6.73,6.73,0,0,1,1237.89,58.45Zm-2.68,10.88a4,4,0,0,0,1.63-2V65.31h-4.15c-2.28,0-3.41.76-3.41,2.3a2.07,2.07,0,0,0,.86,1.76,4,4,0,0,0,2.41.64A5.06,5.06,0,0,0,1235.21,69.33Z"
      />
      <path class="e" d="m1244.5 51.14h2.69v20.78h-2.69z" />
      <path
        class="e"
        d="m1273.4 71.92-4.23-6h-1.23-4.89v6h-2.8v-19.6h7.65a9 9 0 0 1 6 1.82 6.18 6.18 0 0 1 2.18 5 6.52 6.52 0 0 1-1.12 3.86 6.3 6.3 0 0 1-3.19 2.33l4.65 6.58zm-1.49-9.5a4.51 4.51 0 0 0 0-6.53 6.28 6.28 0 0 0-4.06-1.14h-4.8v8.82h4.76a6.22 6.22 0 0 0 4.1-1.15z"
      />
      <path
        class="e"
        d="m1293.6 65.39h-12a4.62 4.62 0 0 0 1.73 3.15 5.55 5.55 0 0 0 3.65 1.19 5.48 5.48 0 0 0 4.28-1.79l1.49 1.74a6.52 6.52 0 0 1-2.51 1.79 8.73 8.73 0 0 1-3.35 0.61 8.84 8.84 0 0 1-4.17-1 7 7 0 0 1-2.81-2.7 8.23 8.23 0 0 1 0-7.81 7 7 0 0 1 2.66-2.71 7.61 7.61 0 0 1 3.82-1 7.48 7.48 0 0 1 3.8 1 6.79 6.79 0 0 1 2.6 2.71 8.15 8.15 0 0 1 0.94 4 7 7 0 0 1-0.13 0.82zm-10.51-5a4.75 4.75 0 0 0-1.53 3.08h9.48a4.83 4.83 0 0 0-1.52-3.07 5 5 0 0 0-6.47 0z"
      />
      <path
        class="e"
        d="M1309.07,57.89a6.85,6.85,0,0,1,2.68,2.66,7.91,7.91,0,0,1,1,4,8,8,0,0,1-1,4,6.81,6.81,0,0,1-2.68,2.67,7.7,7.7,0,0,1-3.86,1,6.85,6.85,0,0,1-2.92-.61,6.14,6.14,0,0,1-2.26-1.79v7.67h-2.69V57.08h2.58v2.35a5.7,5.7,0,0,1,2.27-1.86,7.13,7.13,0,0,1,3-.63A7.7,7.7,0,0,1,1309.07,57.89Zm-1.52,11.19a4.83,4.83,0,0,0,1.79-1.85,6,6,0,0,0,0-5.46,4.66,4.66,0,0,0-1.79-1.84,5.09,5.09,0,0,0-2.56-.64,5,5,0,0,0-2.54.66,4.84,4.84,0,0,0-1.79,1.83,5.48,5.48,0,0,0-.66,2.72,5.68,5.68,0,0,0,.65,2.73,4.62,4.62,0,0,0,1.79,1.85,5.35,5.35,0,0,0,5.11,0Z"
      />
      <path
        class="e"
        d="m1318.8 71.11a7.12 7.12 0 0 1-2.76-2.71 8.2 8.2 0 0 1 0-7.81 7 7 0 0 1 2.76-2.69 8.69 8.69 0 0 1 7.94 0 7.08 7.08 0 0 1 2.74 2.69 8.2 8.2 0 0 1 0 7.81 7.2 7.2 0 0 1-2.74 2.71 8.61 8.61 0 0 1-7.94 0zm6.54-2a4.71 4.71 0 0 0 1.77-1.85 6.06 6.06 0 0 0 0-5.46 4.55 4.55 0 0 0-1.77-1.84 5.46 5.46 0 0 0-5.13 0 4.66 4.66 0 0 0-1.79 1.84 6 6 0 0 0 0 5.46 4.83 4.83 0 0 0 1.79 1.85 5.39 5.39 0 0 0 5.13 0z"
      />
      <path
        class="e"
        d="m1338.9 57.61a7.46 7.46 0 0 1 3.33-0.67v2.6a5 5 0 0 0-0.62 0 4.53 4.53 0 0 0-3.43 1.31 5.13 5.13 0 0 0-1.24 3.71v7.39h-2.69v-14.87h2.57v2.49a4.6 4.6 0 0 1 2.08-1.96z"
      />
      <path
        class="e"
        d="M1354.46,71.05a4.14,4.14,0,0,1-1.46.77,5.92,5.92,0,0,1-1.79.26,4.77,4.77,0,0,1-3.47-1.2,4.59,4.59,0,0,1-1.23-3.44V59.29H1344V57.08h2.52V53.83h2.69v3.25h4.25v2.21h-4.25v8a2.6,2.6,0,0,0,.6,1.84,2.23,2.23,0,0,0,1.72.65,3.28,3.28,0,0,0,2.1-.7Z"
      />
      <path
        class="e"
        d="M1358.46,71.59a7.67,7.67,0,0,1-2.63-1.24l1.12-2.13a8.08,8.08,0,0,0,2.38,1.12,9.85,9.85,0,0,0,2.83.42c2.35,0,3.53-.67,3.53-2a1.31,1.31,0,0,0-.48-1.07,2.94,2.94,0,0,0-1.19-.57c-.47-.12-1.15-.26-2-.41a20.62,20.62,0,0,1-2.92-.64,4.64,4.64,0,0,1-2-1.23,3.46,3.46,0,0,1-.81-2.44,3.79,3.79,0,0,1,1.68-3.23,7.47,7.47,0,0,1,4.51-1.22,12.42,12.42,0,0,1,3,.36,7.9,7.9,0,0,1,2.44,1l-1.15,2.13a7.66,7.66,0,0,0-4.28-1.18,5,5,0,0,0-2.6.56,1.7,1.7,0,0,0-.9,1.49,1.42,1.42,0,0,0,.5,1.14,3.06,3.06,0,0,0,1.25.62c.49.13,1.19.28,2.08.45a20.34,20.34,0,0,1,2.9.66,4.27,4.27,0,0,1,1.89,1.19,3.28,3.28,0,0,1,.79,2.35,3.72,3.72,0,0,1-1.72,3.2,8.12,8.12,0,0,1-4.7,1.19A12.33,12.33,0,0,1,1358.46,71.59Z"
      />
    </g>
    <rect class="d" x="1423" y="22" width="178" height="80" />
    <g class="a">
      <path
        class="e"
        d="M1480.76,73.71a6.5,6.5,0,0,1-2.22,1.71,6.34,6.34,0,0,1-2.71.58,7.07,7.07,0,0,1-3.46-.88,16.44,16.44,0,0,1-3.68-3,10.44,10.44,0,0,1-5-1.48,9.66,9.66,0,0,1-3.47-3.57,10.37,10.37,0,0,1,.11-10.07,9.66,9.66,0,0,1,3.74-3.6,11.6,11.6,0,0,1,10.67,0,9.65,9.65,0,0,1,3.72,3.59,9.88,9.88,0,0,1,1.34,5.14,10.14,10.14,0,0,1-1,4.42,9.55,9.55,0,0,1-2.72,3.39,10.1,10.1,0,0,1-4,1.9,7.45,7.45,0,0,0,1.89,1.5,4.07,4.07,0,0,0,1.87.46,4.67,4.67,0,0,0,3.58-1.68Zm-18-7.74a7,7,0,0,0,2.74,2.7,7.91,7.91,0,0,0,3.91,1,7.77,7.77,0,0,0,3.86-1A7.08,7.08,0,0,0,1476,66a8,8,0,0,0,0-7.7,7.08,7.08,0,0,0-2.73-2.7,7.68,7.68,0,0,0-3.86-1,7.81,7.81,0,0,0-3.91,1,7,7,0,0,0-2.74,2.7,7.91,7.91,0,0,0,0,7.7Z"
      />
      <path
        class="e"
        d="M1497.53,57.08V71.92H1495V69.68a5.48,5.48,0,0,1-2.14,1.78,6.68,6.68,0,0,1-2.9.62,6.49,6.49,0,0,1-4.68-1.63,6.37,6.37,0,0,1-1.7-4.83V57.08h2.68v8.23a4.61,4.61,0,0,0,1,3.26,3.86,3.86,0,0,0,3,1.11,4.42,4.42,0,0,0,3.36-1.29,5.06,5.06,0,0,0,1.23-3.64V57.08Z"
      />
      <path
        class="e"
        d="m1502.7 53.72a1.68 1.68 0 0 1-0.51-1.23 1.71 1.71 0 0 1 0.51-1.25 1.76 1.76 0 0 1 1.31-0.52 1.81 1.81 0 0 1 1.3 0.49 1.63 1.63 0 0 1 0.52 1.22 1.78 1.78 0 0 1-1.82 1.79 1.83 1.83 0 0 1-1.31-0.5zm0 3.36h2.69v14.84h-2.69z"
      />
      <path
        class="e"
        d="M1521.47,69.71v2.21H1509V70.18l8.76-10.89h-8.6V57.08h12v1.76l-8.76,10.87Z"
      />
      <path
        class="e"
        d="m1536 69.71v2.21h-12.43v-1.74l8.77-10.89h-8.6v-2.21h12v1.76l-8.76 10.87z"
      />
      <path
        class="e"
        d="m1552.8 65.39h-12a4.65 4.65 0 0 0 1.72 3.15 5.59 5.59 0 0 0 3.66 1.19 5.48 5.48 0 0 0 4.28-1.79l1.49 1.74a6.6 6.6 0 0 1-2.51 1.79 9.52 9.52 0 0 1-7.52-0.35 7 7 0 0 1-2.81-2.7 8.23 8.23 0 0 1 0-7.81 6.93 6.93 0 0 1 2.66-2.71 7.61 7.61 0 0 1 3.82-1 7.5 7.5 0 0 1 3.8 1 6.85 6.85 0 0 1 2.6 2.71 8.26 8.26 0 0 1 0.94 4 7 7 0 0 1-0.13 0.78zm-10.51-5a4.75 4.75 0 0 0-1.53 3.08h9.52a4.83 4.83 0 0 0-1.52-3.07 5 5 0 0 0-6.47 0z"
      />
      <path
        class="e"
        d="M1557.31,71.59a7.73,7.73,0,0,1-2.64-1.24l1.12-2.13a8.08,8.08,0,0,0,2.38,1.12,9.85,9.85,0,0,0,2.83.42c2.36,0,3.53-.67,3.53-2a1.31,1.31,0,0,0-.48-1.07,3,3,0,0,0-1.18-.57c-.48-.12-1.16-.26-2-.41a20.94,20.94,0,0,1-2.92-.64,4.64,4.64,0,0,1-2-1.23,3.46,3.46,0,0,1-.81-2.44,3.79,3.79,0,0,1,1.68-3.23,7.47,7.47,0,0,1,4.51-1.22,12.57,12.57,0,0,1,3,.36,7.84,7.84,0,0,1,2.43,1l-1.15,2.13a7.66,7.66,0,0,0-4.28-1.18,5,5,0,0,0-2.6.56,1.7,1.7,0,0,0-.9,1.49,1.4,1.4,0,0,0,.51,1.14,3,3,0,0,0,1.24.62c.5.13,1.19.28,2.09.45a20.49,20.49,0,0,1,2.89.66,4.36,4.36,0,0,1,1.9,1.19,3.32,3.32,0,0,1,.78,2.35,3.69,3.69,0,0,1-1.72,3.2,8.08,8.08,0,0,1-4.69,1.19A12.28,12.28,0,0,1,1557.31,71.59Z"
      />
    </g>
  </svg>
)

export default BattleDiagram
